import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';

import { COLOR_LIST } from 'mda2-frontend/src/constants';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import { HiCheckCircle } from 'react-icons/hi2';

export default function ColorSwitch(): JSX.Element {
  const themeColor = useStore((state) => state.userSettings.themeColor);
  const setThemeColor = useStore((state) => state.userApi.setThemeColor);

  const setPrimaryColor = (theme: any) => {
    setThemeColor(theme);
  };

  return (
    <div className="flex flex-col space-y-6 xl:space-x-6 xl:space-y-0 xl:flex-row w-auto relative">
      {COLOR_LIST.map((color: any) => (
        <Button
          onClick={() => {
            setPrimaryColor(color);
          }}
          key={color}
          id={color}
          className="w-auto max-w-md xl:w-32 relative"
        >
          <p className="pb-2 text-left">
            <FormattedMessage
              id={color.charAt(0).toUpperCase() + color.slice(1)}
            />
          </p>
          <div className="w-auto relative">
            {color === 'blue' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-blue-50 h-24 w-[10%] rounded-l-md" />
                <div className="bg-blue-100 h-24 w-[10%]" />
                <div className="bg-blue-200 h-24 w-[10%]" />
                <div className="bg-blue-300 h-24 w-[10%]" />
                <div className="bg-blue-400 h-24 w-[10%]" />
                <div className="bg-blue-500 h-24 w-[10%]" />
                <div className="bg-blue-600 h-24 w-[10%]" />
                <div className="bg-blue-700 h-24 w-[10%]" />
                <div className="bg-blue-800 h-24 w-[10%]" />
                <div className="bg-blue-900 h-24 w-[10%] rounded-r-md" />
              </div>
            )}
            {color === 'gray' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-neutral-50 h-24 w-[10%] rounded-l-md " />
                <div className="bg-neutral-100 h-24 w-[10%]" />
                <div className="bg-neutral-200 h-24 w-[10%]" />
                <div className="bg-neutral-300 h-24 w-[10%]" />
                <div className="bg-neutral-400 h-24 w-[10%]" />
                <div className="bg-neutral-500 h-24 w-[10%]" />
                <div className="bg-neutral-600 h-24 w-[10%]" />
                <div className="bg-neutral-700 h-24 w-[10%]" />
                <div className="bg-neutral-800 h-24 w-[10%]" />
                <div className="bg-neutral-900 h-24 w-[10%] rounded-r-md " />
              </div>
            )}
            {color === 'green' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-green-50 h-24 w-[10%] rounded-l-md " />
                <div className="bg-green-100 h-24 w-[10%]" />
                <div className="bg-green-200 h-24 w-[10%]" />
                <div className="bg-green-300 h-24 w-[10%]" />
                <div className="bg-green-400 h-24 w-[10%]" />
                <div className="bg-green-500 h-24 w-[10%]" />
                <div className="bg-green-600 h-24 w-[10%]" />
                <div className="bg-green-700 h-24 w-[10%]" />
                <div className="bg-green-800 h-24 w-[10%]" />
                <div className="bg-green-900 h-24 w-[10%] rounded-r-md " />
              </div>
            )}
            {color === 'indigo' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-indigo-50 h-24 w-[10%] rounded-l-md" />
                <div className="bg-indigo-100 h-24 w-[10%]" />
                <div className="bg-indigo-200 h-24 w-[10%]" />
                <div className="bg-indigo-300 h-24 w-[10%]" />
                <div className="bg-indigo-400 h-24 w-[10%]" />
                <div className="bg-indigo-500 h-24 w-[10%]" />
                <div className="bg-indigo-600 h-24 w-[10%]" />
                <div className="bg-indigo-700 h-24 w-[10%]" />
                <div className="bg-indigo-800 h-24 w-[10%]" />
                <div className="bg-indigo-900 h-24 w-[10%] rounded-r-md" />
              </div>
            )}
            {color === 'yellow' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-yellow-50 h-24 w-[10%] rounded-l-md " />
                <div className="bg-yellow-100 h-24 w-[10%]" />
                <div className="bg-yellow-200 h-24 w-[10%]" />
                <div className="bg-yellow-300 h-24 w-[10%]" />
                <div className="bg-yellow-400 h-24 w-[10%]" />
                <div className="bg-yellow-500 h-24 w-[10%]" />
                <div className="bg-yellow-600 h-24 w-[10%]" />
                <div className="bg-yellow-700 h-24 w-[10%]" />
                <div className="bg-yellow-800 h-24 w-[10%]" />
                <div className="bg-yellow-900 h-24 w-[10%] rounded-r-md " />
              </div>
            )}
            {color === 'orange' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-orange-50 h-24 w-[10%] rounded-l-md " />
                <div className="bg-orange-100 h-24 w-[10%]" />
                <div className="bg-orange-200 h-24 w-[10%]" />
                <div className="bg-orange-300 h-24 w-[10%]" />
                <div className="bg-orange-400 h-24 w-[10%]" />
                <div className="bg-orange-500 h-24 w-[10%]" />
                <div className="bg-orange-600 h-24 w-[10%]" />
                <div className="bg-orange-700 h-24 w-[10%]" />
                <div className="bg-orange-800 h-24 w-[10%]" />
                <div className="bg-orange-900 h-24 w-[10%] rounded-r-md " />
              </div>
            )}
            {color === 'purple' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-purple-50 h-24 w-[10%] rounded-l-md " />
                <div className="bg-purple-100 h-24 w-[10%]" />
                <div className="bg-purple-200 h-24 w-[10%]" />
                <div className="bg-purple-300 h-24 w-[10%]" />
                <div className="bg-purple-400 h-24 w-[10%]" />
                <div className="bg-purple-500 h-24 w-[10%]" />
                <div className="bg-purple-600 h-24 w-[10%]" />
                <div className="bg-purple-700 h-24 w-[10%]" />
                <div className="bg-purple-800 h-24 w-[10%]" />
                <div className="bg-purple-900 h-24 w-[10%] rounded-r-md " />
              </div>
            )}
            {color === 'red' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-red-50 h-24 w-[10%] rounded-l-md " />
                <div className="bg-red-100 h-24 w-[10%]" />
                <div className="bg-red-200 h-24 w-[10%]" />
                <div className="bg-red-300 h-24 w-[10%]" />
                <div className="bg-red-400 h-24 w-[10%]" />
                <div className="bg-red-500 h-24 w-[10%]" />
                <div className="bg-red-600 h-24 w-[10%]" />
                <div className="bg-red-700 h-24 w-[10%]" />
                <div className="bg-red-800 h-24 w-[10%]" />
                <div className="bg-red-900 h-24 w-[10%] rounded-r-md " />
              </div>
            )}
            {color === 'pink' && (
              <div className="transition-all flex shadow hover:shadow-lg rounded-lg">
                <div className="bg-pink-50 h-24 w-[10%] rounded-l-md " />
                <div className="bg-pink-100 h-24 w-[10%]" />
                <div className="bg-pink-200 h-24 w-[10%]" />
                <div className="bg-pink-300 h-24 w-[10%]" />
                <div className="bg-pink-400 h-24 w-[10%]" />
                <div className="bg-pink-500 h-24 w-[10%]" />
                <div className="bg-pink-600 h-24 w-[10%]" />
                <div className="bg-pink-700 h-24 w-[10%]" />
                <div className="bg-pink-800 h-24 w-[10%]" />
                <div className="bg-pink-900 h-24 w-[10%] rounded-r-md " />
              </div>
            )}
            {themeColor === color && (
              <HiCheckCircle
                id="selected"
                className="-bottom-2 -right-2 absolute size-7 text-green-400"
              />
            )}
          </div>
        </Button>
      ))}
    </div>
  );
}
