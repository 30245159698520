import Card from 'generic/components/Card';
import Input from 'generic/components/Form/Input';
import Select from 'generic/components/Form/Select';
import Switch from 'generic/components/Form/Switch';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import type { SensorTypesQuery } from 'graphql/types';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

import { useCallback, useState } from 'react';
import { HiOutlineClock } from 'react-icons/hi2';

enum Duration {
  SECONDS = 's',
  MINUTES = 'min',
  HOURS = 'h',
}

interface SensorSelectProps {
  sensorType: SensorTypesQuery['SensorTypes'][number];
  configuration: { [key: string]: number | string | boolean };
  setConfiguration: (configuration: {
    [key: string]: number | string | boolean;
  }) => void;
  disableThreshold?: boolean;
}

export default function SensorSelect({
  sensorType,
  configuration,
  setConfiguration,
  disableThreshold = false,
}: SensorSelectProps) {
  const [numericConfiguration] = useState<{
    [key: string]: number;
  }>(
    Object.keys(configuration).reduce(
      (a, b) => {
        if (typeof configuration[b] === 'number') {
          a[b] = configuration[b];
        }
        return a;
      },
      {} as { [key: string]: any },
    ),
  );
  const name = sensorType.Name.replace('clc_', '');

  const getDuration = () => {
    const duration = numericConfiguration[`${name}_interval`];
    if (!duration) {
      return Duration.SECONDS;
    }

    if (duration % 3600 === 0) {
      return Duration.HOURS;
    }

    if (duration % 60 === 0) {
      return Duration.MINUTES;
    }

    return Duration.SECONDS;
  };

  const intl = useIntl();
  const [selectedDuration, setSelectedDuration] = useState<Duration>(
    getDuration(),
  );

  const calculateTimeFactor = useCallback(() => {
    if (selectedDuration === Duration.SECONDS) {
      return 1;
    }

    if (selectedDuration === Duration.MINUTES) {
      return 60;
    }

    return 3600;
  }, [selectedDuration]);

  const [sendCyclical, setSendCyclical] = useState(
    Object.keys(numericConfiguration).length === 0
      ? false
      : numericConfiguration[`${name}_interval`] !== 0,
  );
  const [sendOnAbsoluteChange, setSendOnAbsoluteChange] = useState(
    Object.keys(numericConfiguration).length === 0
      ? false
      : numericConfiguration[`${name}_send_on_change`] !== 0,
  );
  const [cyclicalDuration, setCyclicalDuration] = useState(
    (numericConfiguration[`${name}_interval`] ?? 60) / calculateTimeFactor(),
  );
  const [absoluteChange, setAbsoluteChange] = useState(
    numericConfiguration[`${name}_send_on_change`] ??
      sensorType.DefaultUpdateThreshold,
  );

  const active = sendCyclical || (sendOnAbsoluteChange && !disableThreshold);

  return (
    <div className="relative">
      <Card
        className={`h-full ${
          active ? '!bg-primary-200 text-neutral-800' : ''
        } md:p-2 md:px-2 md:pt-6`}
        title={intl.formatMessage({ id: sensorType.Name as IntlMessageKeys })}
      >
        <div className="space-y-2">
          <Transition show={!disableThreshold}>
            <div className="space-y-1">
              <div className="flex items-center space-x-2">
                <Switch
                  data-test-id={`${name}-threshold-switch`}
                  isEnabled={sendOnAbsoluteChange}
                  onSetEnable={(enabled) => {
                    const value = enabled
                      ? sensorType.MinimumUpdateThreshold
                      : 0;

                    setAbsoluteChange(value);
                    setSendOnAbsoluteChange(enabled);
                    setConfiguration({
                      [`${name}_send_on_change`]: value,
                    });
                  }}
                />
                <div>
                  <FormattedMessage id="Threshold" />{' '}
                  {sensorType.Unit ? `(${sensorType.Unit})` : ''}
                </div>
                <Tooltip>
                  <FormattedMessage id="Sends the sensor when passing the defined threshold" />
                </Tooltip>
              </div>
              <Transition
                show={
                  sendOnAbsoluteChange &&
                  // Can't change the value if min and max are same -> do not show input
                  sensorType.MaxValue !== sensorType.MinimumUpdateThreshold
                }
              >
                <Input
                  className="col-span-1"
                  type="number"
                  value={absoluteChange}
                  min={sensorType.MinimumUpdateThreshold}
                  max={sensorType.MaxValue}
                  placeholder="Absolute change"
                  icon={
                    <HiOutlineClock className="size-5 dark:text-neutral-200" />
                  }
                  onChangeValue={(e) => {
                    setAbsoluteChange(Number.parseInt(e, 10));
                    setConfiguration({
                      [`${name}_send_on_change`]: Number.parseInt(e, 10),
                    });
                  }}
                />
              </Transition>
            </div>
          </Transition>
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <Switch
                data-test-id={`${name}-cyclical-switch`}
                isEnabled={sendCyclical}
                onSetEnable={(enabled) => {
                  const value = enabled
                    ? selectedDuration === Duration.SECONDS
                      ? 60
                      : 1
                    : 0;
                  setCyclicalDuration(value);
                  setSendCyclical(enabled);
                  setConfiguration({
                    [`${name}_interval`]: value,
                  });
                }}
              />
              <div>
                <FormattedMessage id="Cyclical" />
              </div>
              <Tooltip>
                <FormattedMessage id="Sends the sensor data every set number of seconds/minutes/hours" />
              </Tooltip>
            </div>
            <Transition show={sendCyclical}>
              <Input
                data-test-id={`${name}-cyclical-input`}
                className="col-span-1 pr-24"
                type="number"
                value={cyclicalDuration}
                placeholder="Cyclical"
                min={selectedDuration === Duration.SECONDS ? 60 : 1}
                max={1000}
                icon={
                  <HiOutlineClock className="size-5 dark:text-neutral-200" />
                }
                onChangeValue={(e) => {
                  setCyclicalDuration(Number.parseInt(e, 10));
                  setConfiguration({
                    [`${name}_interval`]:
                      Number.parseInt(e, 10) * calculateTimeFactor(),
                  });
                }}
                selectContent={
                  <Select
                    dataTestId={`${name}-cyclical-select`}
                    options={Object.values(Duration).map((d) => d.toString())}
                    value={selectedDuration}
                    onChangeSelected={(selected) =>
                      selected && setSelectedDuration(selected as Duration)
                    }
                    renderValue={(v) => v.toString()}
                    isDeselectable={false}
                  />
                }
              />
            </Transition>
          </div>
        </div>
      </Card>
    </div>
  );
}
