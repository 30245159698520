import Input from 'generic/components/Form/Input';
import Switch from 'generic/components/Form/Switch';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition/Transition';
import {
  type 
  LiveBeaconsQuery,
  MqttSystems,
  useDeleteBeaconsMutation,
} from 'graphql/types';
import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';
import parseMda3Topic from 'utils/parseMda3Topic';

import type { Row } from '@tanstack/react-table';
import { Action, DeviceTypes } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import Subtitle from 'mda2-frontend/src/generic/components/Subtitle';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

export type LiveBeaconsQueryData = LiveBeaconsQuery['MqttBeacons'][number];

interface RemoveBeaconModalProps {
  setBeaconsToRemove: (beaconsToRemove?: Row<LiveBeaconsQueryData>[]) => void;
  resetSelection?: () => void;
  beaconsToRemove: Row<LiveBeaconsQueryData>[];
}

export default function RemoveBeaconModal({
  beaconsToRemove,
  setBeaconsToRemove,
  resetSelection,
}: RemoveBeaconModalProps): JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const [confirmDelete, setConfirmDelete] = useState('');
  const [offlineDeletion, setOfflineDeletion] = useState(false);
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const [{ fetching }, deleteBeacons] = useDeleteBeaconsMutation();

  const singleBeacon = beaconsToRemove.length === 1;
  const confirmation = singleBeacon
    ? beaconsToRemove[0]?.original.UniqueIdentifier
    : beaconsToRemove.length.toString();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={!!beaconsToRemove.length}
      setShowModal={() => setBeaconsToRemove(undefined)}
      footer={
        <ModalFooter
          disabled={confirmDelete !== confirmation}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.REMOVE}
          onProceed={() => {
            deleteBeacons(
              {
                BeaconUuids: beaconsToRemove.map(
                  (b) => b.original.UniqueIdentifier,
                ),
                UserInput: {
                  offlineDeletion,
                  topics: beaconsToRemove
                    // Reset action only works for MDA3
                    .filter(
                      (b) =>
                        b.original.MqttBeaconSource.Name === MqttSystems.Mda3,
                    )
                    .map(
                      ({ original }) =>
                        `cmd/${parseMda3Topic(original.MqttTopic)}/reset`,
                    ),
                },
              },
              hasuraHeader(
                userRoles?.includes(HasuraPermissions.READ_ALL)
                  ? HasuraPermissions.READ_ALL
                  : HasuraPermissions.DELETE_MQTTBEACON,
              ),
            ).then((data) => {
              toast(data, {
                message: {
                  type: 'success',
                  content: singleBeacon
                    ? intl.formatMessage(
                        { id: 'Deleted beacon' },
                        {
                          device: beaconsToRemove[0]?.original.UniqueIdentifier,
                        },
                      )
                    : intl.formatMessage(
                        { id: 'Deleted beacons' },
                        { count: beaconsToRemove.length },
                      ),
                },
              });
              setBeaconsToRemove(undefined);
              setConfirmDelete('');
              if (resetSelection) {
                resetSelection();
              }
            });
          }}
          onCancel={() => {
            setBeaconsToRemove(undefined);
            setConfirmDelete('');
          }}
        />
      }
    >
      <Subtitle
        value={
          singleBeacon
            ? intl.formatMessage({ id: 'Are you sure to remove this beacon?' })
            : intl.formatMessage({
                id: 'Are you sure to remove these beacons?',
              })
        }
        className="text-base pb-2"
      />
      {beaconsToRemove.some(
        (b) => b.original.DeviceType?.Name === DeviceTypes.GATEWAY,
      ) && (
        <p className="text-red-500 font-bold">
          <FormattedMessage id="Gateway warning" />
        </p>
      )}

      <p className="pb-2">
        {singleBeacon ? (
          <FormattedMessage
            id="Enter the beacon identifier in order to delete it"
            values={{
              identifier: <b>{confirmation}</b>,
            }}
          />
        ) : (
          <FormattedMessage
            id="Enter the number of beacons in order to delete them"
            values={{
              count: <b>{confirmation}</b>,
            }}
          />
        )}
      </p>

      <div className="flex flex-col space-y-1">
        <Transition
          show={
            // Show the offline deletion switch, if there are MDA3 devices as only they support the reset function
            beaconsToRemove.filter(
              (b) => b.original.MqttBeaconSource.Name === MqttSystems.Mda3,
            ).length > 0
          }
        >
          <div className="flex flex-col space-y-1">
            <div className="flex items-center space-x-2">
              <Switch
                data-test-id="offline-deletion-switch"
                isEnabled={offlineDeletion}
                onSetEnable={setOfflineDeletion}
              />
              <div className="flex space-x-2 items-center text-base md:text-sm">
                <div>
                  <FormattedMessage id="Offline deletion" />
                </div>
                <Tooltip>
                  <FormattedMessage id="Offline deletion description" />
                </Tooltip>
              </div>
            </div>
            <Transition show={offlineDeletion}>
              <p className="text-red-500 text-xs">
                <FormattedMessage id="Offline deletion warning" />
              </p>
            </Transition>
          </div>
        </Transition>

        <Input
          type="text"
          value={confirmDelete}
          id="Remove user"
          icon={<HiOutlineTrash className="size-5 dark:text-neutral-200" />}
          onChangeValue={(input: string) => setConfirmDelete(input)}
          placeholder={confirmation}
          required
          data-test-id="confirm-delete-beacon"
        />
      </div>
    </Modal>
  );
}
