import { localPoint } from '@visx/event';
import { type Point, motion } from 'framer-motion';

interface AnimatedRectProps {
  bar: {
    width: number;
    height: number;
    y: number;
    x: number;
    color: string;
  };
  onMouseMove?: (point: Point | null) => void;
  onMouseOut?: () => void;
  dataTestId?: string;
  rounded?: boolean;
  horizontal?: boolean;
}

export default function AnimatedRect({
  bar,
  onMouseMove,
  onMouseOut,
  dataTestId,
  rounded,
  horizontal,
}: AnimatedRectProps) {
  return (
    <motion.rect
      data-test-id={dataTestId}
      onMouseMove={(event) => onMouseMove?.(localPoint(event))}
      onMouseOut={onMouseOut}
      initial="initial"
      animate="animate"
      fill={bar.color}
      fillOpacity={0.8}
      variants={
        horizontal
          ? {
              initial: {
                width: 0,
              },
              animate: {
                width: bar.width > 0 ? bar.width : 0,
                transition: {
                  ease: 'easeInOut',
                  duration: 1,
                },
              },
            }
          : {
              initial: {
                height: 0,
              },
              animate: {
                height: bar.height > 0 ? bar.height : 0,
                transition: {
                  ease: 'easeInOut',
                  duration: 1,
                },
              },
            }
      }
      x={bar.x}
      y={bar.y}
      width={horizontal ? undefined : bar.width}
      height={horizontal ? bar.height : undefined}
      rx={rounded ? 4 : 0}
    />
  );
}
