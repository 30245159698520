import { useRoomOccupancyLabelHistoryQuery } from 'graphql/types';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import BarChart from '../BarChart';
import ReportingPage from '../ReportingPage';

export default function ReportRoomLabelOccupancy() {
  const hasuraHeader = useHasuraHeader();
  const intl = useIntl();
  const { Start, End, Building } = useAnalyticsFilter();
  const [{ data, fetching }] = useRoomOccupancyLabelHistoryQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    variables: {
      Start,
      End,
      Building,
    },
  });

  const occupancyData = data?.f_history_roomslabel_occupancy_custom;

  return (
    <ReportingPage
      loading={fetching}
      reportTitle="Meeting rooms occupancy by label"
    >
      <div className="h-96 w-full">
        <BarChart
          data={occupancyData ?? []}
          yScaleAccessor={(d) => d.AvgDailyOccupancy}
          groupAccessor={(d) => d.Label}
          y1Accessor={(d) => d.MaxDailyOccupancy}
          y2Accessor={(d) => d.MaxHourlyOccupancy}
          xAxisLabel={intl.formatMessage({ id: 'Label' })}
        />
      </div>
    </ReportingPage>
  );
}
