import Tooltip from 'generic/components/Tooltip';
import {
  type ChangeEvent,
  type InputHTMLAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { HiOutlineClock, HiOutlineEye } from 'react-icons/hi2';
import Button from '../Button';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeValue: (arg: string) => void;
  icon?: JSX.Element;
  label?: string;
  error?: boolean;
  type: string;
  className?: string;
  iconClassName?: string;
  wrapperClassName?: string;
  tooltipText?: string;
  tooltipContent?: JSX.Element;
  selectContent?: JSX.Element;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props, forwardedRef) => {
    const {
      icon = <HiOutlineClock className="size-5 dark:text-neutral-200" />,
      onChangeValue,
      value,
      label,
      error,
      iconClassName,
      className = '',
      disabled = false,
      required = false,
      placeholder = '',
      step,
      autoFocus = false,
      tooltipText,
      tooltipContent,
      wrapperClassName,
      selectContent,
      type,
      ...rest
    } = props;

    const internalRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(
      forwardedRef,
      () => internalRef.current as HTMLInputElement,
    );

    const [passwordVisible, setPasswordVisible] = useState(false);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChangeValue(e.target.value);
    };

    useEffect(() => {
      // Set focus on open because autoFocus doesn't work with Panel open/close
      if (autoFocus && internalRef) {
        internalRef.current?.focus();
      }
    }, [autoFocus]);

    // If no initial prop, the input is controlled by above component.
    return (
      <div className={`relative ${wrapperClassName ?? ''}`}>
        <label
          htmlFor={label}
          className="text-base md:text-sm text-neutral-700 dark:text-white text-left"
        >
          <div className="flex space-x-1 items-center">
            <div>
              {label}
              {label && required && <span className="text-red-700"> *</span>}
            </div>
            {label && !!tooltipText && (
              <Tooltip content={tooltipContent}>
                <p>{tooltipText}</p>
              </Tooltip>
            )}
          </div>
          <div
            className={`${
              label ? 'mt-1' : ''
            } relative rounded-md items-center`}
          >
            <div
              className={`absolute inset-y-0 text-neutral-500 ${
                iconClassName ?? ''
              } dark:text-neutral-200 px-2 flex items-center`}
            >
              {icon}
            </div>
            <input
              ref={internalRef}
              name={label}
              value={value}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              onChange={onInputChange}
              type={passwordVisible ? 'text' : type}
              // biome-ignore lint/a11y/noAutofocus: don't need it
              autoFocus={autoFocus}
              className={`w-full dark:bg-neutral-800 pl-10 focus:ring-primary-500 dark:focus:ring-neutral-500 focus:border-primary-500 dark:focus:border-neutral-500 block border-neutral-200 dark:border-neutral-700 text-base md:text-sm rounded-md disabled:opacity-50 ${
                error ? 'border-red-500' : ''
              } ${className}`}
              {...rest}
            />
            {!selectContent && type === 'password' && (
              <div className="absolute inset-y-0 right-2 flex items-center">
                <Button onClick={() => setPasswordVisible(!passwordVisible)}>
                  <HiOutlineEye className="size-5" />
                </Button>
              </div>
            )}
            <div className="absolute inset-y-0 right-0 flex items-center">
              {selectContent}
            </div>
          </div>
        </label>
      </div>
    );
  },
);

export default Input;
