import Card from 'generic/components/Card/Card';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import ImageInput from 'generic/components/Form/ImageInput';
import PrivateWrapper from 'generic/components/PrivateWrapper/PrivateWrapper';
import {
  type ClimateSensorLimits_Insert_Input,
  useOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'graphql/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import useStore from 'model/store';
import WorkdaySelect, {
  type CheckedDays,
} from 'pages/SettingsView/components/OrganizationView/components/WorkdaySelect/WorkdaySelect';
import { useEffect, useState } from 'react';
import { HiLink, HiOutlineHome } from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { lower, upper } from 'utils/date';
import formatDate from 'utils/format';
import FirmwareDaySelect, {
  type CheckedFirmwareUpdateDays,
} from './components/FirmwareDaySelect/FirmwareDaySelect';
import LimitSelect from './components/LimitSelect/LimitSelect';

export default function OrganizationView() {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [organizationId, setOrganizationId] = useState<
    string | null | undefined
  >(undefined);
  const [name, setName] = useState('');
  const [logoLight, setLogoLight] = useState<string | null | undefined>(
    undefined,
  );
  const [logoDark, setLogoDark] = useState<string | null | undefined>(
    undefined,
  );
  const [logoLightValidated, setLogoLightValidated] = useState(false);
  const [logoDarkValidated, setLogoDarkValidated] = useState(false);
  const [deletedDays, setDeletedDays] = useState<string[]>([]);
  const [deletedFirmwareDays, setDeletedFirmwareDays] = useState<string[]>([]);
  const [timeObjects, setTimeObjects] = useState<CheckedDays[]>([]);
  const [firmwareTimeObjects, setFirmwareTimeObjects] = useState<
    CheckedFirmwareUpdateDays[]
  >([]);
  const [climateObjects, setClimateObjects] = useState<
    ClimateSensorLimits_Insert_Input[]
  >([]);
  const [timeValidated, setTimeValidated] = useState(false);
  const [firmwareTimeValidated, setFirmwareTimeValidated] = useState(false);
  const [notificationUrl, setNotificationUrl] = useState<
    string | null | undefined
  >('');
  const warmMinutesPolicy = useStore(
    (state) => state.organizationSettings.warmMinutesPolicy,
  );
  const { check: cleaningCheck, clean: cleaningThreshold } = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const userRoles = useStore((state) => state.user)?.roles;
  const reservationLimit = useStore(
    (state) => state.organizationSettings.reservationLimit,
  );
  const setOrganizationLogo = useStore(
    (state) => state.organizationApi.setOrganizationLogo,
  );
  const setWarmMinutesPolicy = useStore(
    (state) => state.organizationApi.setWarmMinutesPolicy,
  );
  const setReservationLimit = useStore(
    (state) => state.organizationApi.setReservationLimit,
  );
  const setCleaningTimes = useStore(
    (state) => state.organizationApi.setCleaningTimes,
  );
  const setBusinessHours = useStore(
    (state) => state.organizationApi.setBusinessHours,
  );
  const [newReservationLimit, setNewReservationLimit] = useState<
    number | undefined
  >(reservationLimit);
  const [offlineMinutes, setOfflineMinutes] = useState<number>(360);

  const [{ data: organizationData }] = useOrganizationQuery();

  useEffect(() => {
    if (organizationData?.Organizations[0]) {
      const {
        Id,
        Name,
        NotificationUrl,
        LogoDark,
        LogoLight,
        OfflineMinutes,
        ReservationLimit,
      } = organizationData.Organizations[0];
      setOrganizationId(Id);
      setName(Name);
      setNotificationUrl(NotificationUrl);
      setLogoDark(LogoDark);
      setLogoLight(LogoLight);
      setOfflineMinutes(OfflineMinutes);
      setNewReservationLimit(ReservationLimit || 0);
    }
  }, [organizationData, organizationData?.Organizations?.[0]]);

  const [{ fetching: loadingChangeOrganization }, changeOrganization] =
    useUpdateOrganizationMutation();

  return (
    <Card>
      <div className="space-y-6">
        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-8 md:justify-between">
          <div className="flex flex-col md:w-1/2 space-y-3">
            <Input
              type="text"
              label={intl.formatMessage({
                id: 'Organization name',
              })}
              placeholder={intl.formatMessage({ id: 'Name' })}
              icon={<HiOutlineHome className="size-5 dark:text-neutral-200" />}
              value={name}
              data-test-id="organization-name"
              onChangeValue={(e) => setName(e)}
              required
            />
            <Input
              type="number"
              value={warmMinutesPolicy}
              label={intl.formatMessage({
                id: 'Desksharing policy (in minutes)',
              })}
              data-test-id="desksharing-policy"
              min={0}
              onChangeValue={(v) =>
                setWarmMinutesPolicy(Number.parseInt(v, 10))
              }
              required
            />
            <Input
              type="number"
              value={cleaningThreshold}
              label={intl.formatMessage({
                id: 'Cleaning threshold (in minutes)',
              })}
              min={0}
              data-test-id="cleaning-threshold"
              onChangeValue={(v) =>
                setCleaningTimes({
                  check: cleaningCheck,
                  clean: Number.parseInt(v, 10),
                })
              }
              required
            />
            <Input
              type="number"
              value={offlineMinutes}
              label={intl.formatMessage({
                id: 'Beacon offline timeout (in minutes)',
              })}
              id="offline-minutes"
              tooltipText={intl.formatMessage({
                id: 'Beacon offline description',
              })}
              min={90}
              onChangeValue={(v) => setOfflineMinutes(Number.parseInt(v, 10))}
              required
            />
            <Input
              type="number"
              value={newReservationLimit}
              label={intl.formatMessage({
                id: 'Reservation period (in days)',
              })}
              id="reservation-limit"
              tooltipText={intl.formatMessage({
                id: 'Reservation period description',
              })}
              min={0}
              onChangeValue={(v) =>
                setNewReservationLimit(Number.parseInt(v, 10))
              }
            />
            <PrivateWrapper roleRequired={HasuraPermissions.MDA3}>
              <FirmwareDaySelect
                deletedDays={deletedFirmwareDays}
                setDeletedDays={setDeletedFirmwareDays}
                setTimeObjects={setFirmwareTimeObjects}
                setValidated={setFirmwareTimeValidated}
              />
            </PrivateWrapper>
            <div className="flex flex-col space-y-1">
              <Input
                type="text"
                value={notificationUrl ?? ''}
                label={intl.formatMessage({
                  id: 'MS Teams notification URL',
                })}
                placeholder={intl.formatMessage({
                  id: 'MS Teams notification URL',
                })}
                tooltipText={intl.formatMessage({
                  id: 'teams-notification-description',
                })}
                id="notification-url"
                icon={<HiLink className="size-5 dark:text-neutral-200" />}
                onChangeValue={(v) =>
                  v === '' ? setNotificationUrl(null) : setNotificationUrl(v)
                }
              />
              <div className="text-sm md:text-xxs text-left">
                <FormattedMessage
                  id="See here for configuration"
                  values={{
                    here: (
                      <a
                        className="text-primary-500 hover:text-primary-600"
                        href="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"
                      >
                        <FormattedMessage id="here" />
                      </a>
                    ),
                  }}
                />
              </div>
            </div>
            {organizationData?.Organizations[0]?.LicenseDuration && (
              <div className="flex flex-col text-base md:text-sm">
                <div>
                  <FormattedMessage
                    id="Used licenses"
                    values={{
                      used:
                        (organizationData.Organizations[0]
                          .OnPremisesBeacons_aggregate.aggregate?.count ?? 0) +
                        (organizationData.Organizations[0].MqttOrganizations.reduce(
                          (acc, curr) =>
                            acc + (curr.beaconCount.aggregate?.count ?? 0),
                          0,
                        ) ?? 0),
                      available:
                        organizationData?.Organizations[0]?.AvailableLicenses,
                    }}
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="License duration start - end"
                    values={{
                      start: formatDate(
                        lower(
                          organizationData.Organizations[0].LicenseDuration,
                        ),
                      ),
                      end: formatDate(
                        upper(
                          organizationData.Organizations[0].LicenseDuration,
                        ),
                      ),
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col md:w-1/2 space-y-3">
            <div className="flex space-x-2">
              <div className="w-1/2">
                <ImageInput
                  previewImage={logoLight ?? undefined}
                  label={intl.formatMessage({
                    id: 'Light logo',
                  })}
                  setImage={setLogoLight}
                  imageValidated={logoLightValidated}
                  setImageValidated={setLogoLightValidated}
                />
              </div>
              <div className="w-1/2">
                <ImageInput
                  dark
                  previewImage={logoDark ?? undefined}
                  label={intl.formatMessage({
                    id: 'Dark logo',
                  })}
                  setImage={setLogoDark}
                  imageValidated={logoDarkValidated}
                  setImageValidated={setLogoDarkValidated}
                />
              </div>
            </div>

            <WorkdaySelect
              deletedDays={deletedDays}
              setDeletedDays={setDeletedDays}
              setTimeObjects={setTimeObjects}
              setValidated={setTimeValidated}
            />

            <PrivateWrapper roleRequired={HasuraPermissions.VIEW_CLIMATE}>
              <LimitSelect setClimateObjects={setClimateObjects} />
            </PrivateWrapper>
          </div>
        </div>
        <div className="flex justify-end">
          <StyledButton
            data-test-id="save-organization"
            isLoading={loadingChangeOrganization}
            disabled={
              name === '' ||
              !logoLightValidated ||
              !logoDarkValidated ||
              !warmMinutesPolicy ||
              !cleaningThreshold ||
              !timeValidated ||
              (userRoles?.includes(HasuraPermissions.MDA3) &&
                !firmwareTimeValidated)
            }
            onClick={() => {
              changeOrganization(
                {
                  Id: organizationId,
                  Name: name,
                  LogoLight: logoLight,
                  LogoDark: logoDark,
                  WarmMinutesPolicy: warmMinutesPolicy,
                  ReservationLimit: newReservationLimit ?? 0,
                  OfflineMinutes: offlineMinutes,
                  CleaningThreshold: cleaningThreshold,
                  NotificationUrl:
                    notificationUrl === '' ? null : notificationUrl,
                  TimeObjects: timeObjects,
                  FirmwareTimeObjects: firmwareTimeObjects,
                  ClimateSensorLimits: climateObjects,
                  DeleteObjects: deletedDays
                    .filter((v) => v)
                    .map((v) => Number.parseInt(v, 10)),
                  DeleteFirmwareObjects: deletedFirmwareDays.filter((v) => v),
                },
                hasuraHeader(HasuraPermissions.WRITE_ORGANIZATION),
              ).then((data) => {
                toast(data);
                if (!data.error) {
                  if (logoLight && logoDark) {
                    setOrganizationLogo({ light: logoLight, dark: logoDark });
                  }
                  setWarmMinutesPolicy(warmMinutesPolicy);
                  setReservationLimit(newReservationLimit);
                  setCleaningTimes({
                    check: cleaningCheck,
                    clean: cleaningThreshold,
                  });
                  setBusinessHours({
                    BusinessHours:
                      timeObjects[0]?.BusinessHours ?? '[08:30:00, 17:30:00]',
                    LunchHours: timeObjects[0]?.Lunch ?? '[11:30:00, 12:30:00]',
                  });
                }
              });
            }}
          >
            <FormattedMessage id="Save" />
          </StyledButton>
        </div>
      </div>
    </Card>
  );
}
