import useStore from 'model/store';

import {
  addDays,
  addHours,
  addYears,
  endOfDay,
  formatDistance,
  parse,
  set,
  startOfDay,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { LOCALES } from 'mda2-frontend/src/constants';

import localize from './format';

export function startOfDayUTC(date: Date) {
  const offset = date.getTimezoneOffset();

  if (offset === 0) return startOfDay(date).toISOString();

  const inputZoned = toZonedTime(date, 'UTC');
  const dayStartZoned = startOfDay(inputZoned);
  const offsetTime =
    offset > 0
      ? fromZonedTime(dayStartZoned, 'UTC')
      : addDays(fromZonedTime(dayStartZoned, 'UTC'), 1);

  return offsetTime.toISOString();
}

export function startOfYearUTC(date: Date) {
  const offset = date.getTimezoneOffset();

  if (offset === 0) return startOfYear(date).toISOString();

  const inputZoned = toZonedTime(date, 'UTC');
  const yearStartZoned = startOfYear(inputZoned);
  const offsetTime =
    offset > 0
      ? fromZonedTime(yearStartZoned, 'UTC')
      : addDays(fromZonedTime(yearStartZoned, 'UTC'), 1);

  return offsetTime.toISOString();
}

export function endOfDayUTC(date: Date) {
  const offset = date.getTimezoneOffset();

  if (offset === 0) return endOfDay(date).toISOString();

  const inputZoned = toZonedTime(date, 'UTC');
  const dayEndZoned = endOfDay(inputZoned);
  const offsetTime =
    offset < 0
      ? fromZonedTime(dayEndZoned, 'UTC')
      : subDays(fromZonedTime(dayEndZoned, 'UTC'), 1);

  return offsetTime.toISOString();
}

export function getWeekDayFromNumber(number: number): string {
  return localize(addDays(startOfWeek(new Date()), number), 'EEEEEE');
}

export const getTodayAtSpecificHour = (hour = 12, minutes = 0): Date =>
  set(new Date(), { hours: hour, minutes, seconds: 0, milliseconds: 0 });

export const getInterval = (time: string): Date =>
  getTodayAtSpecificHour(
    time ? Number.parseInt(time.split(':')[0] ?? '0', 10) : 0,
    time ? Number.parseInt(time.split(':')[1] ?? '0', 10) : 0,
  );

const parseDateRange = (range: string, formatString?: string) => {
  const parts = range.split(',');

  if (parts.length !== 2 || !parts[0] || !parts[1]) {
    throw new Error('Invalid range');
  }

  // Timerange
  if (parts[0].split(' ').length === 1) {
    return {
      start: {
        inclusive: parts[0][0] === '[',
        value: getInterval(parts[0].slice(1)),
      },

      end: {
        inclusive: parts[1][parts[1].length - 1] === ']',
        value: getInterval(parts[1].slice(0, -1)),
      },
    };
  }

  // Daterange
  return {
    start: {
      inclusive: parts[0][0] === '[',
      // Check if the date includes milliseconds
      value:
        parts[0].slice(1).replaceAll('"', '') === 'infinity'
          ? // Infinity is long, but use an earthly time
            addYears(new Date(), 10)
          : parts[0].includes('.')
            ? parse(
                parts[0].slice(1).replaceAll('"', ''),
                formatString || 'yyyy-MM-dd HH:mm:ss.SSSSSSx',
                new Date(),
              )
            : parse(
                parts[0].slice(1).replaceAll('"', ''),
                formatString || 'yyyy-MM-dd HH:mm:ssx',
                new Date(),
              ),
    },
    end: {
      inclusive: parts[1][parts[1].length - 1] === ']',
      value:
        parts[1].slice(0, -1).replaceAll('"', '') === 'infinity'
          ? // Infinity is long, but use an earthly time
            addYears(new Date(), 10)
          : parts[1].includes('.')
            ? parse(
                parts[1].slice(0, -1).replaceAll('"', ''),
                formatString || 'yyyy-MM-dd HH:mm:ss.SSSSSSx',
                new Date(),
              )
            : parse(
                parts[1].slice(0, -1).replaceAll('"', ''),
                formatString || 'yyyy-MM-dd HH:mm:ssx',
                new Date(),
              ),
    },
  };
};

export const serializeRange = (
  range: {
    start: { value: Date; inclusive: boolean };
    end: { value: Date; inclusive: boolean };
  },
  timerange = false,
) => {
  const inclusivity = (inclusive: boolean) => (inclusive ? '[]' : '()');

  // Timerange
  if (timerange) {
    return `${inclusivity(range.start.inclusive)[0]}${localize(
      range.start.value,
      'HH:mm',
    )}, ${localize(range.end.value, 'HH:mm')}${
      inclusivity(range.end.inclusive)[1]
    }`;
  }

  // Daterange
  return `${inclusivity(range.start.inclusive)[0]}${localize(
    range.start.value,
    'yyyy-MM-dd HH:mm:ssx',
  )}, ${localize(range.end.value, 'yyyy-MM-dd HH:mm:ssx')}${
    inclusivity(range.end.inclusive)[1]
  }`;
};

export const lower = (duration: string, formatString?: string): Date =>
  parseDateRange(duration, formatString).start.value;

export const upper = (duration: string, formatString?: string): Date =>
  parseDateRange(duration, formatString).end.value;

export const getAllWeekdays = (): string[] =>
  Array.from(Array(7)).map((_, i) => getWeekDayFromNumber(i + 1));

export const getAllHours = (): string[] =>
  Array.from(Array(24)).map((_, i) =>
    localize(addHours(startOfDay(new Date()), i), 'H'),
  );

export const formattedDistance = (
  date: Date,
  options: { includeSeconds: boolean } = { includeSeconds: false },
  baseDate: Date = new Date(),
) =>
  formatDistance(date, baseDate, {
    addSuffix: true,
    includeSeconds: options.includeSeconds,
    locale: LOCALES[useStore.getState().userSettings.language],
  });
