import { Switch as HUISwitch } from '@headlessui/react';

interface SwitchProps {
  isEnabled?: boolean;
  onSetEnable: (val: boolean) => void;
  color?: string;
  disabledColor?: string;
  className?: string;
}

export default function Switch({
  isEnabled = false,
  onSetEnable,
  color = 'bg-primary-500',
  disabledColor = 'bg-neutral-200',
  className = '',
  ...rest
}: SwitchProps): JSX.Element {
  return (
    <HUISwitch
      checked={isEnabled}
      onChange={onSetEnable}
      className={`${
        isEnabled ? `${color}` : disabledColor
      } group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:${color} ${className}`}
      {...rest}
    >
      <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
    </HUISwitch>
  );
}
