import { useCallback, useEffect, useRef, useState } from 'react';
import { type CombinedError, type UseQueryArgs, useQuery } from 'urql';

import type { DocumentNode } from 'graphql';
import usePolling from './usePolling';

const usePreviousValue = (track: boolean) => {
  const ref = useRef(track);
  useEffect(() => {
    ref.current = track;
  }, [track]);
  return ref.current;
};

// TODO: Maybe better approach: https://github.com/urql-graphql/urql/blob/89f9a49fa9bf8132a4276484829a627e18cfee56/examples/with-infinite-pagination/src/SearchResults.jsx

export default function usePagination<Type>({
  query,
  indexKey,
  variables,
  pause = false,
  context,
  uniqueIdentifier = 'Id',
}: {
  query: DocumentNode;
  indexKey: keyof Type;
  variables: { [key: string]: unknown } & { Limit: number; Offset: number };
  pause?: UseQueryArgs['pause'];
  context: UseQueryArgs['context'];
  uniqueIdentifier: string;
}): [{ data?: Type; fetching: boolean; error?: CombinedError }, () => void] {
  const { Limit, Offset: initialOffset } = variables;
  const [offset, setOffset] = useState(initialOffset);
  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query,
    variables: { ...variables, Limit, Offset: offset },
    pause,
    context,
  });

  usePolling(fetching, reexecuteQuery, 1000 * 30);

  // should be here to immediately pick up the first (cached) result
  const [index, setIndex] = useState(
    data && indexKey in data ? data[indexKey] : [],
  );
  const prevFetching = usePreviousValue(fetching);

  useEffect(() => {
    if (
      prevFetching === true &&
      fetching === false &&
      !error &&
      data &&
      indexKey in data
    ) {
      setIndex((prevIndex: any) =>
        // If new values are added while it is open then they are added on every poll, do filter them out
        // TODO: Only problem is if something gets deleted, then it will still be available in the list
        [...prevIndex, ...data[indexKey]].filter(
          (obj, idx, arr) =>
            arr
              .map((mapObj) => JSON.stringify(mapObj))
              .indexOf(JSON.stringify(obj)) === idx,
        ),
      );
    }
  }, [data, error, fetching, indexKey, prevFetching]);

  useEffect(() => {
    if (data && indexKey in data) {
      const changedOldData = data[indexKey].find(
        (newData: any) =>
          JSON.stringify(newData) !==
          JSON.stringify(
            index.find(
              (p: any) => p[uniqueIdentifier] === newData[uniqueIdentifier],
            ),
          ),
      );
      if (changedOldData) {
        const itemIndex = index.findIndex(
          (d: any) => d[uniqueIdentifier] === changedOldData[uniqueIdentifier],
        );

        setIndex((prevIndex: any) => {
          prevIndex[itemIndex] = changedOldData;
          return [...prevIndex];
        });
      }
    }
  }, [data, indexKey, uniqueIdentifier, index]);

  const fetchMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + Limit);
  }, [Limit]);

  return [
    {
      data: { [indexKey]: index } as Type,
      fetching,
      error,
    },
    fetchMore,
  ];
}
