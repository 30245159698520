interface HandleProps {
  domain: number[];
  handle: { id: string; value: number; percent: number };
  getHandleProps: (id: string) => any;
  error: boolean;
  disabled?: boolean;
}

export default function Handle({
  error,
  domain,
  handle,
  getHandleProps,
  disabled = false,
}: HandleProps) {
  const [min, max] = domain;
  const { percent: leftPosition, value, id } = handle;

  return (
    <>
      <div
        className="react_time_range__handle_wrapper"
        style={{ left: `${leftPosition}%` }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        className={`react_time_range__handle_container${
          disabled ? '__disabled' : ''
        }`}
        style={{ left: `${leftPosition}%` }}
      >
        <div
          className={`react_time_range__handle_marker${error ? '__error' : ''}`}
        />
      </div>
    </>
  );
}
