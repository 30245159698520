import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Badge from 'mda2-frontend/src/generic/components/Badge';
import Transition from 'mda2-frontend/src/generic/components/Transition';
import { useNotificationsQuery } from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import { Fragment, useMemo } from 'react';
import { HiOutlineChatBubbleOvalLeft } from 'react-icons/hi2';
import { FormattedMessage } from 'translations/Intl';
import NotificationList from '../../../NotificationList';

export default function NotificationBubble(): JSX.Element | null {
  const userRoles = useStore((state) => state.user)?.roles;
  const hasuraHeader = useHasuraHeader();
  const [
    { data: notifications, fetching: loadingNotifications },
    reexecuteQuery,
  ] = useNotificationsQuery({
    context: useMemo(
      () =>
        hasuraHeader(
          userRoles?.includes(HasuraPermissions.READ_ALL)
            ? HasuraPermissions.READ_ALL
            : HasuraPermissions.READ,
        ),
      [hasuraHeader, userRoles],
    ),
  });

  usePolling(loadingNotifications, reexecuteQuery, 1000 * 30);

  return (
    <Popover as="div" className="relative xl:inline-block text-left">
      {({ open }) => (
        <>
          <PopoverButton className="flex xl:w-10 xl:h-10 items-center justify-center xl:bg-neutral-100 xl:dark:bg-neutral-700  xl:hover:bg-neutral-200 transition duration-150 xl:rounded-full">
            <HiOutlineChatBubbleOvalLeft className="size-5 hidden xl:block" />
            <div className="block xl:hidden mr-2 font-medium hover:text-primary-500">
              <FormattedMessage id="Notifications" />
            </div>
            <Transition
              show={!!notifications}
              className="xl:absolute xl:top-0 xl:right-0"
            >
              <Badge
                className="xl:absolute xl:-top-1 xl:-right-1 "
                messageLength={notifications?.unreadCount.aggregate?.count}
              />
            </Transition>
          </PopoverButton>
          <Transition show={open} as={Fragment}>
            <PopoverPanel className="xl:absolute max-h-48 overflow-hidden xl:max-h-fit -right-10 xl:right-0 w-full xl:w-max mt-2 origin-top-right bg-white dark:bg-neutral-800 dark:text-white divide-y divide-neutral-100 dark:divide-black rounded-md xl:shadow-lg ring-1 ring-black dark:ring-neutral-700 ring-opacity-5 focus:outline-none text-sm">
              <NotificationList
                open={open}
                total={notifications?.totalCount.aggregate?.count}
              />
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
