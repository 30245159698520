import Select from 'generic/components/Form/Select';
import type { SetSceneConfigMutationVariables } from 'graphql/types';
import { useEffect, useState } from 'react';
import { useIntl } from 'translations/Intl';

export type BluerangeConfigScene = Omit<
  Omit<SetSceneConfigMutationVariables, 'BeaconInfos'>,
  'MqttSystem'
> & {
  pirDelay: number;
} & { powerMode: number } & { targetBrightness: number } & {
  aloneAtWork: number;
};

interface BluerangeConfigurationProps {
  setConfiguration: React.Dispatch<
    React.SetStateAction<BluerangeConfigScene | undefined>
  >;
}

export default function BluerangeConfiguration({
  setConfiguration,
}: BluerangeConfigurationProps) {
  const intl = useIntl();
  const [pirDelay, setPIRDelay] = useState(0);
  const [powerMode, setPowerMode] = useState(0);
  const [targetBrightness, setTargetBrightness] = useState(0);
  const [aloneAtWork, setAloneAtWork] = useState(0);

  const configurationData = {
    powerMode: {
      0: intl.formatMessage({ id: 'Do not change' }),
      1: intl.formatMessage({ id: 'Full automatic (automatic on/off)' }),
      2: intl.formatMessage({ id: 'Semi automatic (manual on/automatic off)' }),
      3: intl.formatMessage({ id: 'Manual (manual on/off)' }),
      4: intl.formatMessage({
        id: 'Manual with plug-in circuit (on when power on)',
      }),
    },
    pirDelay: {
      0: intl.formatMessage({ id: 'Do not change' }),
      10: intl.formatMessage({ id: '{min} minutes' }, { min: 10 }),
      30: intl.formatMessage({ id: '{min} minutes' }, { min: 30 }),
      60: intl.formatMessage({ id: '{min} minutes' }, { min: 60 }),
      120: intl.formatMessage({ id: '{min} minutes' }, { min: 120 }),
    },
    targetBrightness: {
      0: intl.formatMessage({ id: 'Do not change' }),
      1: intl.formatMessage({ id: 'Deactivate' }),
      200: intl.formatMessage({ id: 'Lux target value' }, { lux: 200 }),
      300: intl.formatMessage({ id: 'Lux target value' }, { lux: 300 }),
      400: intl.formatMessage({ id: 'Lux target value' }, { lux: 400 }),
      500: intl.formatMessage({ id: 'Lux target value' }, { lux: 500 }),
      600: intl.formatMessage({ id: 'Lux target value' }, { lux: 600 }),
      700: intl.formatMessage({ id: 'Lux target value' }, { lux: 700 }),
      800: intl.formatMessage({ id: 'Lux target value' }, { lux: 800 }),
    },
    aloneAtWork: {
      0: intl.formatMessage({ id: 'Do not change' }),
      1: intl.formatMessage({ id: 'Activate' }),
      2: intl.formatMessage({ id: 'Deactivate' }),
    },
  };

  useEffect(() => {
    setConfiguration({
      powerMode,
      pirDelay,
      targetBrightness,
      aloneAtWork,
    });
  }, [powerMode, pirDelay, targetBrightness, aloneAtWork, setConfiguration]);

  return (
    <div className="flex flex-col space-y-2">
      <Select
        value={powerMode}
        onChangeSelected={(selected) => selected && setPowerMode(selected)}
        options={Object.keys(configurationData.powerMode).map((d) => Number(d))}
        label="On & Off behavior"
        renderValue={(d) =>
          configurationData.powerMode[
            d as keyof typeof configurationData.powerMode
          ]
        }
        isDeselectable={false}
      />
      <Select
        value={targetBrightness}
        onChangeSelected={(selected) =>
          selected && setTargetBrightness(selected)
        }
        options={Object.keys(configurationData.targetBrightness).map((d) =>
          Number(d),
        )}
        label="Brightness regulation"
        renderValue={(d) =>
          configurationData.targetBrightness[
            d as keyof typeof configurationData.targetBrightness
          ]
        }
        isDeselectable={false}
      />
      <Select
        value={pirDelay}
        onChangeSelected={(selected) => selected && setPIRDelay(selected)}
        options={Object.keys(configurationData.pirDelay).map((d) => Number(d))}
        label="Change delay time"
        renderValue={(d) =>
          configurationData.pirDelay[
            d as keyof typeof configurationData.pirDelay
          ]
        }
        isDeselectable={false}
      />
      <Select
        value={aloneAtWork}
        onChangeSelected={(selected) => selected && setAloneAtWork(selected)}
        options={Object.keys(configurationData.aloneAtWork).map((d) =>
          Number(d),
        )}
        label="Alone@Work operation"
        renderValue={(d) =>
          configurationData.aloneAtWork[
            d as keyof typeof configurationData.aloneAtWork
          ]
        }
        isDeselectable={false}
      />
    </div>
  );
}
