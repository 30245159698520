import keycloak from 'mda2-frontend/src/keycloak';
import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useStore from 'model/store';

interface PrivateWrapperProps {
  roleRequired: HasuraPermissions | HasuraPermissions[];
  children: JSX.Element | JSX.Element[] | null;
}

export default function PrivateWrapper({
  roleRequired,
  children,
}: PrivateWrapperProps) {
  const userRoles = useStore((state) => state.user)?.roles;

  const isAuthorized = (role: HasuraPermissions) =>
    (!!keycloak.token && keycloak.hasResourceRole(role)) ||
    // Special case for MDA3 role, as it is "virtual" and calculated based on the number of MDA3 beacons
    (role === HasuraPermissions.MDA3 &&
      userRoles?.includes(HasuraPermissions.MDA3));

  const authorized = Array.isArray(roleRequired)
    ? roleRequired.every((role) => isAuthorized(role))
    : isAuthorized(roleRequired);

  if (authorized) {
    return children;
  }

  return null;
}
