import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import Switch from 'mda2-frontend/src/generic/components/Form/Switch';

export interface SwitchInterface {
  name: IntlMessageKeys;
  isEnabled: boolean;
  onSetEnable: (enabled: boolean) => void;
  image?: JSX.Element;
  info?: IntlMessageKeys;
}

interface LayerInfoTogglersProps {
  switches: SwitchInterface[];
}

export default function LayerInfoTogglers({
  switches,
}: LayerInfoTogglersProps): JSX.Element {
  return (
    <>
      {switches.map((s) => (
        <div className="flex items-center justify-between gap-4" key={s.name}>
          <div className="flex items-center">
            {s.image}
            <div className="ml-2">
              <FormattedMessage id={s.name} />
            </div>
            {s.info && (
              <div className="ml-2">
                <Tooltip>
                  <FormattedMessage id={s.info} />
                </Tooltip>
              </div>
            )}
          </div>
          <Switch isEnabled={s.isEnabled} onSetEnable={s.onSetEnable} />
        </div>
      ))}
    </>
  );
}
