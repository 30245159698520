import { FormattedMessage } from 'translations/Intl';

import {
  MqttSystems,
  useSetSceneIdentifyMutation,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import SetSceneButton from 'pages/StatusView/components/BluerangeMqttAction/components/SetSceneButton';
import { HiOutlineLightBulb } from 'react-icons/hi2';

interface SetSceneIdentifyButtonProps {
  mqttTopic: string;
}

export default function SetSceneIdentifyButton({
  mqttTopic,
}: SetSceneIdentifyButtonProps): JSX.Element {
  const hasuraHeader = useHasuraHeader();
  const [, setSceneIdentifyMutation] = useSetSceneIdentifyMutation();

  return (
    <SetSceneButton
      icon={<HiOutlineLightBulb className="size-5" />}
      title="Set scene identify"
      timeout
      onProceed={() =>
        setSceneIdentifyMutation(
          {
            Topics: [mqttTopic],
            MqttSystem: MqttSystems.Mda3,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        )
      }
    >
      <FormattedMessage id="Identify scene" />
    </SetSceneButton>
  );
}
