import {
  type RoomSizeOccupancyHistoryQuery,
  useRoomSizeOccupancyHistoryQuery,
} from 'graphql/types';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import groupBy from 'utils/groupBy';
import ReportingPage from '../ReportingPage';
import BarChart from './components/LineChart';

export default function ReportRoomSize() {
  const hasuraHeader = useHasuraHeader();
  const { Start, End, Building } = useAnalyticsFilter();
  const intl = useIntl();
  const [{ data, fetching }] = useRoomSizeOccupancyHistoryQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    variables: {
      Start,
      End,
      Building,
    },
  });

  const occupancyData = data?.f_history_roomscapacity2_occupancy_custom.sort(
    (a, b) => a.Occupancy - b.Occupancy,
  );

  const capacityData = (occupancyData ?? []).map((o) => ({
    numberOfPeople: o.Occupancy,
    occupancyPercent: o.DurationPercent,
    capacity: o.Capacity,
  }));

  // For every occupancy there is an entry with same values for AvgOccupancyPercent per Capacity
  const groupedData = Object.values<
    RoomSizeOccupancyHistoryQuery['f_history_roomscapacity2_occupancy_custom']
  >(groupBy(occupancyData ?? [], (o) => o.Capacity));

  return (
    <ReportingPage
      loading={fetching}
      reportTitle="Room size"
      kpi={intl.formatMessage({
        id: 'Is the size of the meeting rooms correct?',
      })}
      cardData={[
        {
          title: `${intl.formatMessage({
            id: 'Average occupancy',
          })} ${occupancyData?.[0]?.AvgOccupancyPercentAll}%`,
          values: groupedData.map((o) => ({
            value: `${o[0]?.AvgOccupancyPercent}% (${intl.formatMessage({
              id: 'Capacity',
            })} ${o[0]?.Capacity})`,
            key: o[0]?.Capacity.toString() ?? '',
          })),
        },
        {
          title: `${intl.formatMessage({
            id: 'Mode',
          })} ${occupancyData?.[0]?.ModeAll} ${intl.formatMessage({
            id: 'Persons',
          })}`,
          values: groupedData.map((o) => ({
            value: `${o[0]?.Mode} ${intl.formatMessage({
              id: 'Persons',
            })} (${intl.formatMessage({ id: 'Capacity' })} ${o[0]?.Capacity})`,
            key: o[0]?.Capacity.toString() ?? '',
          })),
        },
        {
          title: `${intl.formatMessage({
            id: 'Max',
          })} ${occupancyData?.[0]?.MaxOccupancyAll} ${intl.formatMessage({
            id: 'Persons',
          })}`,
          values: groupedData.map((o) => ({
            value: `${o[0]?.MaxOccupancy} (${intl.formatMessage({
              id: 'Capacity',
            })} ${o[0]?.Capacity})`,
            key: o[0]?.Capacity.toString() ?? '',
          })),
        },
      ]}
    >
      <div className="h-96 w-full">
        <BarChart data={capacityData} />
      </div>
    </ReportingPage>
  );
}
