import type { AccuracyFeatureType } from 'generic/layers/AccuracyLayer';
import type { AssetFeatureType } from 'generic/layers/AssetLayer';
import type { DeskInUseFeatureType } from 'generic/layers/DeskInUseLayer';
import type { RoomBeaconFeatureType } from 'generic/layers/RoomBeaconLayer';
import type { RoomInUseFeatureType } from 'generic/layers/RoomInUseLayer';
import type TypedFeature from 'generic/layers/TypedFeature';
import type { OccupancyMapFeature } from 'pages/FindMyPlaceView/components/OccupancyMap/components/OccupancyMapPopupBody/OccupancyMapPopupBody';

import type { Locale } from 'date-fns';
import type VectorLayer from 'mda2-frontend/src/generic/layers/VectorLayer';
import type {
  FloorMapFeaturesQuery,
  FloorQuery,
  UserReservationsQuery,
} from 'mda2-frontend/src/graphql/types';
import type { Coordinate } from 'ol/coordinate';
import type { Geometry, LineString, Point, Polygon } from 'ol/geom';
import type { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

export enum Action {
  UPDATE = 'Update',
  REMOVE = 'Remove',
  ADD = 'Add',
}

export function isAssetFeature(
  feature: OccupancyMapFeature,
): feature is AssetFeatureType {
  return (feature as AssetFeatureType).getProperties().isAssetFeature;
}

export function isAssetAccuracyFeature(
  feature: OccupancyMapFeature,
): feature is AccuracyFeatureType {
  return (feature as AccuracyFeatureType).getProperties().isAccuracyFeature;
}

export function isRoomFeature(
  feature: OccupancyMapFeature,
): feature is RoomInUseFeatureType {
  return (
    (feature as RoomInUseFeatureType).getProperties().__typename ===
    'LiveRoomOccupancy'
  );
}

export function isDeskInUseFeature(
  feature: OccupancyMapFeature,
): feature is DeskInUseFeatureType {
  return (
    (feature as DeskInUseFeatureType).getProperties().__typename === 'Desks'
  );
}

export function isRoomBeaconFeature(
  feature: OccupancyMapFeature,
): feature is RoomBeaconFeatureType {
  return (feature as RoomBeaconFeatureType).getProperties().isRoomBeaconFeature;
}

export enum DeviceTypes {
  GATEWAY = 'Gateway',
  NODE = 'Node',
  ASSET = 'Asset',
  LIGHTPAD = 'Lightpad',
  LEVEL = 'Level',
  TWEAK = 'Tweak',
  NEO = 'Neo',
  SOLO_SLIM = 'Solo Slim',
  SMART_MODULE_O = 'Smartmodule O',
  SMART_MODULE_X = 'Smartmodule X',
}

export enum LightpadErrors {
  LIGHTPAD_ERROR_NON = 0, // 0
  LIGHTPAD_ERROR_INIT = 1,
  LIGHTPAD_ERROR_NUMBER_OF_SENSORS_WRONG = 2, // number of detected sensors is wrong
  LIGHTPAD_ERROR_NUMBER_OF_PANELS_WRONG = 3, // number of detected panels is wrong
  LIGHTPAD_ERROR_NUMBER_OF_LED_DRIVER_WRONG = 4, // number of detected led drivers is wrong
  LIGHTPAD_ERROR_FIRST_RUN_FORCED = 5,
  LIGHTPAD_ERROR_HW_ID_WRONG = 6,
  LIGHTPAD_ERROR_SENSOR = 7,
  LIGHTPAD_ERROR_PANEL = 8,
  LIGHTPAD_ERROR_SG = 9,
  LIGHTPAD_ERROR_ALONE_AT_WORK = 10,
  LIGHTPAD_ERROR_CURRENT_RELAIS = 11,
  LIGHTPAD_ERROR_DALI_SHORT = 12,
  LIGHTPAD_ERROR_12V_MODBUS_SHORT = 13,
  LIGHTPAD_ERROR_SUPPLY = 14,
  LIGHTPAD_WRONG_HEAD_DETECTED = 15,
  LIGHTPAD_ERROR_CLM = 16,
  LIGHTPAD_ERROR_PCBRIDGE = 17,
  LIGHTPAD_ERROR_LED_DRIVER_FAILURE = 18,
}

export interface Locales {
  [index: string]: Locale;
}

export enum RoomTypes {
  MEETING = 'Meeting',
  DESKS = 'Desksharing',
}

export enum Unit {
  PPM = 'ppm',
  PPB = 'ppb',
  DB = 'dB',
  C = '°C',
  PERCENT = '%',
}

export type ClimateRange = {
  good: number;
  acceptable: number;
  poor: number;
  unit: Unit;
};

export enum ClimateType {
  TVOC = 'clc_tvoc',
  CO2 = 'clc_co2eq',
  TEMPERATURE = 'clc_temperature',
  AUDIO = 'clc_audio',
  HUMIDITY = 'clc_humidity',
}

export enum MqttBeaconSource {
  BLUERANGE = 'Bluerange',
  EXTERNAL = 'External',
  MDA3 = 'MDA3',
}

export enum ModuleType {
  LINECOUNT = 'clc_linecount',
  LINECOUNT_IN = 'clc_linecount_in',
  LINECOUNT_OUT = 'clc_linecount_out',
  AREACOUNT = 'clc_areacount',
  PRESENCE = 'clc_presence',
}

export enum OtherType {
  DESKINUSE = 'clc_deskinuse',
  BRIGHTNESS = 'clc_brightness',
}

export type SensorTypes = ClimateType | ModuleType | OtherType;

export type Floor = FloorQuery['Floors'][number];

export type UrlParams = [key: string, value: string][];

export enum Colors {
  RED = '220,38,38', // red-600 - #dc2626
  GREEN = '5,150,105', // emerald-600 - #059668
  YELLOW = '234,179,8', // #eab208
  BLUE = '59,130,246', // #3B82F6
  PRIMARY50 = '238,242,255', // #EEF2FF
  PRIMARY200 = '199,210,254', // #C7D2FE
  PRIMARY400 = '129,140,248', // #818CF8
  BLACK = '0,0,0',
  WHITE = '255,255,255',
  RED200 = '254,202,202',
  RED400 = '248,113,113',
  RED500 = '239,68,68',
  RED700 = '185,28,28',
  RED800 = '153,27,27',
  RED900 = '127,29,29',
  NEUTRAL50 = '250,250,255',
  NEUTRAL100 = '245,245,245',
  NEUTRAL200 = '229,229,229',
  NEUTRAL300 = '212,212,212',
  NEUTRAL400 = '163,163,163',
  NEUTRAL600 = '82,82,82',
  NEUTRAL800 = '38,38,38',
  NEUTRAL900 = '23,23,23',
}

export enum Languages {
  EN = 'en',
  DE = 'de',
}

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface MarginProps {
  top: number;
  left: number;
  right: number;
  bottom: number;
}
export interface UserProps {
  name: string;
  roles: HasuraPermissions[];
  email: string;
  familyName: string;
  givenName: string;
  organization: string;
  userUuid: string;
  acls?: {
    pub?: string[];
    sub?: string[];
    all?: string[];
  };
}

export interface BinProps {
  bin: number;
  count: number;
}

export interface BinsProps {
  bin: number;
  bins: BinProps[];
}

export interface TooltipDatumProps {
  bin: BinProps;
  color: string;
  column: number;
  count: number;
  datum: BinsProps;
  gap: number;
  height: number;
  opacity: number;
  row: number;
  width: number;
  x: number;
  y: number;
}

export type DeskReservation = NonNullable<
  UserReservationsQuery['selectedDeskReservations']
>[number];
export type RoomReservation = NonNullable<
  UserReservationsQuery['selectedRoomReservations']
>[number];

export type InitialSetup = {
  clickedAdmin: boolean;
  clickedBuilding: boolean;
  addedBuilding: boolean;
  addedFloor: boolean;
  clickedAddDevice: boolean;
  clickedEnroll: boolean;
};

export type FloorRoomMapProps = {
  floor: Floor;
  buildingName?: string;
  panelOpen: boolean;
  setPanelOpen: (panelOpen: boolean) => void;
  activateMeasureDistanceInteraction: boolean;
  setActivateMeasureDistanceInteraction: (checked: boolean) => void;
};

export type BeaconFeatures = TypedFeature<
  FloorMapFeaturesQuery['MqttBeacons'][number],
  Point
>;

export type SmartModuleFeatures = TypedFeature<
  FloorMapFeaturesQuery['smartModules'][number],
  Point
>;

export type RoomFeatures = TypedFeature<
  FloorMapFeaturesQuery['Rooms'][number],
  Polygon
>;

export type DeskFeatures = TypedFeature<
  FloorMapFeaturesQuery['Desks'][number],
  Point
>;

export type DrawingDeskFeatures = TypedFeature<
  {
    Radius: number;
    DeskId: number;
    Id?: number;
    Index?: number;
    visible: boolean;
    IsNew?: boolean;
    __typename: 'DrawingDesks';
  },
  Point
>;

export type OutlineFeatures = TypedFeature<
  {
    relatedDesks: number[];
    relatedBeacon: BeaconFeatures;
  },
  Polygon
>;

export type RotateAnchorFeatures = TypedFeature<
  {
    outlineFeature: OutlineFeatures;
  },
  Point
>;

export type SnapType = {
  beaconId: string;
  snapIdentifier: string;
  geometry: LineString;
};

export type SnapFeatures = TypedFeature<SnapType, LineString>;

export type FloorRoomMapFeatures =
  | BeaconFeatures
  | RoomFeatures
  | DeskFeatures
  | SnapFeatures
  | SmartModuleFeatures;

export interface HoveredFeature<T> {
  feature: T;
  layer: VectorLayer<Geometry>;
}

interface SensorType {
  Id: number;
  Name: SensorTypes;
}

export interface Sensor {
  Id: number;
  Index: number;
  SensorType: SensorType;
}

export interface RoomSensor {
  RoomsId: number | undefined;
  SensorsId: number;
  Sign: number;
  IsFloorCount?: boolean;
}

export interface NewSensor {
  id: number;
  index: number;
  active: boolean;
  name?: string;
  roomSensors: RoomSensor[];
  type: SensorTypes;
}

// https://stackoverflow.com/questions/41139763/how-to-declare-a-fixed-length-array-in-typescript
type ArrayLengthMutationKeys =
  | 'splice'
  | 'push'
  | 'pop'
  | 'shift'
  | 'unshift'
  | number;
type ArrayItems<T extends Array<any>> = T extends Array<infer TItems>
  ? TItems
  : never;
type FixedLengthArray<T extends any[]> = Pick<
  T,
  Exclude<keyof T, ArrayLengthMutationKeys>
> & { [Symbol.iterator]: () => IterableIterator<ArrayItems<T>> };

export type NumberArray2 = FixedLengthArray<[number, number]>;
export type NumberArray4 = FixedLengthArray<[number, number, number, number]>;

export type Mda3ConfigurationType = {
  sensorPolicyId?: number;
  firmwarePackageId?: string;
  excludedFromUpdates?: boolean;
};

export enum LastWill {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export interface RoomToRemove {
  Id: number;
  Name: string;
  IsPrivate: boolean;
  Floor: {
    Number: number;
  };
}

export interface FloorStoredOffsets {
  0?: Coordinate;
  1?: Coordinate;
  2?: Coordinate;
  3?: Coordinate;
  4?: Coordinate;
}
export interface StoredOffsets {
  [name: string]: FloorStoredOffsets;
}
