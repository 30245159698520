import useToastStore from 'model/toast/store';
import {
  type 
  IntlErrorMessageKeys,
  type 
  IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

import type { GraphQLError } from 'graphql';
import type { CombinedError, OperationResult } from 'urql';

const getErrors = (error: CombinedError): string[] => {
  const errors: string[] = [];
  error.graphQLErrors.map(({ message, extensions }: GraphQLError) => {
    let newError: undefined | IntlErrorMessageKeys;

    // Catch  custom error message send from postgres (RAISE EXCEPTION USING ERRCODE = '22000', MESSAGE = 'Custom Error -')
    if (message.includes('Custom Error -')) {
      if (
        message.includes('Private room must have minimum 2 related beacons')
      ) {
        newError =
          'Custom Error - Private room must have minimum 2 related beacons';
      }
      if (message.includes('Cannot move')) {
        newError =
          'Custom Error - Cannot move the beacon out of its private room (at least 2 beacons should be present in a private room)';
      }
    } else {
      if (extensions.code === 'constraint-violation') {
        if (
          message.includes('duplicate key value violates unique constraint')
        ) {
          newError = 'error:duplicate-key';
        } else if (
          message.includes(
            'null value in column "Image" violates not-null constraint',
          )
        ) {
          newError = 'error:missing-image';
        } else if (message.includes('C_DeskReservations_DeskId_Reserved')) {
          newError = 'error:desk-exclusion-constraint';
        }
      }
      if (extensions.code === 'validation-failed') {
        newError = 'error:validation-failed';
      }
      if (extensions.code === 'permission-error') {
        if (message.includes('Email_Valid')) {
          newError = 'error:invalid-email';
        }
        if (message.includes('CK_MqttBeacons_SensorPolicyId')) {
          newError = 'error:used-sensorpolicy';
        }
        if (message.includes('CK_MqttBeacons_FirmwarePackageId')) {
          newError = 'error:used-firmwarepackage';
        }
        if (message.includes('CK_Configuration_Complete')) {
          newError = 'error:incomplete-configuration';
        }
        if (message.includes('CK_Configuration_Valid')) {
          newError = 'error:invalid-configuration';
        }
      }
    }
    errors.push(newError || 'error:default');
  });
  return errors;
};

interface ShowToastOpts {
  field: string;
  message: {
    type: 'success' | 'error';
    content: string;
  };
}

export default function useToast() {
  const intl = useIntl();
  const addErrorToast = useToastStore((state) => state.addErrorToast);
  const addSuccessToast = useToastStore((state) => state.addSuccessToast);

  const showToast = (
    result: OperationResult,
    opts?: Partial<ShowToastOpts>,
  ) => {
    const field = opts?.field ?? 'Name';
    const fields = field.split('.');

    if (result.error) {
      const errors = getErrors(result.error);
      if (errors.length > 0) {
        for (const err of errors) {
          addErrorToast(
            intl.formatMessage({
              id: err as IntlMessageKeys,
            }),
          );
        }
      }
    } else if (opts?.message) {
      const { type, content } = opts.message;
      if (type === 'success') {
        addSuccessToast(content);
      }
      if (type === 'error') {
        addErrorToast(content);
      }
    } else {
      const [operationName] = Object.keys(result.data);
      const [action, type] = (operationName ?? '').split('_');
      const returnedData = operationName
        ? (result.data[operationName]?.returning as any[])
        : [];

      if (returnedData.length > 1) {
        addSuccessToast(
          `${intl.formatMessage({
            id: action as IntlMessageKeys,
          })}: ${type}`,
        );
      } else if (
        returnedData[0] &&
        (field in returnedData[0] ||
          // For nested fields (e.g. Floor.Name) check if it exists
          (fields[0] &&
            fields[0] in returnedData[0] &&
            fields[1] &&
            fields[1] in returnedData[0][fields[0]]))
      ) {
        let entity: unknown;
        if (fields.length === 1) {
          entity = returnedData[0][field];
        } else if (fields[0] && fields[1]) {
          entity = returnedData[0][fields[0]][fields[1]];
        }

        addSuccessToast(
          `${intl.formatMessage({
            id: action as IntlMessageKeys,
          })}: ${type?.slice(0, -1)} ${entity}`,
        );
      } else {
        console.warn(
          `Key "${field}" not found in returned data. Available keys: "${Object.keys(
            returnedData?.[0],
          )}"`,
        );
      }
    }
  };

  return showToast;
}
