import { FaroErrorBoundary } from '@grafana/faro-react';
import useStore from 'mda2-frontend/src/model/store';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';

import ToastContainer from './generic/components/layout/ToastNotification/ToastContainer';
import translations from './translations';

function App(): JSX.Element {
  const language = useStore((state) => state.userSettings.language);

  return (
    <FaroErrorBoundary>
      <IntlProvider locale={language} messages={translations[language]}>
        <HelmetProvider>
          <ToastContainer />
          <Outlet />
        </HelmetProvider>
      </IntlProvider>
    </FaroErrorBoundary>
  );
}

export default App;
