import Subtitle from 'generic/components/Subtitle';
import { useBuildingsTopologyQuery } from 'graphql/types';
import useStore from 'model/store';
import ClimateValues from 'pages/ClimateView/components/ClimateValues';
import { useMemo } from 'react';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import { lower, upper } from 'utils/date';
import format from 'utils/format';
import ReportingPage from '../ReportingPage';
import ReportMap from './components/ReportMap';

function GlossaryItem({
  title,
  description,
  values,
}: {
  title: IntlMessageKeys;
  description: IntlMessageKeys;
  values?: Record<string, React.ReactNode>;
}) {
  return (
    <div className="grid grid-cols-8 py-2">
      <dt className="col-span-1 font-semibold">
        <FormattedMessage id={title} />
      </dt>
      <dd className="col-span-7">
        <FormattedMessage id={description} values={values} />
      </dd>
    </div>
  );
}

export default function ReportDescription() {
  const intl = useIntl();
  const building = useStore((state) => state.userSettings.building);
  const organizationName = useStore(
    (state) => state.organizationSettings.organizationName,
  );
  const { businessHours, warmMinutesPolicy } = useStore(
    (state) => state.organizationSettings,
  );
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : new Date()),
    [dateRange.end],
  );

  const businessHoursWithoutLunch = useMemo(
    () =>
      businessHours.BusinessHours &&
      `${format(lower(businessHours.BusinessHours), 'HH:mm')}-${format(
        upper(businessHours.BusinessHours),
        'HH:mm',
      )}`,
    [businessHours],
  );

  const businessHoursWithLunch = useMemo(
    () =>
      businessHours.LunchHours &&
      `${format(lower(businessHours.BusinessHours), 'HH:mm')}-${format(
        lower(businessHours.LunchHours),
        'HH:mm',
      )} / ${format(upper(businessHours.LunchHours), 'HH:mm')}-${format(
        upper(businessHours.BusinessHours),
        'HH:mm',
      )}`,
    [businessHours],
  );

  const [{ data, fetching }] = useBuildingsTopologyQuery();

  const floors = useMemo(
    () =>
      data?.Buildings?.find((b) => b.Name === building?.Name)
        ?.Floors.slice()
        .sort((a, b) => a.Number - b.Number),
    [data?.Buildings, building?.Name],
  );

  return (
    <ReportingPage
      loading={fetching}
      key={organizationName}
      reportTitle="Overview"
    >
      <div className="flex flex-col">
        <Subtitle
          value={intl.formatMessage(
            { id: 'Duration from to' },
            { from: format(dateFrom, 'PP'), to: format(dateTo, 'PP') },
          )}
        />
        {floors?.map((f) => (
          <ReportMap key={f.Number} floor={f} />
        ))}
        <Subtitle
          value={`${intl.formatMessage({ id: 'Building' })}: ${building?.Name}`}
        />
        <Subtitle
          value={`${intl.formatMessage({ id: 'Business hours' })}: ${businessHoursWithLunch ?? businessHoursWithoutLunch}`}
        />
        <Subtitle
          value={`${intl.formatMessage({ id: 'Desksharing policy (in minutes)' })}: ${warmMinutesPolicy}`}
        />
        <Subtitle value={intl.formatMessage({ id: 'Climate limits' })} />
        <ClimateValues hideRecommended />
        <Subtitle value={intl.formatMessage({ id: 'Glossary' })} />
        <dl className="divide-y divide-neutral-200 dark:text-white dark:divide-neutral-700">
          <GlossaryItem title="Mode" description="mode-description" />
          <GlossaryItem
            title="Occupancy"
            description="occupancy-daily-description"
            values={{
              here: <FormattedMessage id="in the organization settings" />,
            }}
          />
          <GlossaryItem title="Usage" description="usage-description" />
          <GlossaryItem
            title="Usage vs. Occupancy"
            description="usage-occupancy-description"
          />
          <GlossaryItem title="hot" description="hot-description" />
          <GlossaryItem title="warm" description="warm-description" />
          <GlossaryItem
            title="Flex desks"
            description="flex-desks-description"
          />
          <GlossaryItem title="Fix desks" description="fix-desks-description" />
          <GlossaryItem
            title="Max hourly"
            description="max-hourly-description"
          />
          <GlossaryItem title="Max daily" description="max-daily-description" />
        </dl>
      </div>
    </ReportingPage>
  );
}
