import Accordion from 'generic/components/Accordion/Accordion';
import { getLocation } from 'generic/components/layout/FilterBar/FilterBar';
import { useDesksUsageQuery, useRoomsUsageQuery } from 'graphql/types';
import useStore from 'model/store';
import UtilizationCard from 'pages/AnalyticsView/components/UtilizationCard';
import { useIntl } from 'translations/Intl';
import { endOfDayUTC, startOfDayUTC } from 'utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import type { ChartVariables } from '../LineChart/LineChart';

interface UtilizationProps {
  chartVariables: ChartVariables;
  meetingRoomOccupancy?: boolean;
}

export default function Utilization({
  chartVariables,
  meetingRoomOccupancy = false,
}: UtilizationProps) {
  const intl = useIntl();
  const location = useLocation();
  const hasuraHeader = useHasuraHeader();
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );

  const { Room, Floor, Building, Labels } = chartVariables;

  const labels =
    (Labels?.length ?? 0) > 0
      ? JSON.stringify(Labels).replace('[', '{').replace(']', '}')
      : null;

  const [{ data: deskData, fetching: fetchingDesks }] = useDesksUsageQuery({
    variables: {
      Start: startOfDayUTC(dateFrom),
      End: dateTo && endOfDayUTC(dateTo),
      Floor,
      Room,
      Building,
      Labels: labels,
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    pause: meetingRoomOccupancy,
  });

  const [{ data: roomData, fetching: fetchingRooms }] = useRoomsUsageQuery({
    variables: {
      Start: dateFrom,
      End: dateTo,
      Floor,
      Room,
      Building,
      Labels: labels,
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    pause: !meetingRoomOccupancy,
  });

  return (
    <Accordion
      initialStateOpen
      title={getLocation(intl, location, Building, Floor, Room, Labels)}
    >
      <div
        className={`grid ${
          meetingRoomOccupancy ? 'grid-cols-2' : 'grid-cols-3'
        } gap-1`}
      >
        {meetingRoomOccupancy ? (
          <>
            <UtilizationCard
              title={intl.formatMessage({ id: 'Average Occupancy' })}
              value={
                roomData?.f_history_rooms_occupancy_custom[0]
                  ?.AvgPercentageUsedMeetingRooms ?? 0
              }
              loading={fetchingRooms}
            />
            <UtilizationCard
              title={intl.formatMessage({ id: 'PercentageMaxOccupancy' })}
              value={
                roomData?.f_history_rooms_occupancy_custom[0]
                  ?.MaxPercentageUsedMeetingRooms ?? 0
              }
              loading={fetchingRooms}
            />
          </>
        ) : (
          <>
            <UtilizationCard
              dataTestId="average-occupancy"
              title={intl.formatMessage({ id: 'Average Occupancy' })}
              infoText={`${intl.formatMessage({
                id: 'hot',
              })} + ${intl.formatMessage({ id: 'warm' })}`}
              value={
                deskData?.f_history_desks_occupancy_custom[0]
                  ?.AvgPercentageUsage ?? 0
              }
              loading={fetchingDesks}
            />
            <UtilizationCard
              title={intl.formatMessage({ id: 'Highest hourly occupancy' })}
              infoText={`${intl.formatMessage({
                id: 'hot',
              })} + ${intl.formatMessage({ id: 'warm' })}`}
              value={
                deskData?.f_history_desks_occupancy_custom[0]
                  ?.MaxPercentageUsage ?? 0
              }
              loading={fetchingDesks}
            />
            <UtilizationCard
              title={intl.formatMessage({ id: 'PercentageMaxOccupancy' })}
              infoText={intl.formatMessage({
                id: 'Highest desk occupancy in the selected period',
              })}
              value={
                deskData?.f_history_desks_occupancy_custom[0]
                  ?.PercentageMaxOccupancy ?? 0
              }
              loading={fetchingDesks}
            />
          </>
        )}
      </div>
    </Accordion>
  );
}
