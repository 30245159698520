import { scaleOrdinal } from '@visx/scale';
import Legend from 'generic/components/Chart/Legend';
import PieChart from 'generic/components/PieChart';
import { useRoomOccupancyHistoryQuery } from 'graphql/types';
import { RAINBOW } from 'mda2-frontend/src/constants';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import BarChart from '../BarChart';
import ReportingPage from '../ReportingPage';

export default function ReportRoomSizeOccupancy() {
  const hasuraHeader = useHasuraHeader();
  const { Start, End, Building } = useAnalyticsFilter();
  const intl = useIntl();
  const [{ data, fetching }] = useRoomOccupancyHistoryQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    variables: {
      Start,
      End,
      Building,
    },
  });

  const occupancyData = data?.f_history_roomscapacity_occupancy_custom.sort(
    (a, b) => a.Capacity - b.Capacity,
  );

  return (
    <ReportingPage
      loading={fetching}
      reportTitle="Meeting rooms occupancy by capacity"
      kpi={intl.formatMessage({
        id: 'Is the number of meeting rooms correct or are more needed?',
      })}
      cardData={[
        {
          title: `${intl.formatMessage({
            id: 'Average daily',
          })} ${occupancyData?.[0]?.AvgDailyOccupancyAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.AvgDailyOccupancy}% (${o.Capacity})`,
            key: o.Capacity.toString(),
          })),
        },
        {
          title: `${intl.formatMessage({
            id: 'Max hourly',
          })} ${occupancyData?.[0]?.MaxHourlyOccupancyAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.MaxHourlyOccupancy}% (${o.Capacity})`,
            key: o.Capacity.toString(),
          })),
        },
        {
          title: `${intl.formatMessage({
            id: 'Max daily',
          })} ${occupancyData?.[0]?.MaxDailyOccupancyAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.MaxDailyOccupancy}% (${o.Capacity})`,
            key: o.Capacity.toString(),
          })),
        },
      ]}
    >
      <div className="flex justify-between">
        <div className="flex flex-col">
          <p>
            {intl.formatMessage({
              id: 'Number of meeting rooms by capacity',
            })}
          </p>
          <div className="size-96">
            <PieChart
              tooltipEnabled={false}
              showLabel
              colors={RAINBOW}
              dataFormat={(d) => d.value.toString()}
              data={
                occupancyData?.map((d) => ({
                  id: String(d.Capacity),
                  value: d.NumberOfRoomsWithCapacity,
                })) ?? []
              }
            />
          </div>
          <Legend
            scaleType="ordinal"
            scale={scaleOrdinal({
              domain: occupancyData?.map((d) => d.Capacity),
              range: RAINBOW,
            })}
            labelFormat={(d) =>
              `${intl.formatMessage({ id: 'Capacity' })}: ${d}`
            }
          />
        </div>
        <div className="flex flex-col">
          <p>
            {intl.formatMessage({
              id: 'Average and maximum occupancy of meeting rooms by capacity',
            })}
          </p>
          <div className="flex space-x-4">
            <div className="h-96 w-full">
              <BarChart
                data={occupancyData ?? []}
                yScaleAccessor={(d) => d.AvgDailyOccupancy}
                groupAccessor={(d) => d.Capacity.toString()}
                y1Accessor={(d) => d.MaxDailyOccupancy}
                y2Accessor={(d) => d.MaxHourlyOccupancy}
                xAxisLabel={intl.formatMessage({ id: 'Capacity' })}
              />
            </div>
          </div>
        </div>
      </div>
    </ReportingPage>
  );
}
