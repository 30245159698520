import type { DeskInUseFeatureType } from 'generic/layers/DeskInUseLayer';
import type { DeskReservationFeatureType } from 'generic/layers/DeskReservationLayer';
import type { RoomInUseFeatureType } from 'generic/layers/RoomInUseLayer';
import type { RoomReservationFeatureType } from 'generic/layers/RoomReservationsLayer';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useRoomDeskFilter from 'utils/graphql/useRoomDeskFilter';

import { format, startOfDay } from 'date-fns';
import {
  Action,
  type 
  DeskReservation,
  type 
  RoomReservation,
} from 'mda2-frontend/src/common/types';
import { LS_HIDE_CANCELLED_RESERVATIONS } from 'mda2-frontend/src/constants';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import Switch from 'mda2-frontend/src/generic/components/Form/Switch';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import {
  useInsertDeskReservationMutation,
  useInsertRoomReservationMutation,
  useUserReservationsQuery,
} from 'mda2-frontend/src/graphql/types';
import { lower, serializeRange, upper } from 'mda2-frontend/src/utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { HiOutlineCube } from 'react-icons/hi2';

import type { Event } from '../ReservationsCalendar/CustomEvent';
import UpdateReservation from '../UpdateReservation';
import NewReservation from './components/NewReservation';

const ReservationsCalendar = lazy(() => import('../ReservationsCalendar'));

function isDeskReservation(
  feature:
    | DeskReservationFeatureType
    | RoomReservationFeatureType
    | DeskInUseFeatureType
    | RoomInUseFeatureType,
): feature is DeskReservationFeatureType {
  if (
    (feature as DeskReservationFeatureType).getProperties().__typename ===
    'DeskReservations'
  ) {
    return true;
  }
  return false;
}

function isDeskInUseReservation(
  feature:
    | DeskReservationFeatureType
    | RoomReservationFeatureType
    | DeskInUseFeatureType
    | RoomInUseFeatureType,
): feature is DeskInUseFeatureType {
  if (
    (feature as DeskInUseFeatureType).getProperties().__typename === 'Desks'
  ) {
    return true;
  }
  return false;
}

const isDesk = (
  feature:
    | DeskReservationFeatureType
    | RoomReservationFeatureType
    | DeskInUseFeatureType
    | RoomInUseFeatureType,
) => isDeskInUseReservation(feature) || isDeskReservation(feature);

function isRoomInUseReservation(
  feature:
    | DeskReservationFeatureType
    | RoomReservationFeatureType
    | DeskInUseFeatureType
    | RoomInUseFeatureType,
): feature is RoomInUseFeatureType {
  if (
    (feature as RoomInUseFeatureType).getProperties().__typename ===
    'LiveRoomOccupancy'
  ) {
    return true;
  }
  return false;
}

interface ReservationModalProps {
  feature:
    | DeskReservationFeatureType
    | RoomReservationFeatureType
    | RoomInUseFeatureType
    | DeskInUseFeatureType;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  reservationStartDate?: Date;
  reservationEndDate?: Date;
}

const getReservationMessage = (cancelled: boolean, isPersonal: boolean) => {
  if (isPersonal) {
    if (cancelled) return 'Cancelled: My Reservation';
    return 'My Reservation';
  }
  if (cancelled) return 'Cancelled: Reserved';
  return 'Reserved';
};

export default function ReservationModal({
  feature,
  open = false,
  setOpen,
  onClose,
  reservationStartDate,
  reservationEndDate,
}: ReservationModalProps): JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [showCancelledEvents, setShowCancelledEvents] = useState(
    localStorage.getItem(LS_HIDE_CANCELLED_RESERVATIONS)
      ? localStorage.getItem(LS_HIDE_CANCELLED_RESERVATIONS) === 'true'
      : true,
  );
  const [newEvent, setNewEvent] = useState<Event | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [features, setFeatures] = useState<
    DeskReservation[] | RoomReservation[]
  >([]);

  const featureName = useMemo(() => {
    if (isDeskReservation(feature)) {
      return feature.getProperties().Desk.Name;
    }
    if (isDeskInUseReservation(feature)) {
      return feature.getProperties().Name;
    }
    if (isRoomInUseReservation(feature)) {
      return feature.getProperties().Name;
    }
    return feature.getProperties().Room.Name;
  }, [feature]);

  const featureId = useMemo(() => {
    if (isDeskReservation(feature)) {
      return feature.getProperties().Desk.Id;
    }
    if (isDeskInUseReservation(feature) || isRoomInUseReservation(feature)) {
      return feature.getProperties().Id;
    }
    return feature.getProperties().Room.Id;
  }, [feature]);

  const [{ data, fetching: loading }] = useUserReservationsQuery({
    variables: {
      ...useRoomDeskFilter(),
      SelectedId: featureId,
      ShowSelectedDesk: useRoomDeskFilter().Desks && !!featureId,
      ShowSelectedRoom: useRoomDeskFilter().Rooms && !!featureId,
    },
    context: useMemo(
      () => ({
        additionalTypenames: ['DeskReservations', 'RoomReservations'],
      }),
      [],
    ),
  });

  const [, addDeskReservation] = useInsertDeskReservationMutation();
  const [, addRoomReservation] = useInsertRoomReservationMutation();

  useEffect(() => {
    if (data) {
      if (isDesk(feature)) {
        const userDeskReservationIds = data.DeskReservations?.filter(
          (dR) => !dR.Cancelled,
        ).map((userDeskReservation) => userDeskReservation.Id);
        setFeatures(
          data.selectedDeskReservations?.map((deskReservation) => ({
            ...deskReservation,
            isPersonal: userDeskReservationIds?.includes(deskReservation.Id),
          })) ?? [],
        );
      } else if (!isDesk(feature)) {
        const userRoomReservationIds = data.RoomReservations?.filter(
          (dR) => !dR.Cancelled,
        ).map((userRoomReservation) => userRoomReservation.Id);
        setFeatures(
          data.selectedRoomReservations?.map((roomReservation) => ({
            ...roomReservation,
            isPersonal: userRoomReservationIds?.includes(roomReservation.Id),
          })) ?? [],
        );
      }
    }
  }, [feature, data]);

  const featureInfos = useMemo(
    () =>
      isDesk(feature)
        ? {
            name: featureName,
            icon: <FaChalkboardTeacher className="size-6 text-blue-500" />,
            category: intl.formatMessage({
              id: 'Desk',
            }),
          }
        : {
            name: featureName,
            icon: <HiOutlineCube className="size-6 text-blue-500" />,
            category: intl.formatMessage({
              id: 'Room',
            }),
          },
    [feature, featureName, intl],
  );

  const onCreate = useCallback(
    (callback: () => void) => {
      if (newEvent?.start) {
        const duration = serializeRange({
          start: {
            value: newEvent.start,
            inclusive: true,
          },
          end: {
            value: newEvent.end,
            inclusive: false,
          },
        });
        if (isDesk(feature)) {
          addDeskReservation(
            {
              Desk: [
                {
                  Id: newEvent.ressource?.id,
                  DeskId: featureId,
                  Duration: duration,
                  Cancelled: newEvent.ressource?.cancelled ?? false,
                },
              ],
            },
            hasuraHeader(HasuraPermissions.READ, [
              'DeskReservations_aggregate',
            ]),
          ).then((d) => {
            toast(d, {
              message: {
                type: 'success',
                content: intl.formatMessage(
                  {
                    id: 'Added reservation for desk',
                  },
                  { desk: featureName },
                ),
              },
            });
            callback();
          });
        } else {
          addRoomReservation(
            {
              Room: [
                {
                  Id: newEvent.ressource?.id,
                  RoomId: featureId,
                  Duration: duration,
                  Cancelled: newEvent.ressource?.cancelled ?? false,
                },
              ],
            },
            hasuraHeader(HasuraPermissions.READ, [
              'RoomReservations_aggregate',
            ]),
          ).then((d) => {
            toast(d, {
              message: {
                type: 'success',
                content: intl.formatMessage(
                  {
                    id: 'Added reservation for room',
                  },
                  { room: featureName },
                ),
              },
            });
            callback();
          });
        }
      }
    },
    [
      addDeskReservation,
      addRoomReservation,
      feature,
      featureId,
      featureName,
      hasuraHeader,
      intl,
      newEvent,
      newEvent?.start,
      toast,
    ],
  );

  const eventList = useMemo(() => {
    let events = features.map((reservation) => {
      const start = lower(reservation.Duration);
      const end = upper(reservation.Duration);
      if (
        reservation.Cancelled &&
        start < startOfDay(new Date()) &&
        end < startOfDay(new Date())
      ) {
        // remove cancelled reservation in the past
        return null;
      }
      if (!showCancelledEvents && reservation.Cancelled) {
        return null;
      }
      return {
        title: `${intl.formatMessage({
          id: getReservationMessage(
            reservation.Cancelled,
            reservation.IsPersonal ?? false,
          ),
        })} (${format(start, 'HH:mm')} - ${format(end, 'HH:mm')})`,
        start,
        end,
        ressource: {
          id: reservation.Id,
          cancelled: reservation.Cancelled,
          isDesk: isDesk(feature),
          name: featureName,
          featureId,
          isPersonal: reservation.IsPersonal,
        },
      };
    });
    events = events.filter((e) => e);
    if (newEvent) {
      return [...events, newEvent] as Event[];
    }
    return events as Event[];
  }, [
    features,
    newEvent,
    showCancelledEvents,
    intl,
    feature,
    featureName,
    featureId,
  ]);

  useEffect(() => {
    if (!open) {
      setNewEvent(null);
      setSelectedEvent(null);
    }
  }, [open]);

  return (
    <Modal
      title={
        <div className="flex flex-col md:flex-row justify-between">
          <div>
            {featureInfos.category}: {featureInfos.name}
          </div>
          <div className="flex justify-center items-center gap-1 text-sm ">
            <FormattedMessage id="Show cancelled reservations" />
            <Switch
              isEnabled={showCancelledEvents}
              onSetEnable={(enabling) => {
                localStorage.setItem(
                  LS_HIDE_CANCELLED_RESERVATIONS,
                  JSON.stringify(enabling),
                );
                setShowCancelledEvents(enabling);
              }}
              data-test-id="reservation-show-cancelled"
            />
          </div>
        </div>
      }
      action={Action.UPDATE}
      open={!!open}
      setShowModal={setOpen}
      icon={featureInfos.icon}
      wrapperClassName="sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
      className="dark:bg-opacity-100"
      footer={
        <ModalFooter
          disabled={!newEvent}
          action={Action.UPDATE}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          onProceed={() => {
            onCreate(() => {
              onClose();
              setNewEvent(null);
              setSelectedEvent(null);
            });
          }}
          onCancel={() => {
            onClose();
            setNewEvent(null);
            setSelectedEvent(null);
          }}
          customButton={
            newEvent && (
              <Button
                id="reservation-save-and-new-event"
                data-test-id="reservation-save-and-new-event"
                onClick={() => {
                  onCreate(() => {
                    setNewEvent(null);
                    setSelectedEvent(null);
                  });
                }}
                className="mt-3 bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus:ring-blue-500 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto text-sm"
              >
                <FormattedMessage id="Confirm and new reservation" />
              </Button>
            )
          }
        />
      }
    >
      {!loading && (
        <div className="map-height" data-test-id="reservation-calendar">
          <ReservationsCalendar
            eventList={eventList}
            newEvent={newEvent}
            setNewEvent={(evt) => setNewEvent(evt)}
            selectedEvent={selectedEvent}
            setSelectedEvent={(evt) => setSelectedEvent(evt)}
            featureName={featureName}
            featureId={featureId}
            isDesk={isDesk(feature)}
            reservationStartDate={reservationStartDate}
            reservationEndDate={reservationEndDate}
          />
        </div>
      )}
      {newEvent && (
        <NewReservation feature={featureInfos.name} newEvent={newEvent} />
      )}
      {!newEvent && selectedEvent && (
        <UpdateReservation selectedEvent={selectedEvent} />
      )}
    </Modal>
  );
}
