/* eslint-disable jsx-a11y/label-has-associated-control */
import Checkbox from 'generic/components/Form/Checkbox';
import Switch from 'generic/components/Form/Switch/Switch';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import { useBusinessHoursQuery } from 'mda2-frontend/src/graphql/types';
import {
  getAllWeekdays,
  getInterval,
  lower,
  serializeRange,
  upper,
} from 'mda2-frontend/src/utils/date';
import localize from 'mda2-frontend/src/utils/format';
import useStore from 'model/store';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';

import Transition from 'generic/components/Transition';
import TimeRange from './components/TimeRange';

export interface CheckedDays {
  DayOfTheWeek: number;
  BusinessHours: string;
  Lunch?: string | null;
  Id?: number;
  OrganizationId: string;
}

interface WorkdaySelectProps {
  deletedDays: string[];
  setDeletedDays: (val: string[]) => void;
  setTimeObjects: (value: CheckedDays[]) => void;
  setValidated: (val: boolean) => void;
}

export default function WorkdaySelect({
  deletedDays,
  setDeletedDays,
  setTimeObjects,
  setValidated,
}: WorkdaySelectProps): JSX.Element {
  const intl = useIntl();
  const organizationId = useStore(
    (state) => state.organizationSettings.organizationUuid,
  );
  const [{ data }] = useBusinessHoursQuery();

  const [lunchStart, setLunchStart] = useState(
    data?.BusinessHours[0]?.Lunch
      ? lower(data.BusinessHours[0]?.Lunch)
      : getInterval('11:30:00'),
  );
  const [lunchEnd, setLunchEnd] = useState(
    data?.BusinessHours[0]?.Lunch
      ? upper(data.BusinessHours[0]?.Lunch)
      : getInterval('12:30:00'),
  );
  const [lunchBreak, setLunchBreak] = useState(
    data?.BusinessHours[0]?.Lunch !== null || false,
  );
  const [selectedInterval, setSelectedInterval] = useState([
    data?.BusinessHours[0]?.BusinessHours
      ? lower(data.BusinessHours[0]?.BusinessHours)
      : getInterval('08:00:00'),
    data?.BusinessHours[0]?.BusinessHours
      ? upper(data.BusinessHours[0]?.BusinessHours)
      : getInterval('17:00:00'),
  ]);
  const [checkedDays, setCheckedDays] = useState<CheckedDays[]>([]);

  useEffect(() => {
    if (checkedDays.length !== 0 && !lunchBreak) {
      setValidated(true);
    } else {
      setValidated(false);
    }

    if (lunchBreak && selectedInterval[0] && selectedInterval[1]) {
      if (
        lunchEnd > lunchStart &&
        lunchEnd < selectedInterval[1] &&
        lunchStart > selectedInterval[0]
      ) {
        setValidated(true);
      } else {
        setValidated(false);
      }
      if (checkedDays.length === 0) {
        setValidated(false);
      }
    }
  }, [
    checkedDays,
    lunchStart,
    lunchEnd,
    selectedInterval?.[0],
    selectedInterval?.[1],
    lunchBreak,
    setValidated,
  ]);

  useEffect(() => {
    setTimeObjects(
      checkedDays.map((item) => ({
        Id: item.Id,
        DayOfTheWeek: item.DayOfTheWeek,
        BusinessHours: serializeRange(
          {
            start: {
              value: selectedInterval[0] ? selectedInterval[0] : new Date(),
              inclusive: true,
            },
            end: {
              value: selectedInterval[1] ? selectedInterval[1] : new Date(),
              inclusive: false,
            },
          },
          true,
        ),
        Lunch: lunchBreak
          ? serializeRange(
              {
                start: { value: lunchStart, inclusive: true },
                end: { value: lunchEnd, inclusive: false },
              },
              true,
            )
          : null,
        OrganizationId: organizationId,
      })) as CheckedDays[],
    );
  }, [
    checkedDays,
    lunchEnd,
    lunchStart,
    selectedInterval?.[0],
    selectedInterval?.[1],
    organizationId,
    lunchBreak,
    setTimeObjects,
  ]);

  useEffect(() => {
    if (data?.BusinessHours[0]?.BusinessHours) {
      if (data.BusinessHours[0].Lunch) {
        setLunchStart(lower(data.BusinessHours[0].Lunch));
        setLunchEnd(upper(data.BusinessHours[0].Lunch));
      }
      setLunchBreak(data.BusinessHours[0].Lunch !== null);

      setSelectedInterval([
        lower(data.BusinessHours[0].BusinessHours),
        upper(data.BusinessHours[0].BusinessHours),
      ]);
      setCheckedDays(data.BusinessHours);
    }
  }, [
    data?.BusinessHours[0]?.Lunch,
    data?.BusinessHours,
    data?.BusinessHours[0]?.BusinessHours,
  ]);

  const handleOnChange = (position: number) => {
    const elementAdded = checkedDays.find(
      (day) => day.DayOfTheWeek === position,
    );

    if (elementAdded) {
      // Remove the element from the list as it was already added
      setDeletedDays([
        ...deletedDays,
        checkedDays
          .filter((b: CheckedDays) => b.DayOfTheWeek === position)[0]
          ?.Id?.toString() ?? '',
      ]);
      setCheckedDays(
        checkedDays.filter((b: CheckedDays) => b.DayOfTheWeek !== position),
      );
    } else {
      setCheckedDays([
        ...checkedDays,
        {
          DayOfTheWeek: position,
          BusinessHours: `[${selectedInterval[0]}, ${selectedInterval[1]})`,
          Lunch: `[${lunchStart}, ${lunchEnd})`,
          OrganizationId: '',
        },
      ]);
    }
  };

  const disabledIntervals = useMemo(
    () =>
      lunchBreak
        ? [
            {
              start: lunchStart,
              end: lunchEnd,
            },
          ]
        : [],
    [lunchBreak, lunchStart, lunchEnd],
  );

  return (
    <>
      <label
        htmlFor="weekDay"
        className="block text-base md:text-sm text-left text-neutral-700 dark:text-white"
      >
        <FormattedMessage id="Business hours" />
      </label>

      <div id="weekDay" data-test-id="workday-select" className="space-y-2">
        <div className="flex text-base md:text-sm">
          {localize(
            selectedInterval[0] ? selectedInterval[0] : new Date(),
            'HH:mm',
          )}{' '}
          <FormattedMessage id="to" />{' '}
          {localize(
            selectedInterval[1] ? selectedInterval[1] : new Date(),
            'HH:mm',
          )}
          <Transition show={lunchBreak} as="span" className="ml-1">
            <FormattedMessage
              id="with lunch from {start} to {end}"
              values={{
                start: localize(lunchStart, 'HH:mm'),
                end: localize(lunchEnd, 'HH:mm'),
              }}
            />
          </Transition>
        </div>

        <TimeRange
          ticksNumber={12}
          selectedInterval={selectedInterval}
          onUpdateCallback={() => {}}
          onChangeCallback={setSelectedInterval}
          disabledIntervals={disabledIntervals}
        />

        <div className="flex space-x-4 pb-2 flex-wrap justify-center md:justify-start">
          {getAllWeekdays().map((day, index) => (
            <Checkbox
              key={day}
              checked={
                checkedDays.filter(
                  (d: CheckedDays) => d.DayOfTheWeek === index + 1,
                ).length > 0
              }
              setChecked={() => handleOnChange(index + 1)}
              label={day}
            />
          ))}
        </div>

        <div className="flex space-x-2 items-center">
          <Switch
            isEnabled={lunchBreak}
            onSetEnable={() => setLunchBreak(!lunchBreak)}
          />
          <div>
            <FormattedMessage id="Lunch break" />
          </div>
        </div>

        <Transition show={lunchBreak}>
          <div className="flex flex-row space-x-4">
            <Input
              type="time"
              step="1800"
              value={localize(lunchStart, 'HH:mm')}
              label={intl.formatMessage({
                id: 'Lunch start',
              })}
              id="start-lunch"
              onChangeValue={(v) => setLunchStart(getInterval(v))}
              required
            />

            <Input
              type="time"
              step="1800"
              value={localize(lunchEnd, 'HH:mm')}
              label={intl.formatMessage({
                id: 'Lunch end',
              })}
              id="end-lunch"
              onChangeValue={(v) => setLunchEnd(getInterval(v))}
              required
            />
          </div>
        </Transition>
      </div>
    </>
  );
}
