import Accordion from 'generic/components/Accordion';
import { useChangelogsQuery } from 'graphql/types';
import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

import Subtitle from 'generic/components/Subtitle';
import { Suspense, lazy } from 'react';

// Plugin is only needed for that page -> lazy import
const ReactMarkdown = lazy(() => import('react-markdown'));

function Skeleton() {
  return (
    <div className="space-y-3">
      {Array.from({ length: 1 }).map((_, key) => (
        <div
          key={key}
          className="w-full animate-pulse flex flex-col group-hover:bg-neutral-50 dark:group-hover:bg-neutral-700"
        >
          <div className="flex items-center pb-2 justify-between">
            <div className="flex items-center">
              <div className="w-32 bg-neutral-200 h-4 rounded-md" />
            </div>
          </div>
          <div className="space-y-4">
            <div className="w-full bg-neutral-200 h-4 rounded-md" />
            <div className="w-full bg-neutral-200 h-4 rounded-md" />
            <div className="w-full bg-neutral-200 h-4 rounded-md" />
          </div>
        </div>
      ))}
    </div>
  );
}

function LinkComponent(props: any) {
  const userRoles = useStore((state) => state.user)?.roles;

  return userRoles?.includes(HasuraPermissions.READ_ALL) ? (
    <a {...props} />
  ) : (
    <span {...props} />
  );
}

function TitleComponent(props: any) {
  return <div className="md:text-xl text-lg" {...props} />;
}

function SubtitleComponent(props: any) {
  return <div className="md:text-lg text-md" {...props} />;
}

function ListComponent(props: any) {
  return <ul className="list-disc pl-5" {...props} />;
}

function ChangelogBody({ children }: { children: string }) {
  const userRoles = useStore((state) => state.user)?.roles;

  return (
    <Suspense fallback={<Skeleton />}>
      <ReactMarkdown
        urlTransform={
          userRoles?.includes(HasuraPermissions.READ_ALL) ? undefined : () => ''
        }
        components={{
          a: LinkComponent,
          h1: TitleComponent,
          h2: TitleComponent,
          h3: SubtitleComponent,
          ul: ListComponent,
        }}
      >
        {children}
      </ReactMarkdown>
    </Suspense>
  );
}

export default function ChangelogList() {
  const [{ data }] = useChangelogsQuery();

  return (
    <>
      <Accordion
        title={<FormattedMessage id="Changelog MDA" />}
        initialStateOpen={false}
      >
        <div className="divide-y divide-dashed space-y-2">
          {data?.Changelogs.map((changelog) => (
            <div key={changelog.Version} className="pt-2">
              <ChangelogBody>
                {changelog.Changes.replace(/\[MDA.*?\]/g, '')}
              </ChangelogBody>
            </div>
          ))}
        </div>
      </Accordion>
      <Accordion
        title={<FormattedMessage id="Changelog Firmware" />}
        initialStateOpen={false}
      >
        <div className="divide-y divide-dashed space-y-2">
          {data?.Firmwares.map(
            (changelog) =>
              changelog.Changelog && (
                <div key={changelog.Version} className="pt-2">
                  <Subtitle value={changelog.Module.Name} />
                  <ChangelogBody>
                    {changelog.Changelog.replace(/\[FIR.*?\]/g, '')}
                  </ChangelogBody>
                </div>
              ),
          )}
        </div>
      </Accordion>
    </>
  );
}
