import { curveBasis } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import { AnimatedAreaSeries, Tooltip, XYChart } from '@visx/xychart';
import { addMinutes, subWeeks } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import type { MarginProps } from 'mda2-frontend/src/common/types';
import LoadingSpinner from 'mda2-frontend/src/generic/components/LoadingSpinner';
import {
  type SensorHistoryQuery,
  useSensorHistoryQuery,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import localize from 'mda2-frontend/src/utils/format';
import { primaryColorToRGB } from 'mda2-frontend/src/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import { useMemo } from 'react';
import { FormattedMessage } from 'translations/Intl';
import { lower, upper } from 'utils/date';
import useXYChartTheme from 'utils/useXYChartTheme';

interface ResponsiveSensorChartProps {
  margin?: MarginProps;
  sensorId: number;
}

interface SensorChartProps extends ResponsiveSensorChartProps {
  height: number;
}

export type ChartData = SensorHistoryQuery['SensorHistories'][number];

function SensorChart({
  height,
  margin = { top: 2, left: 0, right: 0, bottom: 2 },
  sensorId,
}: SensorChartProps): JSX.Element | null {
  const theme = useXYChartTheme({ colorRange: [primaryColorToRGB(500)] });
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;

  const [{ data: sensorData, fetching: loadingSensor }] = useSensorHistoryQuery(
    {
      variables: useMemo(
        () => ({
          Start: subWeeks(new Date(), 2),
          SensorId: sensorId,
        }),
        [sensorId],
      ),
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.VIEW_STATUS,
          ),
        [hasuraHeader, userRoles],
      ),
    },
  );

  const data = useMemo(() => {
    const tempData: { Value: number; Unit: string; Date: Date }[] = [];
    for (const d of sensorData?.SensorHistories ?? []) {
      for (
        let i = lower(d.Duration);
        i <= upper(d.Duration);
        i = addMinutes(i, 15)
      ) {
        tempData.push({
          Value: d.Value,
          Unit: d.Sensor.SensorType.Unit ?? '',
          // SensorHistories "Duration" is saved in local time zone, convert it back to UTC
          // as the browser will then display it in local time again
          Date: toZonedTime(i, 'UTC'),
        });
      }
    }
    return tempData.length === 0
      ? [{ Value: 0, Unit: '', Date: new Date() }]
      : tempData;
  }, [sensorData?.SensorHistories]);

  return (
    <>
      <LoadingSpinner loading={loadingSensor} />
      <XYChart
        margin={margin}
        theme={theme}
        height={height}
        xScale={{
          type: 'band',
          paddingInner: 0.2,
        }}
        yScale={{
          type: 'linear',
          domain: [
            Math.min(...data.map((c) => c.Value)),
            Math.max(...data.map((c) => c.Value)),
          ],
          nice: true,
          zero: false,
        }}
      >
        <AnimatedAreaSeries
          dataKey="Date"
          data={data}
          xAccessor={(d) => d.Date}
          yAccessor={(d) => d.Value}
          fillOpacity={0.4}
          curve={curveBasis}
        />
        <Tooltip<(typeof data)[number]>
          showVerticalCrosshair
          renderTooltip={({ tooltipData, colorScale }) => (
            <div className="dark:text-neutral-200">
              {localize(
                tooltipData?.nearestDatum?.datum.Date ?? new Date(),
                'eeeeee do LLL, p',
              )}
              <div data-test-id="offline-chart-tooltip">
                <span
                  style={{
                    color: colorScale?.('Date'),
                    textDecoration: 'underline',
                  }}
                >
                  <FormattedMessage id="Value" />
                </span>{' '}
                {Number.isNaN(tooltipData?.nearestDatum?.datum.Value)
                  ? '–'
                  : tooltipData?.nearestDatum?.datum.Value.toFixed(1)}{' '}
                {tooltipData?.nearestDatum?.datum.Unit}
              </div>
            </div>
          )}
        />
      </XYChart>
    </>
  );
}

export default function ResponsiveSensorChart(
  props: ResponsiveSensorChartProps,
): JSX.Element {
  const { sensorId } = props;
  return (
    <ParentSize>
      {({ height }) => (
        <SensorChart {...props} height={height} sensorId={sensorId} />
      )}
    </ParentSize>
  );
}
