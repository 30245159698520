import Event from 'ol/events/Event';

export enum SourceChangeEventType {
  CHANGE = 'change',
}

export default class MoveEvent extends Event {
  width: number;

  height: number;

  constructor(type: SourceChangeEventType, width: number, height: number) {
    super(type);
    this.width = width;
    this.height = height;
  }
}
