import Input from 'generic/components/Form/Input/Input';
import {
  type UpdateModulesQuery,
  useConfigureMqttDeviceFirmwareMutation,
  useUpdateModulesQuery,
} from 'graphql/types';
import { useIntl } from 'translations/Intl';
import parseMda3Topic from 'utils/parseMda3Topic';

import Select from 'generic/components/Form/Select';
import Subtitle from 'generic/components/Subtitle';
import Transition from 'generic/components/Transition';
import { Action } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useMemo, useState } from 'react';
import { HiOutlinePaperClip } from 'react-icons/hi2';
import { coerce, lt } from 'semver';

export type UpgradeBeacons = {
  mqttTopic: string;
  espSensVersion?: string;
};

interface UpgradeBeaconModalProps {
  setBeaconsToUpgrade: (beaconsToUpgrade?: UpgradeBeacons[]) => void;
  beaconsToUpgrade?: UpgradeBeacons[];
}

export default function UpgradeBeaconModal({
  beaconsToUpgrade,
  setBeaconsToUpgrade,
}: UpgradeBeaconModalProps): JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [updateFile, setUpdateFile] = useState('');
  const [updateUrl, setUpdateUrl] = useState('');
  const [selectedModule, setSelectedModule] = useState<
    UpdateModulesQuery['Modules'][number] | undefined
  >();
  const [selectedFirmwareId, setSelectedFirmwareId] = useState<
    number | undefined
  >();
  const [{ fetching }, upgradeBeacons] =
    useConfigureMqttDeviceFirmwareMutation();

  const [{ data: modules }] = useUpdateModulesQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.READ_ALL),
      [hasuraHeader],
    ),
  });

  const isLegacy = (espSensVersion: string) => {
    const semVer = coerce(espSensVersion, { includePrerelease: true });
    if (semVer) {
      const { major, minor, patch, prerelease, version } = semVer;
      return lt(
        prerelease.length ? `${major}.${minor}.${patch}` : version,
        '3.0.0',
      );
    }
    return lt(espSensVersion, '3.0.0');
  };

  const hasEspSensLegacyVersion = (espSensVersion?: string) =>
    espSensVersion ? isLegacy(espSensVersion) : false;

  const containsLegacyBeacons = beaconsToUpgrade?.some(({ espSensVersion }) =>
    hasEspSensLegacyVersion(espSensVersion),
  );
  const containsNewBeacons = beaconsToUpgrade?.some(
    ({ espSensVersion }) => !hasEspSensLegacyVersion(espSensVersion),
  );
  const resetValues = () => setBeaconsToUpgrade(undefined);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Upgrade selected beacons',
      })}
      action={Action.UPDATE}
      open={!!beaconsToUpgrade}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={
            (containsLegacyBeacons && updateFile === '') ||
            (containsNewBeacons && updateUrl === '')
          }
          proceed={intl.formatMessage({
            id: 'Upgrade selected beacons',
          })}
          isLoading={fetching}
          action={Action.UPDATE}
          onProceed={() => {
            if (beaconsToUpgrade) {
              upgradeBeacons(
                {
                  UserInput: {
                    devices: beaconsToUpgrade.map(
                      ({ mqttTopic, espSensVersion }) => {
                        // eCLM also uses the new "update_url", however doesn't have a espSensVersion
                        const legacy = hasEspSensLegacyVersion(espSensVersion);
                        return {
                          topic: legacy
                            ? `cmd/${parseMda3Topic(mqttTopic)}/update_file`
                            : `cmd/${parseMda3Topic(mqttTopic)}/update_url`,
                          legacyMode: legacy,
                          path: legacy ? updateFile : updateUrl,
                          module: legacy
                            ? undefined
                            : selectedModule?.Name ?? '',
                        };
                      },
                    ),
                  },
                },
                hasuraHeader(HasuraPermissions.READ_ALL),
              ).then((data) => {
                if (data.error) {
                  toast(data, {
                    message: {
                      type: 'error',
                      content: intl.formatMessage({
                        id: 'Error upgrading devices',
                      }),
                    },
                  });
                } else {
                  toast(data, {
                    message: {
                      type: 'success',
                      content: intl.formatMessage({
                        id: 'Upgraded beacons',
                      }),
                    },
                  });
                }
                resetValues();
              });
            } else {
              resetValues();
            }
          }}
          onCancel={resetValues}
        />
      }
    >
      {/* Legacy versions with update_file support */}
      <Transition show={containsLegacyBeacons}>
        <div className="flex flex-col space-y-1">
          <Subtitle value="Legacy" />
          <Input
            type="text"
            value={updateFile}
            label={intl.formatMessage({ id: 'Update file' })}
            onChangeValue={(v) => setUpdateFile(v)}
            placeholder={intl.formatMessage({ id: 'Update file' })}
            icon={
              <HiOutlinePaperClip className="size-5 dark:text-neutral-200" />
            }
          />
        </div>
      </Transition>

      {/* Newer versions that support update_url */}
      <Transition show={containsNewBeacons}>
        <div className="flex flex-col space-y-1">
          <Select
            label="Module"
            value={
              selectedModule &&
              modules?.Modules.find((m) => m.Id === selectedModule.Id)
            }
            options={modules?.Modules ?? []}
            keyParameter="Id"
            renderValue={(m) => m?.Name ?? ''}
            onChangeSelected={(selected) => {
              if (selected) {
                setSelectedModule(selected);
              }
            }}
            isDeselectable={false}
          />

          <Transition show={!!selectedModule}>
            <Select
              label="Firmware URL"
              value={
                selectedModule &&
                modules?.Modules.find(
                  (m) => m.Id === selectedModule.Id,
                )?.Firmwares.find((f) => f.Id === selectedFirmwareId)
              }
              options={
                (selectedModule &&
                  modules?.Modules.find(
                    (m) => m.Id === selectedModule.Id,
                  )?.Firmwares.filter((f) => f.Path)) ??
                []
              }
              keyParameter="Id"
              renderValue={(f) => f?.Version ?? ''}
              onChangeSelected={(selected) => {
                if (selected) {
                  setSelectedFirmwareId(selected.Id);
                  if (selected.Path) {
                    setUpdateUrl(selected.Path);
                  }
                }
              }}
              isDeselectable={false}
            />
          </Transition>
        </div>
      </Transition>
    </Modal>
  );
}
