import { isAssetAccuracyFeature, isAssetFeature } from 'common/types';
import AssetPopup from 'pages/FindMyPlaceView/components/OccupancyMap/components/AssetPopup';

import { Tooltip } from '@visx/tooltip';
import Card from 'mda2-frontend/src/generic/components/Card';
import Map from 'mda2-frontend/src/generic/components/Map';
import Popup from 'mda2-frontend/src/generic/components/Popup';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import AccuracyLayer, {
  type 
  AccuracyFeatureType,
} from 'mda2-frontend/src/generic/layers/AccuracyLayer';
import AssetLayer, {
  type 
  AssetFeatureType,
} from 'mda2-frontend/src/generic/layers/AssetLayer';
import BaseLayer from 'mda2-frontend/src/generic/layers/BaseLayer';
import type Layer from 'mda2-frontend/src/generic/layers/Layer';
import {
  type 
  OccupancyMapQuery,
  useFloorImageQuery,
} from 'mda2-frontend/src/graphql/types';
import useDeviceDetect from 'mda2-frontend/src/utils/useDeviceDetect';
import type { MapBrowserEvent } from 'ol';
import OLMap from 'ol/Map';
import { unByKey } from 'ol/Observable';
import type { Coordinate } from 'ol/coordinate';
import { touchOnly } from 'ol/events/condition';
import { useCallback, useEffect, useState } from 'react';

interface AssetMapProps {
  data: NonNullable<OccupancyMapQuery['MqttAssetTrackers']>[number];
  floorNumber: number;
  buildingName: string;
}

export default function AssetMap({
  data,
  floorNumber,
  buildingName,
}: AssetMapProps): JSX.Element {
  const { isMobile } = useDeviceDetect();
  const [olListenersKeys] = useState<any[]>([]);
  const [baseLayer] = useState(new BaseLayer());
  const [assetLayer] = useState(new AssetLayer());
  const [accuracyLayer] = useState(new AccuracyLayer());
  const [hoveredFeature, setHoveredFeature] = useState(false);
  const [hoveredAsset, setHoveredAsset] = useState<
    AssetFeatureType | AccuracyFeatureType | undefined | null
  >(null);
  const [hoveredCoords, setHoveredCoords] = useState<Coordinate | null>(null);
  const [layers] = useState<Layer[]>([baseLayer, accuracyLayer, assetLayer]);
  const [map] = useState(new OLMap({}));

  const [{ data: floorImageData, fetching: loadingFloor }] = useFloorImageQuery(
    {
      variables: { FloorNumber: floorNumber, BuildingName: buildingName },
    },
  );

  useEffect(() => {
    if (floorImageData?.Floors[0]?.Image) {
      const image = floorImageData.Floors[0].Image;
      baseLayer.setImage(image ?? '');
    }
  }, [baseLayer, floorImageData?.Floors[0]?.Image]);

  const setListener = useCallback(() => {
    olListenersKeys.push(
      baseLayer.on('change', (evt: any) => {
        assetLayer.setBaseImageSize(evt.width, evt.height);
        accuracyLayer.setBaseImageSize(evt.width, evt.height);
        assetLayer.setFeatures([data]);
        accuracyLayer.setFeatures([data]);
      }),
    );
  }, [accuracyLayer, assetLayer, baseLayer, data, olListenersKeys]);

  useEffect(() => {
    setListener();
    return () => {
      unByKey(olListenersKeys);
    };
  }, [olListenersKeys, setListener]);

  const showFeatureInfo = useCallback(
    (
      evt: MapBrowserEvent<PointerEvent>,
      feat?: AssetFeatureType | AccuracyFeatureType,
    ) => {
      if (feat?.getProperties().Name) {
        setHoveredCoords(evt.coordinate);
        if (isAssetFeature(feat)) {
          assetLayer.hoveredFeature = feat;
        }
        if (isAssetAccuracyFeature(feat)) {
          accuracyLayer.hoveredFeature = feat;
        }
        setHoveredAsset(feat);
        assetLayer.olLayer.changed();
        accuracyLayer.olLayer.changed();
        setHoveredFeature(true);
      } else {
        setHoveredCoords(null);
        setHoveredAsset(null);
        setHoveredFeature(false);
        assetLayer.hoveredFeature = undefined;
        accuracyLayer.hoveredFeature = undefined;
        assetLayer.olLayer.changed();
        accuracyLayer.olLayer.changed();
      }
      evt.map.getTargetElement().style.cursor = feat ? 'pointer' : '';
    },
    [accuracyLayer, assetLayer],
  );

  return (
    <Card className="relative m-2" fullScreenButton>
      <Loader loading={loadingFloor} />
      <Map<AssetFeatureType>
        map={map}
        layers={layers}
        isLoadingFeatures={loadingFloor}
        onFeaturesClick={(features, evt) => {
          if (touchOnly(evt)) {
            showFeatureInfo(evt, features[0]);
          }
        }}
        onFeaturesHover={(hoveredFeatures, evt) => {
          const features = hoveredFeatures
            .filter((f) => !isAssetAccuracyFeature(f.feature))
            .map((hF) => hF.feature);
          showFeatureInfo(evt, features[0]);
        }}
      />
      {hoveredFeature && hoveredAsset && (
        <Popup
          map={map}
          popupCoordinate={hoveredCoords}
          renderTooltip={(props) => {
            if (isMobile) {
              return (
                <Card className="absolute bottom-0 right-0 left-0 z-20">
                  <AssetPopup hoveredFeature={hoveredAsset} />
                </Card>
              );
            }
            return (
              <Tooltip {...props}>
                <AssetPopup hoveredFeature={hoveredAsset} />
              </Tooltip>
            );
          }}
        />
      )}
    </Card>
  );
}
