import { useFlexOccupancyHistoryQuery } from 'graphql/types';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import ReportingPage from '../ReportingPage';
import BarChart from './components/BarChart';

export default function ReportFlexOccupancy() {
  const hasuraHeader = useHasuraHeader();
  const intl = useIntl();
  const { Start, End, Building } = useAnalyticsFilter();
  const [{ data, fetching }] = useFlexOccupancyHistoryQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    variables: {
      Start,
      End,
      Building,
    },
  });

  const occupancyData = data?.f_history_desksfloor_occupancy_custom.sort(
    (a, b) => a.Floor - b.Floor,
  );
  const dailyData = (data?.f_history_desks_occupancy_daily ?? []).map((d) => ({
    date: d.Date,
    warmPercentage: d.PercentageWarmMinutes,
    hotPercentage: d.PercentageHotMinutes,
  }));

  return (
    <ReportingPage
      loading={fetching}
      reportTitle="Desks flex"
      kpi={intl.formatMessage({
        id: 'Is the number of flex desks correct or are more needed?',
      })}
      cardData={[
        {
          title: `${intl.formatMessage({
            id: 'Average daily',
          })} ${occupancyData?.[0]?.AvgDailyOccupancyAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.AvgDailyOccupancy}% (${intl.formatMessage(
              {
                id: '{number} Floor',
              },
              { number: o.Floor },
            )})`,
            key: o.Floor.toString(),
          })),
        },
        {
          title: `${intl.formatMessage({
            id: 'Max hourly',
          })} ${occupancyData?.[0]?.MaxHourlyOccupancyAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.MaxHourlyOccupancy}% (${intl.formatMessage(
              {
                id: '{number} Floor',
              },
              { number: o.Floor },
            )})`,
            key: o.Floor.toString(),
          })),
        },
        {
          title: `${intl.formatMessage({
            id: 'Highest usage',
          })} ${occupancyData?.[0]?.MaxHourlyUsageAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.MaxHourlyUsage}% (${intl.formatMessage(
              {
                id: '{number} Floor',
              },
              { number: o.Floor },
            )})`,
            key: o.Floor.toString(),
          })),
        },
      ]}
    >
      <div className="h-96 w-full">
        <BarChart data={dailyData} />
      </div>
    </ReportingPage>
  );
}
