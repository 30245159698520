import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

interface TitleProps {
  value: IntlMessageKeys;
  className?: string;
  translate?: boolean;
  values?: Record<string, React.ReactNode>;
}

export default function Title({
  value,
  className = '',
  translate = true,
  values,
}: TitleProps) {
  return (
    <div
      className={`text-xl md:text-2xl font-extrabold tracking-tight text-neutral-900 dark:text-neutral-100 ${className}`}
    >
      {translate ? <FormattedMessage id={value} values={values} /> : value}
    </div>
  );
}
