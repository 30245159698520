import {
  Menu,
  MenuButton,
  MenuHeading,
  MenuItems,
  MenuSection,
} from '@headlessui/react';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import LocationWrapper from 'mda2-frontend/src/generic/components/LocationWrapper';
import Panel from 'mda2-frontend/src/generic/components/Panel';
import Pill from 'mda2-frontend/src/generic/components/Pill';
import useStore from 'mda2-frontend/src/model/store';
import type { Route } from 'mda2-frontend/src/router/routes';
import format from 'mda2-frontend/src/utils/format';
import { type ReactNode, useMemo, useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi2';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'translations/Intl';

import FilterBubble, {
  CleaningTimesFilter,
  ClimateSensorFilter,
  DateFilter,
  LocationFilter,
  RoomTypesFilter,
} from '../FilterBubble';

function FilterPill({
  allowedLocations,
  menuItem,
  dataTestId,
  children,
}: {
  allowedLocations: Route['path'][];
  menuItem: JSX.Element;
  dataTestId?: string;
  children: ReactNode;
}) {
  const [panelOpen, setPanelOpen] = useState(false);
  return (
    <LocationWrapper allowedLocations={allowedLocations}>
      <>
        {/* Desktop */}
        <Menu as="div" className="relative xl:inline-block text-left hidden">
          <MenuButton>
            <Pill dataTestId={dataTestId}>
              <div className="whitespace-nowrap">{children}</div>
              <div>
                <HiOutlineChevronDown className="size-5" />
              </div>
            </Pill>
          </MenuButton>
          <MenuItems
            modal={false}
            transition
            className="z-40 hidden xl:block absolute max-h-fit left-0 w-max min-w-40 mt-2 origin-top-right bg-white dark:bg-neutral-800 dark:text-white divide-y divide-neutral-100 dark:divide-black rounded-md xl:shadow-lg ring-1 ring-black dark:ring-neutral-700 ring-opacity-5 focus:outline-none text-sm transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <div className="xl:max-w-fit w-full">
              <div
                data-test-id="filter-bar"
                className="flex flex-col relative overscroll-contain py-2 px-4 space-y-2"
              >
                {menuItem}
              </div>
            </div>
          </MenuItems>
        </Menu>
        {/* Mobile */}
        <div className="block xl:hidden">
          <Button onClick={() => setPanelOpen(true)}>
            <Pill
              dataTestId={dataTestId}
              className="bg-primary-400 dark:bg-primary-700 !text-neutral-100 dark:text-neutral-200 dark:hover:text-neutral-300 hover:text-neutral-200"
            >
              <div className="whitespace-nowrap">{children}</div>
              <div>
                <HiOutlineChevronDown className="size-5" />
              </div>
            </Pill>
          </Button>
          <Panel open={panelOpen} setOpen={setPanelOpen}>
            <Panel.Content title={<FormattedMessage id="Filters" />}>
              <div className="flex flex-col relative overscroll-contain space-y-2">
                {menuItem}
              </div>
            </Panel.Content>
          </Panel>
        </div>
      </>
    </LocationWrapper>
  );
}

export const getLocation = (
  intl: ReturnType<typeof useIntl>,
  location: ReturnType<typeof useLocation>,
  buildingName?: string | null,
  floorNumber?: number | null,
  roomName?: string | null,
  labels?: string[] | null,
): string => {
  const labelString =
    (labels?.length ?? 0) > 0 ? ` - ${labels?.join(', ')}` : '';

  if (
    buildingName &&
    floorNumber &&
    roomName &&
    location.pathname.includes('analytics')
  ) {
    return `${intl.formatMessage(
      { id: 'Building Floor Room' },
      {
        number: floorNumber,
        building: buildingName,
        room: roomName,
      },
    )}${labelString}`;
  }

  if (location.pathname.includes('reporting/reports') && buildingName) {
    return `${buildingName}`;
  }

  if (buildingName && typeof floorNumber === 'number') {
    return `${intl.formatMessage(
      { id: 'Building Floor' },
      {
        number: floorNumber,
        building: buildingName,
      },
    )} ${labelString}`;
  }

  if (buildingName && typeof floorNumber !== 'number') {
    return `${buildingName}${labelString}`;
  }

  return `${intl.formatMessage({ id: 'All' })}${labelString}`;
};

export default function FilterBar() {
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const building = useStore((state) => state.userSettings.building)?.Name;
  const floor = useStore((state) => state.userSettings.floor)?.Number;
  const room = useStore((state) => state.userSettings.room)?.Name;
  const labels = useStore((state) => state.userSettings.labels);
  const { check: cleaningCheck, clean: cleaningClean } = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const climateTypes = useStore((state) => state.userSettings.climateTypes);
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const date = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );
  const intl = useIntl();
  const location = useLocation();

  if (
    [
      'findmyasset',
      'admin',
      'management',
      'user',
      'about',
      'status',
      'organization',
      'mqtt',
      'settings',
    ].some(
      (p) =>
        location.pathname.includes(p) && !location.pathname.includes('offline'),
    ) ||
    location.pathname === '/reporting'
  ) {
    return null;
  }

  return (
    <div className="gap-2 px-4 xl:px-0 xl:space-x-2 flex h-12 items-center overflow-x-auto xl:overflow-x-visible">
      <div className="hidden xl:flex items-center space-x-2">
        <FilterBubble />
        <div className="uppercase text-neutral-600 dark:text-neutral-300 text-xs tracking-tight">
          <FormattedMessage id="Filters" />
        </div>
        <span className="h-5 w-px bg-neutral-300" aria-hidden="true" />
      </div>

      <FilterPill
        dataTestId="room-type-filter"
        menuItem={<RoomTypesFilter />}
        allowedLocations={[
          'dashboard',
          'findmyplace',
          'findmyplace/reservations/new',
          'findmyplace/reservations/list',
          'analytics/cleaning',
          'analytics/climate',
          'cleaning',
        ]}
      >
        <MenuSection>
          <MenuHeading>
            <FormattedMessage id="Room Types" />:{' '}
            {roomTypes.map((t) => intl.formatMessage({ id: t })).join(', ')}
          </MenuHeading>
        </MenuSection>
      </FilterPill>
      <FilterPill
        dataTestId="location-filter"
        menuItem={<LocationFilter />}
        allowedLocations={[
          'analytics',
          'analytics/rooms',
          'analytics/cleaning',
          'analytics/climate',
          'findmyplace',
          'myclimate',
          'reporting/reports',
        ]}
      >
        <MenuSection>
          <MenuHeading>
            <FormattedMessage id="Location" />:{' '}
            {getLocation(intl, location, building, floor, room, labels)}
          </MenuHeading>
        </MenuSection>
      </FilterPill>
      <FilterPill
        dataTestId="date-filter"
        menuItem={<DateFilter />}
        allowedLocations={[
          'analytics',
          'analytics/rooms',
          'analytics/cleaning',
          'analytics/climate',
          'reporting/reports',
        ]}
      >
        <MenuSection>
          <MenuHeading>
            <FormattedMessage id="Dates" />:{' '}
            {dateTo
              ? `${format(date, 'PP')} - ${format(dateTo, 'PP')}`
              : format(date, 'PP')}
          </MenuHeading>
        </MenuSection>
      </FilterPill>
      <FilterPill
        dataTestId="climate-filter"
        menuItem={<ClimateSensorFilter />}
        allowedLocations={['myclimate', 'analytics/climate']}
      >
        <MenuSection>
          <MenuHeading>
            <FormattedMessage id="Sensor types" />:{' '}
            {climateTypes
              ?.map((sensor) => intl.formatMessage({ id: sensor }))
              .join(', ')}{' '}
          </MenuHeading>
        </MenuSection>
      </FilterPill>
      <FilterPill
        dataTestId="cleaning-filter"
        menuItem={<CleaningTimesFilter />}
        allowedLocations={['cleaning', 'analytics/cleaning']}
      >
        <MenuSection>
          <MenuHeading>
            <FormattedMessage id="Cleaning Thresholds (min)" />:{' '}
            <FormattedMessage id="check" />: {cleaningCheck},{' '}
            <FormattedMessage id="clean" />: {cleaningClean}
          </MenuHeading>
        </MenuSection>
      </FilterPill>
    </div>
  );
}
