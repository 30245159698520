import Event from 'ol/events/Event';
import type Feature from 'ol/Feature';
import type Geometry from 'ol/geom/Geometry';
import type MapBrowserEvent from 'ol/MapBrowserEvent';

export enum MoveEventType {
  START = 'movestart',
  END = 'moveend',
}

export default class MoveEvent extends Event {
  feature: Feature<Geometry>;

  mapBrowserEvent: MapBrowserEvent<UIEvent>;

  constructor(
    type: MoveEventType,
    feature: Feature<Geometry>,
    mapBrowserPointerEvent: MapBrowserEvent<UIEvent>,
  ) {
    super(type);
    this.feature = feature;
    this.mapBrowserEvent = mapBrowserPointerEvent;
  }
}
