import type { JsonGeometry } from 'common/jsonGeometry';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import getColor from 'utils/getColor';
import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

export type CleaningBeaconFeature = {
  Name: string;
  Geometry: JsonGeometry;
  Radius: number;
  Success: boolean;
};

export type CleaningBeaconFeatureType = TypedFeature<
  CleaningBeaconFeature,
  Geometry
>;

const styleFunction = (
  feature: CleaningBeaconFeatureType,
  hoveredFeature: boolean,
  resolution: number,
) => [
  new Style({
    image: new Circle({
      radius: feature.getProperties().Radius / resolution,
      fill: new Fill({
        color: feature.getProperties().Success
          ? getColor('GREEN', hoveredFeature ? '.9' : '.75')
          : getColor('RED', hoveredFeature ? '.9' : '.75'),
      }),
      stroke: new Stroke({
        color: feature.getProperties().Success
          ? getColor('GREEN', hoveredFeature ? '.9' : '.8')
          : getColor('RED', hoveredFeature ? '.9' : '.8'),
        width: 2,
      }),
    }),
  }),
];

interface CleaningLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Polygon>>>;
}

class CleaningBeaconLayer extends VectorLayer<Polygon> {
  features?: CleaningBeaconFeature[];

  hoveredFeature?: CleaningBeaconFeatureType;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature, resolution) =>
            styleFunction(
              feature as CleaningBeaconFeatureType,
              feature.getGeometry() === this.hoveredFeature?.getGeometry(),
              resolution,
            ),
        }),
      },
      name: 'cleaningBeaconLayer',
    } as CleaningLayerOptions);
  }

  setFeatures(feats: CleaningBeaconFeature[]): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      CleaningBeaconLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  static toGeoJson(feats?: CleaningBeaconFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry: geom } = feat;
      return {
        type: 'Feature',
        geometry: geom,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default CleaningBeaconLayer;
