import useStore from 'model/store';

import { format } from 'date-fns';
import { format as formatUtc, toZonedTime } from 'date-fns-tz';
import { LOCALES } from 'mda2-frontend/src/constants';

const formatDate = (date: Date, formatStr = 'PP', utc = false) => {
  // From https://date-fns.org/v2.21.3/docs/I18n
  const localeId = useStore.getState().userSettings.language;

  return utc
    ? formatUtc(toZonedTime(date, 'UTC'), formatStr, {
        locale: LOCALES[localeId === 'en' ? 'enGB' : 'de'], // avoid US date format
      })
    : format(date, formatStr, {
        locale: LOCALES[localeId === 'en' ? 'enGB' : 'de'], // avoid US date format
      });
};

export default formatDate;
