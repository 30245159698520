import StyledButton from 'generic/components/Form/Button/StyledButton';
import Input from 'generic/components/Form/Input/Input';
import Select from 'generic/components/Form/Select';
import Switch from 'generic/components/Form/Switch';
import TextArea from 'generic/components/Form/TextArea';
import Transition from 'generic/components/Transition';
import { MqttSystems, RpcTypes, useSendRpcMutation } from 'graphql/types';
import { Action } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import { useMemo, useState } from 'react';
import {
  HiOutlineCircleStack,
  HiOutlineCodeBracket,
  HiOutlineDocumentChartBar,
  HiOutlineHomeModern,
  HiOutlineTag,
} from 'react-icons/hi2';
import {
  FormattedMessage,
  useIntl,
  type IntlMessageKeys,
} from 'translations/Intl';
import parseBluerangeTopic from 'utils/parseBluerangeTopic';
import parseMda3Topic from 'utils/parseMda3Topic';

export type RPCBeacons = {
  mqttTopic: string;
  mqttSystem: MqttSystems;
};

interface SendRPCModalProps {
  setBeaconsToSendRPC: (beaconsToSendRPC?: RPCBeacons[]) => void;
  beaconsToSendRPC?: RPCBeacons[];
}

const checkValue = (value?: string) => {
  if (value) {
    const isHex = value.startsWith('0x');

    if (isHex) {
      const number = Number.parseInt(value, 16);

      if (Number.isNaN(number)) {
        return undefined;
      }

      return number;
    }

    const number = Number.parseInt(value, 10);

    if (Number.isNaN(number)) {
      return undefined;
    }

    return number;
  }
  return undefined;
};

function HexValue({ value }: { value: string | undefined }) {
  return (
    <Transition show={!!value?.startsWith('0x') && value.length > 2}>
      <p className="text-xs">
        <FormattedMessage id="Value" />: {checkValue(value)}
      </p>
    </Transition>
  );
}

export default function SendRPCModal({
  beaconsToSendRPC,
  setBeaconsToSendRPC,
}: SendRPCModalProps): JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [responseData, setResponseData] =
    useState<({ beacon: string; data: string | undefined } | undefined)[]>();
  const [responseError, setResponseError] = useState<string | undefined>();
  const [write, setWrite] = useState(false);
  const [selectedType, setSelectedType] = useState(RpcTypes.String);
  const [deviceAddress, setDeviceAddress] = useState<number>(0);
  const [holdingRegister, setHoldingRegister] = useState<string | undefined>();
  const [inputRegister, setInputRegister] = useState<string | undefined>();
  const [count, setCount] = useState<number | undefined>();
  const [values, setValues] = useState<string | undefined>();

  const [{ fetching }, sendRpc] = useSendRpcMutation();

  const resetValues = () => setBeaconsToSendRPC(undefined);

  const calculatedInputRegister = useMemo(
    () => checkValue(inputRegister),
    [inputRegister],
  );
  const calculatedHoldingRegister = useMemo(
    () => checkValue(holdingRegister),
    [holdingRegister],
  );

  return (
    <Modal
      title={intl.formatMessage({
        id: 'RPC',
      })}
      action={Action.UPDATE}
      open={!!beaconsToSendRPC}
      setShowModal={resetValues}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex space-y-2 justify-between items-center">
          <div className="flex flex-col">
            <div className="flex space-x-2">
              <Switch isEnabled={write} onSetEnable={() => setWrite(!write)} />
              <div>
                <FormattedMessage id="Write enabled" />
              </div>
            </div>
            <div>
              <FormattedMessage
                id="Mqtt system elements"
                values={{
                  mqttSystem: MqttSystems.Bluerange,
                  count:
                    beaconsToSendRPC?.filter(
                      (b) => b.mqttSystem === MqttSystems.Bluerange,
                    ).length ?? 0,
                }}
              />
              {' / '}
              <FormattedMessage
                id="Mqtt system elements"
                values={{
                  mqttSystem: MqttSystems.Mda3,
                  count:
                    beaconsToSendRPC?.filter(
                      (b) => b.mqttSystem === MqttSystems.Mda3,
                    ).length ?? 0,
                }}
              />
            </div>
          </div>

          <Select
            label="Return type"
            value={selectedType}
            isDeselectable={false}
            onChangeSelected={(type) =>
              type && setSelectedType(type as RpcTypes)
            }
            options={Object.values(RpcTypes).map((d) => d.toString())}
            renderValue={(t) =>
              `${intl.formatMessage({ id: t as IntlMessageKeys })}`
            }
          />
        </div>
        <Input
          type="number"
          min={0}
          placeholder={intl.formatMessage({ id: 'Device address' })}
          label={intl.formatMessage({ id: 'Device address' })}
          value={deviceAddress}
          icon={
            <HiOutlineHomeModern className="size-5 dark:text-neutral-200" />
          }
          onChangeValue={(val) => setDeviceAddress(Number.parseInt(val, 10))}
        />
        <div className="space-y-0.5">
          <Input
            type="text"
            placeholder={intl.formatMessage({ id: 'Holding register' })}
            label={intl.formatMessage({ id: 'Holding register' })}
            value={holdingRegister}
            icon={
              <HiOutlineCircleStack className="size-5 dark:text-neutral-200" />
            }
            onChangeValue={setHoldingRegister}
          />
          <HexValue value={holdingRegister} />
        </div>
        <div className="space-y-0.5">
          <Input
            type="text"
            placeholder={intl.formatMessage({ id: 'Input register' })}
            label={intl.formatMessage({ id: 'Input register' })}
            value={inputRegister}
            icon={<HiOutlineTag className="size-5 dark:text-neutral-200" />}
            onChangeValue={setInputRegister}
          />
          <HexValue value={inputRegister} />
        </div>
        <Input
          type="number"
          placeholder={intl.formatMessage({ id: 'Count' })}
          label={intl.formatMessage({ id: 'Count' })}
          value={count}
          icon={
            <HiOutlineCodeBracket className="size-5 dark:text-neutral-200" />
          }
          onChangeValue={(c) => setCount(Number.parseInt(c, 10))}
        />
        <Input
          type="text"
          placeholder={intl.formatMessage({ id: 'Values' })}
          label={intl.formatMessage({ id: 'Values' })}
          value={values}
          icon={
            <HiOutlineDocumentChartBar className="size-5 dark:text-neutral-200" />
          }
          onChangeValue={setValues}
        />
        <StyledButton
          isLoading={fetching}
          disabled={
            !deviceAddress ||
            (!count &&
              typeof calculatedHoldingRegister === 'number' &&
              !write) ||
            (typeof calculatedHoldingRegister === 'number' &&
              typeof calculatedInputRegister === 'number') ||
            (typeof calculatedInputRegister === 'number' && write) ||
            (count && write) ||
            (!!values && !write) ||
            (!write &&
              typeof calculatedHoldingRegister !== 'number' &&
              typeof calculatedInputRegister !== 'number')
          }
          onClick={() => {
            setResponseError(undefined);
            setResponseData(undefined);
            sendRpc(
              {
                Input: (beaconsToSendRPC ?? []).map((b) => ({
                  mqttSystem: b.mqttSystem,
                  topic:
                    b.mqttSystem === MqttSystems.Bluerange
                      ? parseBluerangeTopic(b.mqttTopic)
                      : `cmd/${parseMda3Topic(b.mqttTopic)}/modbus_rpc`,
                  write,
                  type: selectedType,
                  deviceAddress,
                  holdingRegister: calculatedHoldingRegister,
                  inputRegister: calculatedInputRegister,
                  count,
                  values,
                })),
              },
              hasuraHeader(HasuraPermissions.READ_ALL),
            ).then((data) => {
              if (data.error) {
                setResponseError(data.error.message);
              } else {
                setResponseData(
                  data.data?.SendRPC?.successResponses.map((s) => ({
                    beacon:
                      s.topic
                        .split('/')
                        .find((m) =>
                          /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/.test(m),
                        ) ?? '',
                    data: s.message ?? undefined,
                  })),
                );
              }
            });
          }}
        >
          <FormattedMessage id="Send" />
        </StyledButton>
        {responseData && (
          <TextArea text={JSON.stringify(responseData, null, 2)} />
        )}
        {responseError && <TextArea text={responseError} />}
      </div>
    </Modal>
  );
}
