import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup } from '@visx/shape';
import type { MarginProps } from 'common/types';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Axis from 'generic/components/Chart/Axis';
import Legend from 'generic/components/Chart/Legend';
import { RAINBOW } from 'mda2-frontend/src/constants';
import { useIntl } from 'translations/Intl';
import getColor from 'utils/getColor';

export enum RoomTypes {
  FIX = 'fix',
  FLEX = 'flex',
}

export const AVERAGE_FLOOR = 9999;

export interface Data {
  [key: number]: number; // Floor number
  roomType: RoomTypes;
}

interface ResponsiveBarChartProps {
  margin?: MarginProps;
  data: Data[];
  colors?: string[];
}

interface BarChartProps extends ResponsiveBarChartProps {
  height: number;
  width: number;
}

function BarChart({
  height,
  width,
  margin = {
    top: 80,
    left: 70,
    right: 0,
    bottom: 50,
  },
  data,
  colors,
}: BarChartProps) {
  const intl = useIntl();
  const keys = Object.keys(data[0] ?? {}).filter((d) => d !== 'roomType');
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const roomTypeScale = scaleBand<RoomTypes>({
    domain: [RoomTypes.FIX, RoomTypes.FLEX],
    padding: 0.2,
    range: [0, xMax],
  });
  const floorScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
    range: [0, roomTypeScale.bandwidth()],
  });
  const occupancyScale = scaleLinear<number>({
    domain: [0, 100],
    range: [yMax, 0],
  });
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: RAINBOW || colors,
  });

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={occupancyScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <BarGroup
            data={data}
            keys={keys}
            height={yMax}
            x0={(x) => x.roomType}
            x0Scale={roomTypeScale}
            x1Scale={floorScale}
            yScale={occupancyScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar) => (
                    <AnimatedRect
                      bar={bar}
                      key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                    />
                  ))}
                </Group>
              ))
            }
          </BarGroup>
          <Axis
            lowLevelChart
            orientation="left"
            scale={occupancyScale}
            tickFormat={(y) => `${y}%`}
            label={intl.formatMessage({ id: 'Occupancy' })}
          />
          <Axis
            lowLevelChart
            orientation="bottom"
            top={yMax}
            scale={roomTypeScale}
            tickFormat={(x) => intl.formatMessage({ id: x })}
            label={intl.formatMessage({ id: 'Room type' })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex">
          <Legend
            scaleType="ordinal"
            labelFormat={(d) =>
              Number.parseInt(d) === AVERAGE_FLOOR
                ? intl.formatMessage({ id: 'Average' })
                : intl.formatMessage(
                    {
                      id: '{number} Floor',
                    },
                    { number: d },
                  )
            }
            scale={colorScale}
          />
        </div>
      </div>
    </div>
  );
}

export default function ResponsiveBarChart(props: ResponsiveBarChartProps) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
