import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Polygon } from '@visx/shape';
import type { MarginProps } from 'common/types';
import { motion } from 'framer-motion';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Axis from 'generic/components/Chart/Axis';
import { RAINBOW } from 'mda2-frontend/src/constants';
import { cloneElement } from 'react';
import { useIntl } from 'translations/Intl';
import getColor, { primaryColorToRGB } from 'utils/getColor';

interface ResponsiveBarChartProps<T> {
  margin?: MarginProps;
  data: T[];
  colors?: string[];
  yScaleAccessor: (d: T) => number;
  groupAccessor: (d: T) => string;
  y1Accessor: (d: T) => number;
  y2Accessor: (d: T) => number;
  xAxisLabel?: string;
}

interface BarChartProps<T> extends ResponsiveBarChartProps<T> {
  height: number;
  width: number;
}

function BarChart<T>({
  height,
  width,
  margin = {
    top: 40,
    left: 70,
    right: 0,
    bottom: 50,
  },
  data,
  colors,
  yScaleAccessor,
  groupAccessor,
  y1Accessor,
  y2Accessor,
  xAxisLabel,
}: BarChartProps<T>) {
  const intl = useIntl();
  const groups = data.map(groupAccessor);
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const colorScale = scaleOrdinal({
    domain: groups,
    range: colors ?? RAINBOW,
  });

  const xScale = scaleBand<string>({
    range: [0, xMax],
    domain: groups,
    paddingInner: 0.2,
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const variants = {
    initial: {
      fill: '#fff',
      pathLength: 0,
    },
    animate: {
      transition: {
        ease: 'easeInOut',
        duration: 1,
      },
      pathLength: 1,
    },
  };

  const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
    domain: [
      intl.formatMessage({ id: 'Max hourly' }),
      intl.formatMessage({ id: 'Max daily' }),
    ],
    range: [
      <Polygon
        key={intl.formatMessage({ id: 'Max hourly' })}
        sides={6}
        center={{ x: 6, y: 7 }}
        rotate={90}
        size={6}
        fill={primaryColorToRGB(500, 1)}
      />,
      <Polygon
        key={intl.formatMessage({ id: 'Max daily' })}
        sides={4}
        center={{ x: 6, y: 7 }}
        rotate={90}
        size={6}
        fill={primaryColorToRGB(500, 1)}
      />,
    ],
  });

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          {data.map((d) => {
            const group = groupAccessor(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(yScaleAccessor(d)) ?? 0);
            const barX = xScale(group) ?? 0;
            const barY = yMax - barHeight;
            return (
              <g key={`bar-${group}`}>
                <AnimatedRect
                  bar={{
                    width: barWidth,
                    height: barHeight,
                    y: barY,
                    x: barX,
                    color: colorScale(group),
                  }}
                />
                <Polygon
                  sides={4}
                  center={{ x: barX + barWidth / 2, y: yScale(y1Accessor(d)) }}
                  rotate={90}
                  size={6}
                >
                  {({ points }) => (
                    <motion.polygon
                      initial="initial"
                      animate="animate"
                      variants={{
                        initial: variants.initial,
                        animate: {
                          fill: colorScale(group),
                          ...variants.animate,
                        },
                      }}
                      points={`${points}`}
                      fillOpacity={0.8}
                    />
                  )}
                </Polygon>
                <Polygon
                  sides={6}
                  center={{ x: barX + barWidth / 2, y: yScale(y2Accessor(d)) }}
                  rotate={90}
                  size={6}
                >
                  {({ points }) => (
                    <motion.polygon
                      initial="initial"
                      animate="animate"
                      variants={{
                        initial: variants.initial,
                        animate: {
                          fill: colorScale(group),
                          ...variants.animate,
                        },
                      }}
                      points={`${points}`}
                      fillOpacity={0.8}
                    />
                  )}
                </Polygon>
              </g>
            );
          })}
          <Axis
            lowLevelChart
            orientation="left"
            scale={yScale}
            tickFormat={(y) => `${y}%`}
            label={intl.formatMessage({ id: 'Occupancy' })}
          />
          {xAxisLabel && (
            <Axis
              lowLevelChart
              orientation="bottom"
              top={yMax}
              scale={xScale}
              label={xAxisLabel}
            />
          )}
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-0 w-full space-y-2">
        <LegendOrdinal
          direction="row"
          labelMargin="0 15px 0 0"
          scale={shapeScale}
        >
          {(groups) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {groups.map((label, i) => {
                const shape = shapeScale(label.datum);
                return (
                  <LegendItem margin="0 5px" key={`legend-quantile-${i}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16">
                      {cloneElement(shape as React.ReactElement)}
                    </svg>
                    <LegendLabel align="left" className="text-xs">
                      {label.text}
                    </LegendLabel>
                  </LegendItem>
                );
              })}
            </div>
          )}
        </LegendOrdinal>
      </div>
    </div>
  );
}

export default function ResponsiveBarChart<T>(
  props: ResponsiveBarChartProps<T>,
) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
