/* Author: Dalibor Kundrat  https://github.com/damikun */
import useToastStore from 'model/toast/store';

import { Portal } from '@headlessui/react';
import ToastMessage from './ToastMessage';

const VARIANTS = {
  top_left: 'top-0 left-0',
  top_right: 'top-0 right-0',
  bottom_right: 'bottom-0 right-0',
  bottom_left: 'bottom-0 left-0',
  top_middle: 'top-0 left-1/2 -translate-x-1/2',
  bottom_middle: 'bottom-0 left-1/2 -translate-x-1/2',
};

export default function ToastContainer(): JSX.Element {
  const toasts = useToastStore((state) => state.toasts);
  const removeToast = useToastStore((state) => state.removeToast);
  const style = VARIANTS.top_right;

  const handleRemove = (id: string) => removeToast(id);

  return (
    <Portal>
      <div
        className={`${style} fixed z-50 w-full md:max-w-sm p-4 max-h-screen overflow-hidden pointer-events-none print:hidden`}
      >
        <div className="flex-1 flex-col space-y-2 fade w-full mr-8 justify-end pointer-events-none">
          {toasts.map(({ id, message, type, lifetime }) => (
            <div
              key={id}
              className="flex w-full transition-all duration-300 pointer-events-auto rounded-md border border-neutral-200 dark:border-neutral-700"
            >
              <ToastMessage
                id={id}
                message={message}
                type={type}
                onRemove={handleRemove}
                lifetime={lifetime}
              />
            </div>
          ))}
        </div>
      </div>
    </Portal>
  );
}
