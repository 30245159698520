import type { Route } from 'mda2-frontend/src/router/routes';
import { useLocation } from 'react-router-dom';

import Transition from '../Transition';

interface LocationWrapperProps {
  allowedLocations: Route['path'][];
  children: JSX.Element;
}

export default function LocationWrapper({
  allowedLocations,
  children,
}: LocationWrapperProps) {
  const location = useLocation();

  return (
    <Transition
      // Use subsctring as Route['path'] starts without a '/'
      show={allowedLocations.includes(
        location.pathname.substring(1) as Route['path'],
      )}
    >
      {children}
    </Transition>
  );
}
