import type { OccupancyMapQuery } from 'mda2-frontend/src/graphql/types';
import { primaryColorToRGB } from 'mda2-frontend/src/utils/getColor';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type OLGeometry from 'ol/geom/Geometry';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

type AccuracyQueryType = NonNullable<
  OccupancyMapQuery['MqttAssetTrackers']
>[number];
interface AccuracyType extends AccuracyQueryType {
  isAccuracyFeature: boolean;
}

export type AccuracyFeatureType = TypedFeature<AccuracyType, OLGeometry>;

const styleFunction = (
  feature: AccuracyFeatureType,
  hoveredFeature: AccuracyFeatureType | undefined,
) => {
  const isHovered = feature.getGeometry() === hoveredFeature?.getGeometry();
  if (feature.getProperties().IsMoving) {
    return new Style();
  }
  return feature.getProperties().IsOffline
    ? [
        new Style({
          fill: new Fill({
            color: primaryColorToRGB(300, isHovered ? 0.6 : 0.3),
          }),
        }),
        isHovered
          ? new Style({
              stroke: new Stroke({
                color: primaryColorToRGB(300, 1),
                lineDash: [5, 5],
                width: 3,
              }),
            })
          : new Style(),
      ]
    : new Style();
};

const createCirclePointCoords = (
  circleCenterX: number,
  circleCenterY: number,
  circleRadius: number,
  pointsToFind: number,
) => {
  const angleToAdd = 360 / pointsToFind;
  const coords = [];
  let angle = 0;
  for (let i = 0; i < pointsToFind; i += 1) {
    angle += angleToAdd;
    const coordX =
      circleCenterX + circleRadius * Math.cos((angle * Math.PI) / 180);
    const coordY =
      circleCenterY + circleRadius * Math.sin((angle * Math.PI) / 180);
    coords.push([coordX, coordY]);
  }

  return [coords];
};

interface AccuracyLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Polygon>>>;
}

class AccuracyLayer extends VectorLayer<Polygon> {
  features?: OccupancyMapQuery['MqttAssetTrackers'];

  hoveredFeature?: AccuracyFeatureType;

  height?: number;

  width?: number;

  init(map: OLMap): void {
    super.init(map);
    this.height = undefined;
    this.width = undefined;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature) =>
            styleFunction(feature as AccuracyFeatureType, this.hoveredFeature),
        }),
      },
      name: 'assetAccuracyLayer',
    } as AccuracyLayerOptions);

    this.hoveredFeature = undefined;
    this.height = undefined;
    this.width = undefined;
  }

  setFeatures(feats: OccupancyMapQuery['MqttAssetTrackers']): void {
    if (feats) {
      this.features = feats.filter(
        (f) => f.XCoordinate !== 0 && f.YCoordinate !== 0,
      );
    }
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      this.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  setBaseImageSize(width: number, height: number): void {
    this.width = width;
    this.height = height;
  }

  toGeoJson(feats?: OccupancyMapQuery['MqttAssetTrackers']): GeoJSONFeature {
    const features =
      this.height !== undefined && this.width !== undefined
        ? feats?.map((feat) => ({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: createCirclePointCoords(
                (this.width as number) * feat.XCoordinate,
                (this.height as number) -
                  (this.height as number) * feat.YCoordinate,
                feat.Accuracy,
                64,
              ),
            },
            properties: {
              ...feat,
              isAccuracyFeature: true,
            },
          }))
        : [];
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default AccuracyLayer;
