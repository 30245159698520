import { forwardRef } from 'react';

interface PillProps {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  dataTestId?: string;
  className?: string;
  children: React.ReactNode;
}

const Pill = forwardRef<HTMLDivElement, PillProps>((props, ref) => {
  const { visible, setVisible, dataTestId, className, children, ...rest } =
    props;

  const getClass = () => {
    if (visible === undefined) {
      return 'bg-primary-200 text-primary-500';
    }

    if (visible) {
      return 'bg-primary-200 hover:line-through text-primary-500';
    }

    // Not visible
    return 'line-through bg-neutral-300 hover:no-underline text-white';
  };
  return setVisible ? (
    <div
      ref={ref}
      role="button"
      data-test-id={dataTestId}
      onClick={() => setVisible(!visible)}
      onKeyDown={() => setVisible(!visible)}
      tabIndex={0}
      className={`${getClass()} transition-all flex items-center text-xxs rounded-full py-1 px-2 ${className}`}
      {...rest}
    >
      {children}
    </div>
  ) : (
    <div
      ref={ref}
      data-test-id={dataTestId}
      className={`${getClass()} transition-all flex items-center text-xxs rounded-full py-1 px-2 ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
});

export default Pill;
