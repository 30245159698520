import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from 'mda2-frontend/src/common/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import Subtitle from 'mda2-frontend/src/generic/components/Subtitle';
import {
  type 
  KeycloakGroupsQuery,
  useDeleteKeycloakGroupMutation,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

interface RemoveGroupModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupToRemove?: KeycloakGroup;
  setGroupToRemove: (groupToRemove?: KeycloakGroup) => void;
}

export type KeycloakGroup = KeycloakGroupsQuery['GetSubGroups'][number];

export default function RemoveGroupModal({
  open,
  setOpen,
  groupToRemove,
  setGroupToRemove,
}: RemoveGroupModalProps): JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [confirmDelete, setConfirmDelete] = useState('');
  const [{ fetching }, deleteGroup] = useDeleteKeycloakGroupMutation();

  const resetValues = () => {
    setConfirmDelete('');
    setGroupToRemove(undefined);
    setOpen(false);
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={open}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={confirmDelete !== groupToRemove?.name}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.REMOVE}
          onProceed={() => {
            if (confirmDelete === groupToRemove?.name) {
              deleteGroup(
                {
                  GroupId: groupToRemove.id,
                },
                hasuraHeader(HasuraPermissions.VIEW_USERGROUP, ['Group']),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Deleted group' },
                      { name: groupToRemove.name },
                    ),
                  },
                });
                resetValues();
              });
            }
          }}
          onCancel={resetValues}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({ id: 'Are you sure to remove this group?' })}
        className="text-base pb-2"
      />
      <p className="pb-2">
        <FormattedMessage
          id="Enter the group {name} in order to delete it"
          values={{
            name: <b>{groupToRemove?.name}</b>,
          }}
        />
      </p>

      <Input
        type="text"
        value={confirmDelete}
        id="Remove group"
        icon={<HiOutlineTrash className="size-5 dark:text-neutral-200" />}
        onChangeValue={(input: string) => setConfirmDelete(input)}
        placeholder={groupToRemove?.name ?? ''}
        required
        data-test-id="confirm-delete-group"
      />
    </Modal>
  );
}
