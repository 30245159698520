import { FormattedMessage } from 'translations/Intl';

import { formattedDistance } from 'mda2-frontend/src/utils/date';
import { useEffect, useState } from 'react';

interface LastUpdatedProps {
  lastUpdate: Date;
  small?: boolean;
}

export default function LastUpdated({
  lastUpdate,
  small = false,
}: LastUpdatedProps): JSX.Element {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className={`text-neutral-700 ${
        small ? 'text-xxs' : 'text-xs'
      } dark:text-neutral-200`}
    >
      <FormattedMessage id="Last updated" />{' '}
      {formattedDistance(lastUpdate, { includeSeconds: true }, now)}
    </div>
  );
}
