import { Tooltip } from '@visx/tooltip';
import { DeviceTypes, LightpadErrors } from 'common/types';
import LuminaireIcon from 'generic/components/LuminaireIcon';
import Map from 'generic/components/Map';
import Popup from 'generic/components/Popup';
import PopupBody from 'generic/components/layout/PopupBody';
import BaseLayer from 'generic/layers/BaseLayer';
import GeometryLayer from 'generic/layers/GeometryLayer';
import type TypedFeature from 'generic/layers/TypedFeature';
import type { DefectiveBeaconsQuery } from 'graphql/types';
import useStore from 'model/store';
import OLMap from 'ol/Map';
import type { Coordinate } from 'ol/coordinate';
import type { Point } from 'ol/geom';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { useEffect, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import translations from 'translations';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import getColor from 'utils/getColor';

export type DefectiveBeacons =
  DefectiveBeaconsQuery['Buildings'][number]['Floors'][number]['MqttBeacons'][number] & {
    Index: number;
  };

type DefectiveBeaconsFeatureType = TypedFeature<DefectiveBeacons, Point>;

interface ReportingMapProps {
  image: string;
  beacons: DefectiveBeacons[];
}

export const getStatus = (statusCode: number, deviceType: DeviceTypes) => {
  const cache = createIntlCache();

  const translation = createIntl(
    {
      locale: useStore.getState().userSettings.language,
      messages: translations[useStore.getState().userSettings.language],
    },
    cache,
  );

  if (
    [
      DeviceTypes.LIGHTPAD,
      DeviceTypes.LEVEL,
      DeviceTypes.TWEAK,
      DeviceTypes.SOLO_SLIM,
      // TODO: Remove when stable, ask Wolfgang
      undefined,
    ].includes(deviceType)
  ) {
    return LightpadErrors[statusCode]
      ? translation.formatMessage({
          id: LightpadErrors[statusCode] as IntlMessageKeys,
        })
      : '-';
  }

  // TODO: Now assuming 0 is working for all devices
  if (statusCode === 0) {
    return translation.formatMessage({
      id: LightpadErrors[statusCode] as IntlMessageKeys,
    });
  }

  return statusCode.toString();
};

export default function ReportingMap({ image, beacons }: ReportingMapProps) {
  const [map] = useState(new OLMap());
  const [baseLayer] = useState(new BaseLayer());
  const [beaconLayer] = useState(
    new GeometryLayer<DefectiveBeacons>({
      style: (feat, _, hoveredFeature) => [
        new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: getColor(
                // Only offline and non functional devices are requested -> if it is functional it must be offline
                feat.getProperties().StatusCode !== 0 ? 'RED' : 'YELLOW',
                feat.getGeometry() === hoveredFeature?.getGeometry()
                  ? '.4'
                  : '.9',
              ),
            }),
            stroke: new Stroke({
              color: getColor(
                feat.getProperties().StatusCode !== 0 ? 'RED' : 'YELLOW',
                feat.getGeometry() === hoveredFeature?.getGeometry()
                  ? '.4'
                  : '1',
              ),
              width:
                feat.getGeometry() === hoveredFeature?.getGeometry() ? 3 : 1,
            }),
          }),
          text: new Text({
            text: feat.getProperties().Index.toString(),
            font: 'bold 12px "Inter Variable", sans-serif',
            fill: new Fill({ color: 'white' }),
          }),
        }),
      ],
    }),
  );
  const [layers] = useState([baseLayer, beaconLayer]);
  const [hoveredFeature, setHoveredFeature] =
    useState<DefectiveBeaconsFeatureType | null>(null);
  const [hoveredCoords, setHoveredCoords] = useState<Coordinate | null>(null);

  useEffect(() => baseLayer.setImage(image), [baseLayer, image]);
  useEffect(() => beaconLayer.setFeatures(beacons), [beaconLayer, beacons]);

  return (
    <>
      <Map<DefectiveBeaconsFeatureType>
        enableZoomButton={false}
        map={map}
        className="h-96"
        layers={layers}
        onFeaturesHover={(hoveredFeatures, evt) => {
          const [feat] = hoveredFeatures.map((hF) => hF.feature);

          if (feat?.getProperties().Name) {
            setHoveredCoords(evt.coordinate);
            beaconLayer.hoveredFeature = feat;
            beaconLayer.olLayer.changed();
            setHoveredFeature(feat);
          } else {
            setHoveredFeature(null);
            beaconLayer.hoveredFeature = undefined;
            beaconLayer.olLayer.changed();
          }
        }}
      />
      {hoveredFeature && (
        <Popup
          map={map}
          popupCoordinate={hoveredCoords}
          renderTooltip={(props) => (
            <Tooltip {...props} data-test-id="reporting-map-tooltip">
              <PopupBody
                title={hoveredFeature.getProperties().Name}
                icon={
                  <div className="dark:bg-neutral-600 rounded-full p-1">
                    <LuminaireIcon
                      device={{
                        deviceType:
                          (hoveredFeature.getProperties().DeviceType?.Name as
                            | DeviceTypes
                            | undefined) ?? DeviceTypes.NODE,
                        desksInUse:
                          hoveredFeature.getProperties()
                            .NumberOfAvailableDesks ?? 0,
                      }}
                    />
                  </div>
                }
              >
                <div className="flex space-x-1">
                  <div>
                    <FormattedMessage id="Status" />:
                  </div>
                  <div>
                    {hoveredFeature.getProperties().IsOffline ? (
                      <FormattedMessage id="Offline" />
                    ) : (
                      getStatus(
                        hoveredFeature.getProperties().StatusCode,
                        hoveredFeature.getProperties().DeviceType
                          ?.Name as DeviceTypes,
                      )
                    )}
                  </div>
                </div>
              </PopupBody>
            </Tooltip>
          )}
        />
      )}
    </>
  );
}
