import { useCallback, useEffect } from 'react';
import type { UseQueryExecute } from 'urql';

export default function usePolling(
  fetching: boolean,
  reexecuteQuery: UseQueryExecute,
  interval = 10000,
  setLastUpdate?: (date: Date) => void,
) {
  let timerId: NodeJS.Timeout | undefined = undefined;

  const executeQuery = useCallback(() => {
    if (!fetching) {
      reexecuteQuery({ requestPolicy: 'cache-and-network' });
      setLastUpdate?.(new Date());
    }
  }, [fetching, reexecuteQuery, setLastUpdate]);

  useEffect(() => {
    // Reexecute query on page focus
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearTimeout(timerId);
      } else {
        executeQuery();
      }
    };

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false,
    );

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [executeQuery, timerId]);

  useEffect(() => {
    timerId = setTimeout(() => executeQuery(), interval);

    return () => clearTimeout(timerId);
  }, [interval, executeQuery, timerId]);
}
