import type { AxisScale } from '@visx/axis';
import { curveMonotoneX, curveStep } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { AreaStack, LinePath } from '@visx/shape';
import type { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import { Themes } from 'common/types';
import { motion, useMotionValue } from 'framer-motion';
import Axis from 'mda2-frontend/src/generic/components/Chart/Axis';
import type {
  HistoryOccupancyDailyAggregatedQuery,
  MeetingRoomsHistoryOccupancyDailyQuery,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import localize from 'mda2-frontend/src/utils/format';
import getColor from 'mda2-frontend/src/utils/getColor';
import { useIntl } from 'translations/Intl';

export type ChartData =
  HistoryOccupancyDailyAggregatedQuery['f_history_desks_occupancy_daily'][number];

export type MeetingChartData =
  MeetingRoomsHistoryOccupancyDailyQuery['f_history_rooms_occupancy_daily'][number];

// Accessors
const getDate = (d: ChartData | MeetingChartData) => d.Date.valueOf();
const getMaxUsage = (d: ChartData) => d.PercentageMaxOccupancy;

interface AreaChartProps {
  data: (ChartData | MeetingChartData)[];
  xScale: AxisScale<number>;
  yScale: AxisScale<number>;
  width: number;
  height: number;
  yMax: number;
  margin: { top: number; right: number; bottom: number; left: number };
  hideBottomAxis?: boolean;
  hideLeftAxis?: boolean;
  top?: number;
  left?: number;
  children?: React.ReactNode;
  meetingRoomOccupancy?: boolean;
}

export function AnimatedPath<T>({
  path,
  data,
  stroke,
}: {
  path: Parameters<
    Exclude<LinePathProps<T>['children'], undefined>
  >[number]['path'];
  data: T[];
  stroke: SVGPathElement['style']['stroke'];
}) {
  const pathLength = useMotionValue(0);

  return (
    <motion.path
      strokeWidth={2}
      strokeOpacity={0.8}
      strokeLinecap="round"
      fill="none"
      stroke={stroke}
      initial="initial"
      animate="animate"
      variants={{
        initial: {
          pathLength: 0,
        },
        animate: {
          pathLength: 1,
          d: path(data) ?? undefined,
          transition: {
            ease: 'easeInOut',
            duration: 1,
          },
        },
      }}
      style={{
        strokeDashoffset: pathLength,
        strokeDasharray: pathLength,
      }}
    />
  );
}

export default function AreaChart({
  data,
  width,
  height,
  yMax,
  margin,
  xScale,
  yScale,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children,
  meetingRoomOccupancy,
}: AreaChartProps): JSX.Element {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);

  const keys = meetingRoomOccupancy
    ? ['PercentageUsedMeetingRooms']
    : ['PercentageHotMinutes', 'PercentageWarmMinutes'];

  return (
    <>
      <LinearGradient
        id="area-red-gradient"
        from={getColor('RED')}
        to={getColor('RED')}
        toOpacity={0.8}
      />
      <LinearGradient
        id="area-yellow-gradient"
        from={getColor('YELLOW')}
        to={getColor('YELLOW')}
        toOpacity={0.8}
      />
      <Group left={left || margin.left} top={top || margin.top}>
        <GridRows
          scale={yScale}
          width={width - margin.right - margin.left}
          numTicks={4}
          height={height}
          strokeDasharray="1,3"
          stroke={getColor('NEUTRAL600')}
          strokeOpacity={0.6}
        />
        <AreaStack
          data={data}
          keys={keys}
          x={(d) => xScale(getDate(d.data)) ?? 0}
          y0={(d) => yScale(d[0]) ?? 0}
          y1={(d) => yScale(d[1]) ?? 0}
          curve={curveStep}
        >
          {({ stacks, path }) =>
            stacks.map((stack) => (
              <motion.path
                animate={{ d: path(stack) ?? undefined }}
                key={`stack-${stack.key}`}
                // stroke={colorScale(stack.key)}
                // strokeWidth={2}
                fill={
                  stack.key === 'PercentageWarmMinutes'
                    ? 'url(#area-yellow-gradient)'
                    : 'url(#area-red-gradient)'
                }
                fillOpacity={0.8}
              />
            ))
          }
        </AreaStack>
        {!meetingRoomOccupancy && (
          <LinePath<ChartData>
            curve={curveMonotoneX}
            data={data as ChartData[]}
            x={(d) => xScale(getDate(d)) ?? 0}
            y={(d) => yScale(getMaxUsage(d)) ?? 0}
          >
            {({ path }) => (
              <AnimatedPath<ChartData>
                path={path}
                data={data as ChartData[]}
                stroke={
                  theme.color === Themes.LIGHT
                    ? getColor('NEUTRAL600')
                    : getColor('NEUTRAL300')
                }
              />
            )}
          </LinePath>
        )}
        {!hideBottomAxis && (
          <Axis
            lowLevelChart
            top={yMax}
            scale={xScale}
            numTicks={width > 768 ? 8 : 2}
            tickFormat={
              data.length > 10
                ? (v: Date) => localize(v, 'eeeeee do LLL')
                : undefined
            }
            orientation="bottom"
          />
        )}
        {!hideLeftAxis && (
          <Axis
            lowLevelChart
            numTicks={4}
            tickFormat={(d) => `${d}%`}
            label={
              meetingRoomOccupancy
                ? intl.formatMessage(
                    {
                      id: 'Meeting rooms usage in percent',
                    },
                    {
                      meetingRooms:
                        (data as MeetingChartData[])[0]?.TotalMeetingRooms ?? 0,
                    },
                  )
                : intl.formatMessage(
                    {
                      id: 'Desks usage in percent',
                    },
                    {
                      desks: (data as ChartData[])[0]?.NumberOfDesks ?? 0,
                    },
                  )
            }
            scale={yScale}
            orientation="left"
          />
        )}
        {children}
      </Group>
    </>
  );
}
