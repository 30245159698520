import Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import type Point from 'ol/geom/Point';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import VectorLayer, { type VectorLayerOptions } from '../VectorLayer';

type AdminLayerFeature = { Geometry: any };

export interface Options {
  source?: VectorSource<Feature<Polygon | Point | Geometry>>;
  features?: Feature<Point | Polygon>[];
  name?: string;
  color?: string;
  showLabel?: boolean;
}

export interface AdminLayerOptions
  extends Options,
    VectorLayerOptions<Polygon | Point> {
  olLayer: OLVectorLayer<VectorSource<Feature<Polygon | Point>>>;
  features?: Feature<Point | Polygon>[];
  color?: string;
}

class AdminLayer extends VectorLayer<Polygon | Point> {
  color: string;

  features?: Feature<Point | Polygon>[];

  hidden?: string | null;

  selected: any;

  highlighted?: Feature<Point | Polygon> | null;

  showLabel?: boolean;

  static toGeoJson(feats?: AdminLayerFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const geometry =
        feat instanceof Feature ? feat.getGeometry() : feat.Geometry;
      return {
        type: 'Feature',
        geometry,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }

  constructor(options: Options) {
    super({
      ...options,
      olLayer: new OLVectorLayer({
        source: (options as any).source || new VectorSource(),
        style: (f, r) =>
          (options as any).styleFunction(
            f,
            r,
            this.color,
            this.selected,
            this.hidden,
            this.highlighted,
            this.showLabel,
          ),
      }),
    } as AdminLayerOptions);
    this.color = options.color || 'lightGrey';
    this.features = options.features || [];
    this.showLabel = options.showLabel || false;
    this.selected = null;
    this.hidden = null;
    this.highlighted = null;
  }

  setFeatures(feats: AdminLayerFeature[]): void {
    const source = this.olLayer.getSource() as VectorSource<
      Feature<Polygon | Point>
    >;
    source.clear();
    this.addFeatures(feats);
  }

  addFeatures(feats: AdminLayerFeature[]): void {
    const source = this.olLayer.getSource() as VectorSource<
      Feature<Polygon | Point>
    >;
    const newFeatures = new GeoJSON().readFeatures(AdminLayer.toGeoJson(feats));
    this.features = newFeatures as Feature<Polygon | Point>[];
    source.addFeatures(newFeatures as Feature<Polygon | Point>[]);
  }

  init(map: OLMap): void {
    super.init(map);
  }

  setHighlightedFeature(feat: Feature<Polygon | Point> | null): void {
    this.highlighted = feat;
  }

  setShowLabel(showLabel: boolean): void {
    this.showLabel = showLabel;
  }
}

export default AdminLayer;
