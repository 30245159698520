import Table from 'generic/components/Table/Table';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import useDecodedLocation from 'utils/useDecodedLocation';

import type { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  type 
  AllMqttAssetTrackersQuery,
  useAllMqttAssetTrackersQuery,
  useMqttAssetTrackersNameMutation,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useState } from 'react';
import {
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryHalf,
  FaBatteryQuarter,
  FaBatteryThreeQuarters,
} from 'react-icons/fa';
import { HiCheck, HiOutlinePencil, HiOutlineXMark } from 'react-icons/hi2';

import Tooltip from 'generic/components/Tooltip/Tooltip';
import AssetMap from './components/AssetMap';

type AssetData = AllMqttAssetTrackersQuery['MqttAssetTrackers'][number];

function StatusCell({ cell }: CellContext<AssetData, unknown>) {
  const { IsOffline } = cell.row.original;

  return (
    <div className="flex items-center">
      <div
        className={`h-2.5 w-2.5 rounded-full ${
          IsOffline ? 'bg-red-500' : 'bg-green-500'
        } me-2`}
      />
      <FormattedMessage id={IsOffline ? 'Offline' : 'Online'} />
    </div>
  );
}

function DisplayNameCell({
  row,
}: CellContext<AssetData, unknown>): JSX.Element | string {
  const userRoles = useStore((state) => state.user)?.roles;
  const [value, setValue] = useState<string>(row.original.DisplayName ?? '-');
  const [isEditing, setIsEditing] = useState(false);
  const [, updateDisplayName] = useMqttAssetTrackersNameMutation();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onClick = () => {
    if (value !== row.original.DisplayName) {
      updateDisplayName(
        {
          UniqueIdentifier: row.original.UniqueIdentifier,
          DisplayName: value === '' ? null : value,
        },
        hasuraHeader(HasuraPermissions.WRITE_MQTTASSETTRACKER),
      ).then((data) => {
        if (data.error) {
          setValue('');
        }
        toast(data);
      });
      setIsEditing(false);
    }
  };

  return !row.getIsGrouped() &&
    userRoles?.includes(HasuraPermissions.WRITE_MQTTASSETTRACKER) ? (
    <div className="flex justify-between items-center w-full">
      {isEditing ? (
        <input
          data-test-id="input-asset-name"
          className="dark:bg-neutral-800 w-full mr-1 border -my-1 px-1 border-primary-500 rounded"
          onChange={onChange}
          value={value}
        />
      ) : (
        <span>{value === '' ? '-' : value}</span>
      )}
      {!isEditing ? (
        <HiOutlinePencil
          data-test-id="edit-asset-name"
          className="size-4 cursor-pointer"
          onClick={() => setIsEditing(!isEditing)}
        />
      ) : (
        <div className="flex">
          <HiOutlineXMark
            className="size-4 cursor-pointer"
            onClick={() => {
              setIsEditing(false);
              setValue(row.original.DisplayName ?? '');
            }}
          />
          {value !== row.original.DisplayName && (
            <HiCheck
              data-test-id="save-asset-name"
              className="size-4 cursor-pointer"
              onClick={onClick}
            />
          )}
        </div>
      )}
    </div>
  ) : (
    value
  );
}

function BatteryCell(
  props: CellContext<AssetData, unknown>,
): JSX.Element | string {
  const { row } = props;
  const { BatteryVoltage } = row.original;

  if (!BatteryVoltage) {
    return '-';
  }

  if (BatteryVoltage === 3) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryFull className="size-5 text-green-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage >= 2.75) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryThreeQuarters className="size-5 text-green-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage >= 2.5) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryHalf className="size-5 text-orange-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage >= 2.2) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryQuarter className="size-5 text-orange-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage < 2.2) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryEmpty className="size-5 text-red-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  return '-';
}

function IsMovingCell(props: AssetData) {
  const intl = useIntl();

  return props.IsMoving
    ? intl.formatMessage({ id: 'Moving' })
    : intl.formatMessage({ id: 'Stationary' });
}

export default function AssetList(): JSX.Element {
  const intl = useIntl();
  const [{ data: mqttAssetTrackers, fetching: loading }, reexecuteQuery] =
    useAllMqttAssetTrackersQuery();
  usePolling(loading, reexecuteQuery, 5000);

  const assetName = useDecodedLocation('asset');

  const defaultColumns: ColumnDef<AssetData>[] = [
    {
      id: 'building',
      header: intl.formatMessage({ id: 'Building' }),
      accessorFn: (row) => row.MqttBeacon?.Floor?.Building.Name ?? '-',
    },
    {
      id: 'floor',
      header: intl.formatMessage({ id: 'Floor' }),
      accessorFn: (row) => row.MqttBeacon?.Floor?.Number ?? 0,
    },
    {
      id: 'room',
      header: intl.formatMessage({ id: 'Room' }),
      accessorFn: (row) => row.Room?.Name ?? '-',
    },
    {
      id: 'batteryVoltage',
      header: intl.formatMessage({ id: 'Battery' }),
      accessorFn: (row) => row.BatteryVoltage,
      cell: BatteryCell,
    },
    {
      id: 'displayName',
      header: intl.formatMessage({ id: 'DisplayName' }),
      accessorFn: (row) => row.DisplayName ?? '-',
      cell: DisplayNameCell,
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'Name' }),
      accessorKey: 'Name',
    },
    {
      id: 'xCoordinate',
      header: intl.formatMessage({ id: 'XCoordinate' }),
      accessorFn: (row) => row.XCoordinate,
      accessorKey: 'XCoordinate',
    },
    {
      id: 'yCoordinate',
      header: intl.formatMessage({ id: 'YCoordinate' }),
      accessorFn: (row) => row.YCoordinate,
      accessorKey: 'YCoordinate',
    },
    {
      id: 'isMoving',
      header: intl.formatMessage({ id: 'IsMoving' }),
      accessorFn: IsMovingCell,
    },
    {
      id: 'accuracy',
      header: intl.formatMessage({ id: 'Accuracy' }),
      accessorFn: (row) => row.Accuracy,
      accessorKey: 'Accuracy',
    },
    {
      id: 'uniqueIdentifier',
      header: intl.formatMessage({ id: 'UniqueIdentifier' }),
      accessorKey: 'UniqueIdentifier',
    },
    {
      header: intl.formatMessage({ id: 'Status' }),
      id: 'Status',
      accessorFn: (row) =>
        row.IsOffline
          ? intl.formatMessage({ id: 'Offline' })
          : intl.formatMessage({ id: 'Online' }),
      cell: StatusCell,
    },
    {
      id: 'updatedAt',
      header: intl.formatMessage({ id: 'UpdatedAt' }),
      accessorFn: (row) => new Date(row.UpdatedAt),
      cell: ({ row }) =>
        formattedDistance(new Date(row.original.UpdatedAt), {
          includeSeconds: true,
        }),
      enableColumnFilter: false,
    },
  ];

  return (
    <div className="relative h-full">
      <Table<AssetData>
        id="assetlist"
        columns={defaultColumns}
        data={mqttAssetTrackers?.MqttAssetTrackers ?? []}
        loading={loading}
        initialState={{
          columnVisibility: {
            uniqueIdentifier: false,
            xCoordinate: false,
            yCoordinate: false,
            accuracy: false,
          },
          columnFilters: assetName
            ? [{ id: 'name', value: assetName }]
            : undefined,
        }}
        renderRowSubComponent={{
          render: (row) => {
            const { MqttBeacon } = row.original;

            return (
              <AssetMap
                data={row.original}
                floorNumber={MqttBeacon?.Floor?.Number ?? 0}
                buildingName={MqttBeacon?.Floor?.Building.Name ?? ''}
              />
            );
          },
          expanderColumnId: 'name',
        }}
        key={assetName}
      />
    </div>
  );
}
