import Tab from 'generic/components/Tab';
import Title from 'generic/components/Title';
import { HiOutlineArrowPath, HiOutlineChartBar } from 'react-icons/hi2';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

export default function ReportingView() {
  return (
    <div>
      <div className="space-y-2 print:hidden">
        <div>
          <Title value="Reporting" />
        </div>
      </div>
      <Tab
        tabs={[
          {
            path: '',
            end: true, // avoid to be always active for all routes
            userRole: HasuraPermissions.VIEW_ADMIN,
            name: 'Defective beacons',
            icon: <HiOutlineArrowPath className="md:size-5 size-6" />,
          },
          {
            path: 'reports',
            name: 'Reports',
            // TODO: Use VIEW_ANALYTICS when ready
            userRole: HasuraPermissions.VIEW_REPORTS,
            icon: <HiOutlineChartBar className="md:size-5 size-6" />,
          },
        ]}
      />
    </div>
  );
}
