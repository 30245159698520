import type { ColumnDef, Row } from '@tanstack/react-table';
import { DeviceTypes } from 'common/types';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table, { DELETE_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import BreadCrumbs from 'mda2-frontend/src/generic/components/BreadCrumbs';
import LastUpdated from 'mda2-frontend/src/generic/components/LastUpdated';
import LivePing from 'mda2-frontend/src/generic/components/LivePing';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import Title from 'mda2-frontend/src/generic/components/Title';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import {
  MqttSystems,
  useLiveBeaconsQuery,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import { formattedDistance } from 'mda2-frontend/src/utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import parseBluerangeTopic from 'mda2-frontend/src/utils/parseBluerangeTopic';
import useDecodedLocation from 'mda2-frontend/src/utils/useDecodedLocation';
import { getStatus } from 'pages/ReportingView/DefectiveBeacons/components/ReportingMap/ReportingMap';
import { useCallback, useMemo, useState } from 'react';
import {
  HiOutlineBugAnt,
  HiOutlineCog,
  HiOutlineRocketLaunch,
  HiOutlineTrash,
} from 'react-icons/hi2';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import parseMda3Topic from 'utils/parseMda3Topic';
import BeaconMap from './components/BeaconMap';
import BluerangeMqttAction from './components/BluerangeMqttAction';
import {
  DeleteCell,
  DeviceTypeCell,
  SensorTypesCell,
  StatusCell,
  getStatusForBeacon,
} from './components/Cells';
import {
  AutoUpdateCell,
  FWPackageCell,
  getIncompliantFirmwares,
} from './components/Cells/Cells';
import ConfigureBeaconModal from './components/ConfigureBeaconModal';
import type { ConfigurableBeacons } from './components/ConfigureBeaconModal/ConfigureBeaconModal';
import MaintenanceModal from './components/MaintenanceModal/MaintenanceModal';
import Mda3MqttAction from './components/Mda3MqttAction/Mda3MqttAction';
import MqttStatus from './components/MqttStatus';
import OfflineChart from './components/OfflineChart';
import OrganizationStatus from './components/OrganizationStatus';
import RemoveBeaconModal from './components/RemoveBeaconModal';
import type { LiveBeaconsQueryData } from './components/RemoveBeaconModal/RemoveBeaconModal';
import SendRPCModal from './components/SendRPCModal';
import type { RPCBeacons } from './components/SendRPCModal/SendRPCModal';
import SensorsStatus from './components/SensorsStatus';
import StatusChart from './components/StatusChart';
import StatusSkeleton from './components/StatusSkeleton';
import type { UpgradeBeacons } from './components/UpgradeBeaconModal/UpgradeBeaconModal';
import UpgradeBeaconModal from './components/UpgradeBeaconModal/UpgradeBeaconModal';

export default function StatusView() {
  const [maintenanceModeModalOpen, setMaintenanceModalOpen] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [resetSelection, setResetSelection] = useState<() => void>();
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    { name: string; online: number; offline: number }[]
  >([]);
  const [beacons, setBeacons] = useState<string[]>([]);
  const userRoles = useStore((state) => state.user)?.roles;
  const [resultsFiltered, setResultsFiltered] = useState<boolean>(false);
  const [beaconsToRemove, setBeaconsToRemove] = useState<
    Row<LiveBeaconsQueryData>[] | undefined
  >();
  const [beaconsToSendRPC, setBeaconsToSendRPC] = useState<
    RPCBeacons[] | undefined
  >();
  const [beaconsToUpgrade, setBeaconsToUpgrade] = useState<
    UpgradeBeacons[] | undefined
  >();
  const [beaconsToConfigure, setBeaconsToConfigure] = useState<
    ConfigurableBeacons[] | undefined
  >();
  const beaconName = useDecodedLocation('beacon');
  const building = useDecodedLocation('building');
  const floor = useDecodedLocation('floor');
  const status = useDecodedLocation('status');
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();

  const [{ data: liveBeacons, fetching: loading }, reexecuteQuery] =
    useLiveBeaconsQuery({
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.READ,
          ),
        [hasuraHeader, userRoles],
      ),
      variables: {
        Admin: !!userRoles?.includes(HasuraPermissions.READ_ALL),
      },
    });
  usePolling(loading, reexecuteQuery, 1000 * 30, setLastUpdate);

  const dataCallback = useCallback(
    (data: Row<LiveBeaconsQueryData>[]) => {
      setBeacons(data.map((s) => s.original.UniqueIdentifier));

      // Sending all beacons in the params is the same as sending none. However, Hasura is much slower
      // when there are Beacons in the params, so by default sending none
      if (
        liveBeacons?.MqttBeacons &&
        data.length !== liveBeacons.MqttBeacons.length
      ) {
        setResultsFiltered(true);
      } else {
        setResultsFiltered(false);
      }

      const uniqueOrganizations = [
        ...new Set(
          data
            .map((d) => d.original.MqttOrganization.Organization.Name)
            .sort((a, b) => a.localeCompare(b)),
        ),
      ];

      setFilteredOrganizations(
        uniqueOrganizations.map((organization) => ({
          name: organization,
          online: data.filter(
            (p) =>
              p.original.MqttOrganization.Organization.Name === organization &&
              getStatusForBeacon(
                !!p.original.IsOffline,
                p.original.BluerangeInfo?.Status ?? '-',
                p.original.DeviceType?.Name,
              ) === 'Online',
          ).length,
          offline: data.filter(
            (p) =>
              p.original.MqttOrganization.Organization.Name === organization &&
              getStatusForBeacon(
                !!p.original.IsOffline,
                p.original.BluerangeInfo?.Status ?? '-',
                p.original.DeviceType?.Name,
              ) === 'Offline',
          ).length,
        })),
      );
    },
    [liveBeacons, liveBeacons?.MqttBeacons],
  );

  const columns: ColumnDef<LiveBeaconsQueryData>[] = useMemo(
    () => [
      {
        header: intl.formatMessage({ id: 'Organization' }),
        id: 'Organization',
        accessorFn: (row) => row.MqttOrganization.Organization.Name,
      },
      {
        header: intl.formatMessage({ id: 'Device Type' }),
        id: 'DeviceType',
        accessorFn: (row) =>
          row.DeviceType?.Name ?? intl.formatMessage({ id: 'Unknown' }),
        cell: DeviceTypeCell,
      },
      {
        header: intl.formatMessage({ id: 'Building' }),
        id: 'BuildingName',
        accessorFn: (row) => row.Floor?.Building.Name ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Floor' }),
        id: 'FloorNumber',
        accessorFn: (row) => row.Floor?.Number,
      },
      {
        header: intl.formatMessage({ id: 'Room' }),
        id: 'RoomName',
        accessorFn: (row) => {
          const joinedRooms = [
            ...new Set(
              ...row.Sensors.map((s) =>
                s.RoomSensors.map((rs) => rs.Room.Name),
              ).filter((rS) => rS.length), // filter empty relations
            ),
          ]
            .sort((a, b) => a.localeCompare(b))
            .join(',');

          if (joinedRooms === '') return '-';

          return joinedRooms;
        },
      },
      {
        header: intl.formatMessage({ id: 'Room labels' }),
        id: 'RoomLabels',
        accessorFn: (row) => {
          const joinedLabels = [
            ...new Set(
              ...row.Sensors.map(
                (s) =>
                  s.RoomSensors.map((rs) =>
                    rs.Room.RoomLabels.map((rl) => rl.Label.Name),
                  ).filter((rS) => rS.length), // filter empty relations,,
              ).filter((rS) => rS.length), // filter empty relations,
            ),
          ].join(',');

          if (joinedLabels === '') return '-';

          return joinedLabels;
        },
      },
      {
        header: 'Beacon',
        id: 'BeaconName',
        accessorFn: (row) => row.Name,
      },
      {
        header: intl.formatMessage({ id: 'Status' }),
        id: 'Status',
        accessorFn: (row) =>
          intl.formatMessage({
            id: getStatusForBeacon(
              !!row.IsOffline,
              row.BluerangeInfo?.Status ?? '-',
              row.DeviceType?.Name,
            ),
          }),
        cell: StatusCell,
      },
      {
        header: intl.formatMessage({ id: 'Auto updates' }),
        id: 'isExcludedFromUpdates',
        accessorFn: (row) =>
          row.IsExcludedFromUpdates
            ? intl.formatMessage({ id: 'No' })
            : intl.formatMessage({ id: 'Yes' }),
        cell: AutoUpdateCell,
      },
      {
        header: intl.formatMessage({ id: 'Sensor policy' }),
        id: 'sensorPolicy',
        accessorFn: (row) => row.SensorPolicy?.Name ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Bluerange OS' }),
        id: 'BluerangeOS',
        accessorFn: (row) => row.BluerangeInfo?.OS ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Bluerange FW' }),
        id: 'BluerangeFW',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'clbtm',
          )?.Firmware.Version ??
          row.BluerangeInfo?.FW ??
          '-',
      },
      {
        header: intl.formatMessage({ id: 'Bluerange Status' }),
        id: 'BluerangeStatus',
        accessorFn: (row) => row.BluerangeInfo?.Status ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Beacon Status' }),
        id: 'MqttBeaconStatus',
        accessorFn: (row) =>
          getStatus(row.StatusCode, row.DeviceType?.Name as DeviceTypes),
      },
      {
        header: intl.formatMessage({ id: 'Beacon Source' }),
        id: 'MqttBeaconSource',
        accessorFn: (row) => row.MqttBeaconSource.Name ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Available desks' }),
        id: 'MqttBeaconAvailableDesks',
        accessorFn: (row) => row.NumberOfAvailableDesks,
      },
      {
        header: intl.formatMessage({ id: 'Sensor types' }),
        id: 'SensorTypes',
        accessorFn: (row) =>
          row.Sensors.map((s) =>
            intl.formatMessage({ id: s.SensorType.Name as IntlMessageKeys }),
          ).join(', '),
        cell: SensorTypesCell,
      },
      {
        accessorFn: (row) =>
          row.MqttBeaconHistories_aggregate.aggregate?.count ?? 0,
        id: 'changes',
        header: intl.formatMessage({ id: 'State changes' }),
      },
      {
        header: 'FW Package',
        id: 'FWPackage',
        accessorFn: (row) => row.FirmwarePackage?.Version ?? '-',
        cell: FWPackageCell,
      },
      {
        header: intl.formatMessage({ id: 'Compliant' }),
        id: 'FWPackageCompliant',
        accessorFn: (row) =>
          getIncompliantFirmwares(row).length === 0
            ? intl.formatMessage({ id: 'Yes' })
            : intl.formatMessage({ id: 'No' }),
      },
      {
        header: 'FW CLM',
        id: 'FWCLM',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find((f) => f.Firmware.Module.Name === 'clm')
            ?.Firmware.Version ?? '-',
      },
      {
        header: 'FW eCLM',
        id: 'FWeCLM',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'eclm',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW ControlUnit',
        id: 'FWControlUnit',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'controlunit',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW ControlUnit M4',
        id: 'FWControlUnitM4',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'controlunit_m4',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW AloneAtWork',
        id: 'FWAloneAtWork',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'aloneatwork',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW Panel 0',
        id: 'FWPanel0',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'panel' && f.Index === 0,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW Panel 1',
        id: 'FWPanel1',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'panel' && f.Index === 1,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 0',
        id: 'FWSensorModule0',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 0,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 1',
        id: 'FWSensorModule1',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 1,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 2',
        id: 'FWSensorModule2',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 2,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 3',
        id: 'FWSensorModule3',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 3,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SmartCore',
        id: 'FWSmartCore',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'smartcore',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW Xovis',
        id: 'FWXovis',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'xovis',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW EspSens',
        id: 'FWEspSens',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'esp_sens',
          )?.Firmware.Version ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Last updated' }),
        id: 'LastSeen',
        accessorFn: (row) => new Date(row.LastHeartbeat),
        cell: ({ row }) =>
          formattedDistance(new Date(row.original.LastHeartbeat)),
        enableColumnFilter: false,
      },
      {
        enableGrouping: false,
        // Make an edit/delete cell
        id: DELETE_ID,
        cell: ({ row }) => (
          <DeleteCell row={row} setBeaconsToRemove={setBeaconsToRemove} />
        ),
      },
    ],
    [intl.formatMessage],
  );

  const organizations = useMemo(
    () => [
      ...new Set<string>(
        liveBeacons?.MqttBeacons.map(
          (d) => d.MqttOrganization.Organization.Name,
        ),
      ),
    ],
    [liveBeacons?.MqttBeacons],
  );

  const columnFilters = useMemo(() => {
    const filters: { id: string; value: string }[] = [];

    if (beaconName) filters.push({ id: 'BeaconName', value: beaconName });
    if (status) filters.push({ id: 'Status', value: status });
    if (building) filters.push({ id: 'BuildingName', value: building });
    if (floor) filters.push({ id: 'FloorNumber', value: floor });

    return filters;
  }, [beaconName, building, status, floor]);

  const filteredColumns = useMemo(
    () =>
      organizations.length < 2
        ? columns.filter(
            (c) =>
              c.id !== 'Organization' &&
              // Do not show bluerange, FW infos and Beacon Source for other organizations
              !c.id?.startsWith('Bluerange') &&
              !c.id?.startsWith('FW') &&
              c.id !== 'MqttBeaconSource',
          )
        : columns,
    [organizations, columns],
  );

  const filteredData = useMemo(
    () =>
      organizations.length < 2
        ? // Hide gateways for other organizations
          liveBeacons?.MqttBeacons.filter(
            (d) => d.DeviceType?.Name !== 'Gateway',
          ) ?? []
        : liveBeacons?.MqttBeacons ?? [],
    [organizations, liveBeacons?.MqttBeacons],
  );

  const initialState = useMemo(
    () => ({
      columnFilters,
      sorting:
        organizations.length < 2
          ? [
              { id: 'BuildingName', desc: false },
              { id: 'FloorNumber', desc: false },
              { id: 'BeaconName', desc: false },
            ]
          : [
              { id: 'Organization', desc: false },
              { id: 'BuildingName', desc: false },
              { id: 'FloorNumber', desc: false },
              { id: 'BeaconName', desc: false },
            ],
    }),
    [columnFilters, organizations.length],
  );

  return (
    <>
      <Loader loading={loading} />
      <div className="space-y-4">
        <div>
          <div className="flex items-center">
            <LivePing />
            <Title value="Status" />
          </div>
          <LastUpdated lastUpdate={lastUpdate} />
        </div>
        <div className="flex space-x-2 justify-between">
          <BreadCrumbs
            items={[
              {
                text: intl.formatMessage({
                  id: 'Status',
                }),
              },
            ]}
          />
          <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
            <div className="flex space-x-2">
              <Tooltip
                content={
                  <StyledButton onClick={() => setMaintenanceModalOpen(true)}>
                    <HiOutlineBugAnt className="size-5" />
                  </StyledButton>
                }
              >
                <FormattedMessage id="Enter maintenance mode" />
              </Tooltip>
            </div>
          </PrivateWrapper>
        </div>
        <div className="space-y-2 w-full">
          {filteredOrganizations.length > 0 ? (
            <div
              className={
                organizations.length === 1
                  ? 'flex space-x-2 items-center'
                  : 'space-y-2'
              }
            >
              <div className="flex flex-wrap gap-1 md:gap-2">
                {filteredOrganizations.map(({ name, online, offline }) => (
                  <div key={name}>
                    <OrganizationStatus
                      organization={name}
                      offlineBeacons={offline}
                      onlineBeacons={online}
                    />
                  </div>
                ))}
              </div>
              <div className="h-32 relative w-full">
                <OfflineChart beacons={resultsFiltered ? beacons : null} />
              </div>
            </div>
          ) : (
            <StatusSkeleton loading={loading} />
          )}
          <Table<LiveBeaconsQueryData>
            id="statusview"
            enabledFeatures={{
              enableRowSelection: true,
            }}
            columns={filteredColumns}
            data={filteredData}
            dataCallback={dataCallback}
            loading={loading}
            renderSelectedAction={(selected, resetRowSelection) => [
              {
                onClick: () =>
                  setBeaconsToConfigure(
                    selected.map((s) => ({
                      id: s.original.Id,
                      name: s.original.Name,
                      topic: s.original.MqttTopic,
                      organization:
                        s.original.MqttOrganization.Organization.Name,
                      mqttSystem: s.original.MqttBeaconSource
                        .Name as MqttSystems,
                    })),
                  ),
                text: intl.formatMessage({
                  id: 'Configure selected beacons',
                }),
                icon: <HiOutlineCog className="size-5" />,
                permission: HasuraPermissions.VIEW_ADMIN,
              },
              {
                onClick: () => {
                  setBeaconsToRemove(selected);
                  setResetSelection(resetRowSelection);
                },
                text: intl.formatMessage({ id: 'Delete selected beacons' }),
                icon: <HiOutlineTrash className="size-5" />,
                permission: HasuraPermissions.DELETE_MQTTBEACON,
              },
              // Only MDA3 supports upgrades. Only allow it for God
              selected.filter(
                (s) => s.original.MqttBeaconSource.Name === MqttSystems.Mda3,
              ).length
                ? {
                    onClick: () =>
                      setBeaconsToUpgrade(
                        selected
                          .filter(
                            (s) =>
                              s.original.MqttBeaconSource.Name ===
                              MqttSystems.Mda3,
                          )
                          .map((s) => ({
                            mqttTopic: s.original.MqttTopic,
                            espSensVersion:
                              s.original.MqttBeaconFirmwares?.find(
                                (f) => f.Firmware.Module.Name === 'esp_sens',
                              )?.Firmware.Version,
                          })),
                      ),
                    text: intl.formatMessage({
                      id: 'Upgrade selected beacons',
                    }),
                    icon: <HiOutlineRocketLaunch className="size-5" />,
                    permission: HasuraPermissions.READ_ALL,
                  }
                : undefined,
              {
                onClick: () =>
                  setBeaconsToSendRPC(
                    selected.map((s) => ({
                      mqttTopic: s.original.MqttTopic,
                      mqttSystem: s.original.MqttBeaconSource
                        .Name as MqttSystems,
                    })),
                  ),
                text: intl.formatMessage({ id: 'Send RPC' }),
                icon: <HiOutlineBugAnt className="size-5" />,
                permission: HasuraPermissions.READ_ALL,
              },
            ]}
            renderRowSubComponent={{
              render: (row) => (
                <div className="px-6 py-2 space-y-2">
                  <div className="h-48 max-width-screen">
                    <StatusChart beaconName={row.original.Name} />
                  </div>
                  <SensorsStatus beaconName={row.original.Name} />
                  <Transition
                    show={
                      typeof row.original.Floor?.Number === 'number' &&
                      !!row.original.Floor.Building.Name
                    }
                  >
                    <div className="max-width-screen">
                      <BeaconMap
                        data={row.original}
                        floorNumber={row.original.Floor?.Number ?? 0}
                        buildingName={row.original.Floor?.Building.Name ?? ''}
                      />
                    </div>
                  </Transition>

                  <PrivateWrapper roleRequired={HasuraPermissions.VIEW_ADMIN}>
                    <>
                      <Transition
                        show={
                          row.original.MqttBeaconSource.Name ===
                          MqttSystems.Bluerange
                        }
                      >
                        <MqttStatus
                          mqttTopic={parseBluerangeTopic(
                            row.original.MqttTopic,
                          )}
                          mqttSystem={MqttSystems.Bluerange}
                        />
                        <Transition
                          show={
                            row.original.DeviceType?.Name !==
                            DeviceTypes.GATEWAY
                          }
                        >
                          <BluerangeMqttAction
                            mqttTopic={parseBluerangeTopic(
                              row.original.MqttTopic,
                            )}
                          />
                        </Transition>
                      </Transition>

                      <Transition
                        show={
                          row.original.MqttBeaconSource.Name ===
                          MqttSystems.Mda3
                        }
                      >
                        <Mda3MqttAction
                          mqttTopic={`cmd/${parseMda3Topic(
                            row.original.MqttTopic,
                          )}/identify`}
                        />
                      </Transition>
                    </>
                  </PrivateWrapper>
                </div>
              ),
              expanderColumnId: 'BeaconName',
            }}
            initialState={initialState}
          />
        </div>
      </div>

      <PrivateWrapper roleRequired={HasuraPermissions.DELETE_MQTTBEACON}>
        <RemoveBeaconModal
          resetSelection={resetSelection}
          beaconsToRemove={beaconsToRemove ?? []}
          setBeaconsToRemove={setBeaconsToRemove}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_ADMIN}>
        <ConfigureBeaconModal
          beaconsToConfigure={beaconsToConfigure}
          setBeaconsToConfigure={setBeaconsToConfigure}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <UpgradeBeaconModal
          beaconsToUpgrade={beaconsToUpgrade}
          setBeaconsToUpgrade={setBeaconsToUpgrade}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <SendRPCModal
          beaconsToSendRPC={beaconsToSendRPC}
          setBeaconsToSendRPC={setBeaconsToSendRPC}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <MaintenanceModal
          open={maintenanceModeModalOpen}
          setOpen={setMaintenanceModalOpen}
        />
      </PrivateWrapper>
    </>
  );
}
