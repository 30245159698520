import { isAfter, subMinutes } from 'date-fns';
import type { OccupancyMapQuery } from 'mda2-frontend/src/graphql/types';
import { lower, upper } from 'mda2-frontend/src/utils/date';
import getColor from 'mda2-frontend/src/utils/getColor';
import renderOfflineIcon from 'mda2-frontend/src/utils/renderOfflineIcon';
import renderReservedIcon from 'mda2-frontend/src/utils/renderReservedIcon';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

type DeskInUseFeature = NonNullable<OccupancyMapQuery['Desks']>[number];

export type DeskInUseFeatureType = TypedFeature<DeskInUseFeature, Geometry>;

const colorFunction = (
  feature: DeskInUseFeatureType,
  warmTimeMinutes: number,
  opacity: string,
  offline: boolean,
) => {
  const sensor = feature.getProperties().Sensor;
  const isPrivate = sensor?.IsPrivate;
  if (offline) {
    return getColor('NEUTRAL400', opacity);
  }
  if (isPrivate) {
    return getColor('NEUTRAL600', opacity);
  }
  if (sensor?.Value === 0) {
    // Value is 0, but desk was used within the time specified by warm minutes
    if (
      isAfter(
        new Date(sensor?.UpdatedAt),
        subMinutes(new Date(), warmTimeMinutes),
      )
    ) {
      return getColor('YELLOW', opacity);
    }
  } else {
    // Value is 1, so it is in use
    return getColor('RED', opacity);
  }
  // Sensor value is 0 and last time it was used is longer than the warm minutes
  return getColor('GREEN', opacity);
};

const styleFunction = (
  feature: DeskInUseFeatureType,
  resolution: number,
  warmTimeMinutes: number,
  hoveredFeature: boolean,
  map: OLMap,
) => {
  const offline = !!feature.getProperties().Sensor?.MqttBeacon.IsOffline;
  const reservations = feature.getProperties().DeskReservations;
  const isReserved = reservations.find(
    (reservation) =>
      !reservation.Cancelled &&
      lower(reservation.Duration) < new Date() &&
      upper(reservation.Duration) > new Date(),
  );
  const extraStyle = !isReserved
    ? renderOfflineIcon(feature, offline, map)
    : renderReservedIcon(
        feature,
        !!isReserved,
        map,
        isReserved?.IsPersonal ?? false,
      );
  return [
    new Style({
      image: new Circle({
        radius: feature.getProperties().Radius / resolution,
        fill: new Fill({
          color: colorFunction(
            feature,
            warmTimeMinutes,
            hoveredFeature ? '1' : '.5',
            offline,
          ),
        }),
        stroke: new Stroke({
          color: colorFunction(
            feature,
            warmTimeMinutes,
            hoveredFeature ? '1' : '.5',
            offline,
          ),
          width: 2,
        }),
      }),
    }),
    extraStyle,
  ];
};

class DeskInUseLayer extends VectorLayer<Polygon> {
  features?: DeskInUseFeature[];

  hoveredFeature?: DeskInUseFeatureType;

  warmTimeMinutes?: number;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature, resolution) =>
            styleFunction(
              feature as DeskInUseFeatureType,
              resolution,
              this.warmTimeMinutes ?? 0,
              feature.getGeometry() === this.hoveredFeature?.getGeometry(),
              this.map,
            ),
        }),
      },
      name: 'currentDeskInUse',
    });

    this.warmTimeMinutes = 0;
  }

  setFeatures(feats: DeskInUseFeature[]): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      DeskInUseLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  static toGeoJson(feats?: DeskInUseFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry: geo } = feat;
      return {
        type: 'Feature',
        geometry: geo,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default DeskInUseLayer;
