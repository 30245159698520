import { useIntl } from 'translations/Intl';

import { ListboxOption } from '@headlessui/react';
import Transition from 'generic/components/Transition';
import { HiCheck, HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi2';

interface OptionProps<T> {
  option: T;
  renderValue: (val: any) => string;
  disabled?: boolean;
  onOptionHover?: (option: T, enter: boolean) => void;
  canEdit?: boolean;
  canDelete?: boolean;
  setOptionToEdit: (option: string) => void;
  setOptionToDelete: (option: string) => void;
}

export default function Option<T>({
  option,
  renderValue,
  disabled = false,
  onOptionHover,
  canEdit = false,
  canDelete = false,
  setOptionToEdit,
  setOptionToDelete,
}: OptionProps<T>): JSX.Element {
  const intl = useIntl();

  return (
    <ListboxOption
      className={({ selected }) =>
        `${
          disabled
            ? 'bg-neutral-200 hover:bg-neutral-200 dark:bg-neutral-700 cursor-default dark:hover:bg-neutral-700'
            : 'cursor-pointer'
        } ${
          selected
            ? 'text-primary-900 bg-primary-100'
            : 'text-neutral-900 dark:text-white'
        } select-none relative py-2 pl-10 pr-4 flex gap-2 hover:text-primary-900 hover:dark:text-primary-900 hover:bg-primary-100`
      }
      data-test-id={`option-${renderValue(option)}`}
      value={option}
      disabled={disabled}
      onMouseEnter={() => onOptionHover?.(option, true)}
      onMouseLeave={() => onOptionHover?.(option, false)}
    >
      {({ selected }) => (
        <>
          <div className="flex justify-between w-full space-x-2">
            <div
              className={`${selected ? 'font-bold' : 'font-normal'} ${
                disabled ? 'text-neutral-400' : 'cursor-pointer'
              } block break-words`}
            >
              {renderValue(option)}
            </div>
            <Transition className="flex" show={canEdit || canDelete}>
              {canEdit && typeof option === 'string' && (
                <HiOutlinePencil
                  className="size-5"
                  title={intl.formatMessage({ id: 'Edit' })}
                  onClick={(e) => {
                    setOptionToEdit(option);
                    e.stopPropagation();
                  }}
                />
              )}
              {canDelete && typeof option === 'string' && (
                <HiOutlineTrash
                  className="size-5"
                  title={intl.formatMessage({ id: 'Delete' })}
                  onClick={(e) => {
                    setOptionToDelete(option);
                    e.stopPropagation();
                  }}
                />
              )}
            </Transition>
          </div>
          {selected && (
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <HiCheck className="size-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </ListboxOption>
  );
}
