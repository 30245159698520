import useStore from 'model/store';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import { Themes } from 'mda2-frontend/src/common/types';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import { HiCheckCircle } from 'react-icons/hi2';

export default function ModeSwitch(): JSX.Element {
  const theme = useStore((state) => state.userSettings.theme);
  const setTheme = useStore((state) => state.userApi.setTheme);

  return (
    <div className="flex flex-col space-y-6 xl:space-x-6 xl:space-y-0 xl:flex-row w-auto relative">
      <Button
        onClick={() => {
          setTheme({
            color: window.matchMedia('(prefers-color-scheme: dark)').matches
              ? Themes.DARK
              : Themes.LIGHT,
            useDeviceTheme: true,
          });
        }}
        id="device-mode"
        className="w-auto max-w-md xl:w-32 relative"
      >
        <p className="pb-2 text-left">
          <FormattedMessage id="Device mode" />
        </p>

        <div className="w-full relative">
          <div className="transition-all flex shadow rounded hover:shadow-lg">
            <div className="w-1/2">
              <div className="rounded-l p-4 bg-white border border-r-0 border-neutral-200">
                <div className="flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-neutral-900 rounded w-3/4" />

                    <div className="h-4 bg-neutral-900 rounded" />

                    <div className="h-4 bg-neutral-900 rounded w-5/6" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <div className="rounded-r p-4 bg-neutral-900 border border-l-0 border-neutral-200">
                <div className="flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-white rounded w-3/4" />

                    <div className="h-4 bg-white rounded" />

                    <div className="h-4 bg-white rounded w-5/6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {theme.useDeviceTheme && (
            <HiCheckCircle
              id="selected"
              className="-bottom-2 -right-2 absolute size-7 text-green-400"
            />
          )}
        </div>
      </Button>
      {Object.values(Themes).map((color) => (
        <Button
          onClick={() => {
            setTheme({ color, useDeviceTheme: false });
          }}
          key={color}
          id={`${color}-mode`}
          className="w-auto max-w-md xl:w-32 relative"
        >
          <p className="pb-2 text-left">
            <FormattedMessage
              id={
                `${
                  color.charAt(0).toUpperCase() + color.slice(1)
                } mode` as IntlMessageKeys
              }
            />
          </p>

          <div className="w-auto relative">
            <div
              className={`transition-all shadow border border-neutral-200 hover:shadow-lg rounded-md p-4 ${
                color === Themes.LIGHT ? 'bg-white' : 'bg-neutral-900'
              }`}
            >
              <div className="flex space-x-4">
                <div className="flex-1 space-y-4 py-1">
                  <div
                    className={`${
                      color === Themes.LIGHT ? 'bg-neutral-900' : 'bg-white'
                    } h-4 rounded w-3/4`}
                  />

                  <div
                    className={`${
                      color === Themes.LIGHT ? 'bg-neutral-900' : 'bg-white'
                    } h-4 rounded`}
                  />

                  <div
                    className={`${
                      color === Themes.LIGHT ? 'bg-neutral-900' : 'bg-white'
                    } h-4 rounded w-5/6`}
                  />
                </div>
              </div>
            </div>
            {color === theme.color && !theme.useDeviceTheme && (
              <HiCheckCircle
                id="selected"
                className="-bottom-2 -right-2 absolute size-7 text-green-400"
              />
            )}
          </div>
        </Button>
      ))}
    </div>
  );
}
