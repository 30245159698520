import Event from 'ol/events/Event';
import type Feature from 'ol/Feature';
import type Geometry from 'ol/geom/Geometry';
import type MapBrowserEvent from 'ol/MapBrowserEvent';

export const RotateEventType = {
  START: 'rotatestart',
  END: 'rotateend',
};

export default class RotateEvent extends Event {
  feature: Feature<Geometry>;

  mapBrowserEvent: MapBrowserEvent<UIEvent>;

  rotation?: number;

  constructor(
    type: string,
    feature: Feature<Geometry>,
    mapBrowserPointerEvent: MapBrowserEvent<UIEvent>,
    rotation?: number,
  ) {
    super(type);
    this.feature = feature;
    this.mapBrowserEvent = mapBrowserPointerEvent;
    this.rotation = rotation;
  }
}
