/* Author: Dalibor Kundrat  https://github.com/damikun */

import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import Button from 'mda2-frontend/src/generic/components/Form/Button';
import { useEffect } from 'react';
import {
  HiOutlineCheckBadge,
  HiOutlineExclamationCircle,
  HiOutlineExclamationTriangle,
  HiOutlineXMark,
} from 'react-icons/hi2';

interface ToastVariant {
  base: string;
  icon: JSX.Element;
  name: string;
}

interface ToastVariants {
  [key: string]: ToastVariant;
}

export enum ToastTypes {
  SUCCESS = 'Success',
  WARNING = 'Warning',
  INFO = 'Info',
  ERROR = 'Error',
}

export interface ToastMessageProp {
  id: string;
  message: string;
  lifetime?: number;
  onRemove?: (id: string) => void;
  type: ToastTypes;
}

const VARIANTS: ToastVariants = {
  Info: {
    base: 'backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 border-blue-500',
    icon: (
      <div className="bg-blue-100 mx-auto flex-shrink-0 flex items-center justify-center size-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <HiOutlineExclamationCircle className="mx-auto size-6 text-blue-500" />
      </div>
    ),
    name: ToastTypes.INFO,
  },

  Error: {
    base: 'backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 border-red-500 ',
    icon: (
      <div className="bg-red-100 mx-auto flex-shrink-0 flex items-center justify-center size-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <HiOutlineExclamationTriangle className="mx-auto size-6 text-red-500" />
      </div>
    ),
    name: ToastTypes.ERROR,
  },

  Warning: {
    base: 'backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 border-yellow-500',
    icon: (
      <div className="bg-yellow-100 mx-auto flex-shrink-0 flex items-center justify-center size-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <HiOutlineExclamationTriangle className="mx-auto size-6 text-yellow-500" />
      </div>
    ),
    name: ToastTypes.WARNING,
  },

  Success: {
    base: 'backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 border-green-500',
    icon: (
      <div className="bg-green-100 mx-auto flex-shrink-0 flex items-center justify-center size-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <HiOutlineCheckBadge className="mx-auto size-6 text-green-500" />
      </div>
    ),
    name: ToastTypes.SUCCESS,
  },
};

export default function ToastMessage({
  id,
  message,
  lifetime,
  onRemove,
  type,
}: ToastMessageProp): JSX.Element | null {
  const variant = VARIANTS[type];

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, lifetime);
    }
  }, [id, lifetime, onRemove]);

  if (!variant) return null;

  const { icon, base, name } = variant;

  return (
    <div
      className={`flex w-full visible flex-row shadow-lg border-l-4 rounded-md duration-100 cursor-pointer transition-all hover:scale-102 ${base} max-h-40`}
    >
      <div className="flex flex-row p-2 flex-no-wrap w-full">
        <div className="mx-2 flex items-center text-xl select-none">{icon}</div>

        <div className="flex flex-col flex-no-wrap px-1 w-full">
          <div className="flex my-auto select-none dark:text-neutral-50">
            <FormattedMessage id={name as IntlMessageKeys} />
          </div>

          <p
            data-test-id="toast-message-content"
            className="-mt-0.5 my-auto break-word flex text-neutral-600 dark:text-neutral-200 text-sm"
          >
            {message}
          </p>
        </div>

        <Button
          data-test-id="close-toast"
          onClick={() => onRemove?.(id)}
          className="z-50 mr-2 items-center mx-auto text-center leading-none text-lg"
        >
          <HiOutlineXMark className="w-6 mx-auto my-auto h-full text-center text-neutral-600 dark:text-neutral-200 cursor-pointer transition-all hover:scale-105" />
        </Button>
      </div>
    </div>
  );
}
