/* eslint-disable no-param-reassign */
import {
  type 
  ToastMessageProp,
  ToastTypes,
} from 'mda2-frontend/src/generic/components/layout/ToastNotification/ToastMessage';
import { create } from 'zustand';

interface State {
  toasts: ToastMessageProp[];
}

interface Action {
  addErrorToast: (message: string) => void;
  addSuccessToast: (message: string) => void;
  addWarningToast: (message: string) => void;
  addInfoToast: (message: string) => void;
  removeToast: (id: string) => void;
}

const DEFAULT_INTERVAL = 4000;
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const useToastStore = create<State & Action>()((set) => ({
  toasts: [],
  addErrorToast: (errorMessage) =>
    set((state) => ({
      toasts: [
        ...state.toasts,
        {
          id: uuidv4(),
          message: errorMessage,
          type: ToastTypes.ERROR,
          lifetime: DEFAULT_INTERVAL,
        },
      ],
    })),
  addSuccessToast: (successMessage) =>
    set((state) => ({
      toasts: [
        ...state.toasts,
        {
          id: uuidv4(),
          message: successMessage,
          type: ToastTypes.SUCCESS,
          lifetime: DEFAULT_INTERVAL,
        },
      ],
    })),
  addWarningToast: (warningMessage) =>
    set((state) => ({
      toasts: [
        ...state.toasts,
        {
          id: uuidv4(),
          message: warningMessage,
          type: ToastTypes.WARNING,
          lifetime: DEFAULT_INTERVAL,
        },
      ],
    })),
  addInfoToast: (infoMessage) =>
    set((state) => ({
      toasts: [
        ...state.toasts,
        {
          id: uuidv4(),
          message: infoMessage,
          type: ToastTypes.INFO,
          lifetime: DEFAULT_INTERVAL,
        },
      ],
    })),
  removeToast: (id) =>
    set((state) => ({
      toasts: state.toasts.filter((t) => t.id !== id),
    })),
}));

export default useToastStore;
