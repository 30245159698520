import StyledButton from 'generic/components/Form/Button/StyledButton';
import Tooltip from 'generic/components/Tooltip';
import { useGenerateReportMutation } from 'graphql/types';
import { useEffect } from 'react';
import { HiOutlineArrowDownTray } from 'react-icons/hi2';
import { FormattedMessage } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import ReportClimateValues from './components/ReportClimateValues';
import ReportDescription from './components/ReportDescription';
import ReportDeskLabelOccupancy from './components/ReportDeskLabelOccupancy';
import ReportFixOccupancy from './components/ReportFixOccupancy';
import ReportFlexOccupancy from './components/ReportFlexOccupancy';
import ReportRoomLabelOccupancy from './components/ReportRoomLabelOccupancy';
import ReportRoomSize from './components/ReportRoomSize';
import ReportRoomSizeOccupancy from './components/ReportRoomSizeOccupancy';

export default function Reports() {
  const hasuraHeader = useHasuraHeader();
  const [{ data, fetching }, generateReport] = useGenerateReportMutation();
  const { Start, End, Building } = useAnalyticsFilter();

  useEffect(() => {
    if (data?.GenerateReport?.base64Pdf) {
      // Create a new anchor element
      const a = document.createElement('a');
      a.href = data.GenerateReport.base64Pdf;
      a.download = `report_${new Date(Start).toISOString().split('T')[0]}_${new Date(End ?? new Date()).toISOString().split('T')[0]}.pdf`;

      a.click();
    }
  }, [Start, End, data?.GenerateReport?.base64Pdf]);

  return (
    <div className="space-y-4 print:w-[210mm]">
      <ReportDescription />
      <ReportFlexOccupancy />
      <ReportFixOccupancy />
      <ReportRoomSizeOccupancy />
      <ReportRoomSize />
      <ReportClimateValues />
      <div className="grid grid-cols-2 print:block space-x-4">
        <div className="col-span-1">
          <ReportRoomLabelOccupancy />
        </div>
        <div className="col-span-1">
          <ReportDeskLabelOccupancy />
        </div>
      </div>
      <div className="flex justify-end">
        <Tooltip
          content={
            <StyledButton
              isLoading={fetching}
              onClick={() =>
                generateReport(
                  {
                    UserInput: {
                      start: Start,
                      end: End,
                      building: Building ?? '',
                    },
                  },
                  hasuraHeader(HasuraPermissions.VIEW_REPORTS),
                )
              }
            >
              <HiOutlineArrowDownTray className="size-5" />
            </StyledButton>
          }
        >
          <FormattedMessage id="Download PDF" />
        </Tooltip>
      </div>
    </div>
  );
}
