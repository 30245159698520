import { scaleOrdinal } from '@visx/scale';
import Legend from 'generic/components/Chart/Legend';
import PieChart from 'generic/components/PieChart';
import { useFixFlexOccupancyHistoryQuery } from 'graphql/types';
import { RAINBOW } from 'mda2-frontend/src/constants';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import ReportingPage from '../ReportingPage';
import BarChart from './components/BarChart';
import {
  AVERAGE_FLOOR,
  type Data,
  RoomTypes,
} from './components/BarChart/BarChart';

export default function ReportFixOccupancy() {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const { Start, End, Building } = useAnalyticsFilter();
  const [{ data, fetching }] = useFixFlexOccupancyHistoryQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    variables: {
      Start,
      End,
      Building,
    },
  });

  const occupancyData = data?.f_history_fixflexfloor_occupancy_custom.sort(
    (a, b) => a.Floor - b.Floor,
  );

  const roomTypeOccupancyData = occupancyData?.flatMap((d) => [
    {
      [d.Floor]: d.AvgDailyFixOccupancy,
      roomType: RoomTypes.FIX,
    },
    {
      [d.Floor]: d.AvgDailyFlexOccupancy,
      roomType: RoomTypes.FLEX,
    },
    {
      [AVERAGE_FLOOR as number]: d.AvgDailyFixOccupancyAll,
      roomType: RoomTypes.FIX,
    },
    {
      [AVERAGE_FLOOR as number]: d.AvgDailyFlexOccupancyAll,
      roomType: RoomTypes.FLEX,
    },
  ]);

  const chartData: Data[] = [
    roomTypeOccupancyData
      ?.filter((d) => d.roomType === RoomTypes.FIX)
      .reduce((r, c) => Object.assign(r, c), {} as Data) ?? {
      roomType: RoomTypes.FIX,
    },
    roomTypeOccupancyData
      ?.filter((d) => d.roomType === RoomTypes.FLEX)
      .reduce((r, c) => Object.assign(r, c), {} as Data) ?? {
      roomType: RoomTypes.FLEX,
    },
  ];

  return (
    <ReportingPage
      loading={fetching}
      reportTitle="Desks fix"
      kpi={intl.formatMessage({
        id: 'Is it possible to transform fix to flex desks?',
      })}
      cardData={[
        {
          title: `${intl.formatMessage({
            id: 'Average flex daily',
          })} ${occupancyData?.[0]?.AvgDailyFlexOccupancyAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.AvgDailyFlexOccupancy}% (${intl.formatMessage(
              {
                id: '{number} Floor',
              },
              { number: o.Floor },
            )})`,
            key: o.Floor.toString(),
          })),
        },
        {
          title: `${intl.formatMessage({
            id: 'Average fix daily',
          })} ${occupancyData?.[0]?.AvgDailyFixOccupancyAll}%`,
          values: (occupancyData ?? [])?.map((o) => ({
            value: `${o.AvgDailyFixOccupancy}% (${intl.formatMessage(
              {
                id: '{number} Floor',
              },
              { number: o.Floor },
            )})`,
            key: o.Floor.toString(),
          })),
        },
      ]}
    >
      <div className="flex justify-between">
        <div className="flex flex-col">
          <p>
            {intl.formatMessage({
              id: 'Distribution fix desks',
            })}
          </p>
          <div className="size-48">
            <PieChart
              tooltipEnabled={false}
              showLabel
              colors={RAINBOW}
              dataFormat={(d) => d.value.toString()}
              data={
                occupancyData?.map((d) => ({
                  id: String(d.Floor),
                  value: d.NumberOfFixDesks,
                })) ?? []
              }
            />
          </div>
          <Legend
            scaleType="ordinal"
            scale={scaleOrdinal({
              domain: occupancyData?.map((d) => d.Floor),
              range: RAINBOW,
            })}
            labelFormat={(d) =>
              intl.formatMessage(
                {
                  id: '{number} Floor',
                },
                { number: d },
              )
            }
          />

          <p>
            {intl.formatMessage({
              id: 'Distribution flex desks',
            })}
          </p>
          <div className="size-48">
            <PieChart
              tooltipEnabled={false}
              showLabel
              colors={RAINBOW}
              dataFormat={(d) => d.value.toString()}
              data={
                occupancyData?.map((d) => ({
                  id: String(d.Floor),
                  value: d.NumberOfFlexDesks,
                })) ?? []
              }
            />
          </div>
          <Legend
            scaleType="ordinal"
            scale={scaleOrdinal({
              domain: occupancyData?.map((d) => d.Floor),
              range: RAINBOW,
            })}
            labelFormat={(d) =>
              intl.formatMessage(
                {
                  id: '{number} Floor',
                },
                { number: d },
              )
            }
          />
        </div>
        <div className="flex flex-col">
          <p>
            {intl.formatMessage({
              id: 'Average occupancy of fix and flex desks',
            })}
          </p>
          <div className="flex space-x-4">
            <div className="h-96 w-full">
              <BarChart data={chartData} />
            </div>
          </div>
        </div>
      </div>
    </ReportingPage>
  );
}
