import Accordion from 'generic/components/Accordion';
import Card from 'generic/components/Card';
import LoadingSpinner from 'generic/components/LoadingSpinner';
import Subtitle from 'generic/components/Subtitle';
import Title from 'generic/components/Title';
import type { ReactNode } from 'react';
import { type IntlMessageKeys, useIntl } from 'translations/Intl';

interface ReportingPageProps {
  reportTitle: IntlMessageKeys;
  kpi?: string;
  children: ReactNode;
  loading?: boolean;
  cardData?: {
    title: string;
    values: { value: string; key: string; className?: string }[];
  }[];
}

export default function ReportingPage({
  reportTitle,
  loading = false,
  kpi,
  cardData,
  children,
}: ReportingPageProps) {
  const intl = useIntl();

  return (
    <Card className="relative print:border-hidden print:md:p-0">
      <LoadingSpinner loading={loading} />
      <Accordion title={intl.formatMessage({ id: reportTitle })}>
        <div className="print:break-after-page">
          <Title value={reportTitle} className="hidden print:block" />
          {kpi && (
            <Subtitle
              value={`${intl.formatMessage({ id: 'KPI' })}:
          ${kpi}`}
            />
          )}
          <div className="flex print:flex-col print:space-y-4 space-x-4 print:space-x-0">
            {cardData && (
              <div className="flex flex-col print:flex-row print:justify-between print:space-x-2 space-y-2 print:space-y-0">
                {cardData.map((card) => (
                  <Card key={card.title} className="bg-primary-100 md:!p-2">
                    <p className="text-lg font-bold">{card.title}</p>
                    {card.values.map((o) => (
                      <p className={o.className} key={o.key}>
                        {o.value}
                      </p>
                    ))}
                  </Card>
                ))}
              </div>
            )}
            {children}
          </div>
        </div>
      </Accordion>
    </Card>
  );
}
