import {
  Axis as DefaultAxis,
  type AxisScale,
  type SharedAxisProps,
} from '@visx/axis';
import { Axis as XYAxis } from '@visx/xychart';
import { Themes } from 'mda2-frontend/src/common/types';
import useStore from 'mda2-frontend/src/model/store';
import { useMemo } from 'react';

interface AxisProps extends Omit<SharedAxisProps<AxisScale>, 'scale'> {
  scale?: AxisScale;
  tickProps?: {
    fill?: string;
    fontSize?: number;
    angle?: number;
    textAnchor?: 'start' | 'middle' | 'end';
    dx?: string;
    dy?: string;
  };
  lowLevelChart?: boolean;
  orientation: 'bottom' | 'left' | 'right';
  labelOffset?: number;
}

export default function Axis(props: AxisProps): JSX.Element {
  const { lowLevelChart, orientation, tickProps, labelOffset } = props;

  const theme = useStore((state) => state.userSettings.theme);
  const color = useMemo(
    () => (theme.color === Themes.LIGHT ? 'black' : 'white'),
    [theme],
  );

  const labelOffsetFct = () => {
    if (orientation === 'left') return 40;
    if (orientation === 'right') return 0;
    return undefined;
  };

  const dx = () => {
    if (orientation === 'left') return '-0.3em';
    if (orientation === 'right') return '0.3em';
    return undefined;
  };

  const textAnchor = () => {
    if (orientation === 'left') return 'end';
    if (orientation === 'right') return 'start';
    return 'middle';
  };

  const AxisType: React.ElementType = lowLevelChart ? DefaultAxis : XYAxis;

  return (
    <AxisType
      stroke={color}
      labelClassName={`fill-current text-[${color}] text-xxs`}
      labelOffset={labelOffset || labelOffsetFct()}
      tickLabelProps={() => ({
        fill: color,
        fontSize: 10,
        textAnchor: textAnchor(),
        dx: dx(),
        dy: '0.33em',
        ...tickProps,
      })}
      {...props}
      orientation={orientation}
    />
  );
}
