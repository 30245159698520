import { useState } from 'react';
import Button from '../Form/Button';
import Modal from '../Modal';

interface ImageModalProps {
  large?: boolean;
  children: React.ReactNode;
}

export default function ImageModal({ large, children }: ImageModalProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>{children}</Button>
      <Modal
        wrapperClassName={
          large
            ? 'sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg'
            : undefined
        }
        open={open}
        setShowModal={setOpen}
      >
        <div className="">{children}</div>
      </Modal>
    </>
  );
}
