import Subtitle from 'generic/components/Subtitle/Subtitle';
import { FormattedMessage, useIntl } from 'translations/Intl';

import {
  Action,
  type 
  RoomFeatures,
  type 
  RoomToRemove,
} from 'mda2-frontend/src/common/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import { useDeleteRoomMutation } from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

interface RemoveRoomModalProps {
  toRemove?: RoomToRemove | null;
  setToRemove: (room: RoomToRemove | null) => void;
  setIsSaving: (arg: boolean) => void;
  setSelectedRoom: (selectedRoom?: RoomFeatures) => void;
}

export default function RemoveRoomModal({
  toRemove,
  setToRemove,
  setIsSaving,
  setSelectedRoom,
}: RemoveRoomModalProps): JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [confirmDelete, setConfirmDelete] = useState('');

  const [{ fetching }, removeRoom] = useDeleteRoomMutation();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={!!toRemove}
      setShowModal={() => setToRemove(null)}
      footer={
        <ModalFooter
          disabled={confirmDelete !== toRemove?.Name}
          action={Action.REMOVE}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          onProceed={() => {
            if (confirmDelete === toRemove?.Name) {
              // Only need to set it to saving here. It will be disabled in FloorRoomMap, when new data is fetched by URQL
              setIsSaving(true);
              removeRoom(
                {
                  RoomId: toRemove.Id,
                },
                hasuraHeader(HasuraPermissions.DELETE_ROOM),
              )
                .then((data) => {
                  toast(data);
                })
                .finally(() => {
                  setToRemove(null);
                  setConfirmDelete('');
                  setIsSaving(false);
                  setSelectedRoom(undefined);
                });
            }
          }}
          onCancel={() => {
            setToRemove(null);
            setConfirmDelete('');
          }}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({ id: 'Are you sure to remove this room?' })}
        className="text-base pb-2"
      />

      <p className="pb-2">
        <FormattedMessage
          id="Deleting the room will delete all associated desks. Enter the room name {name} in order to delete it"
          values={{
            name: <b>{toRemove?.Name}</b>,
          }}
        />
      </p>

      {toRemove?.IsPrivate && (
        <>
          <p className="pb-2 font-bold">
            <FormattedMessage id="This room is private." />
          </p>
          <p className="pb-2 font-bold">
            <FormattedMessage id="The entire history of the associated beacons will be irrevocably deleted if they are not in any private room anymore." />
          </p>
        </>
      )}

      <Input
        type="text"
        value={confirmDelete}
        icon={<HiOutlineTrash className="size-5 dark:text-neutral-200" />}
        id="remove-room"
        data-test-id="remove-room"
        onChangeValue={(input) => setConfirmDelete(input)}
        placeholder={toRemove?.Name}
        required
      />
    </Modal>
  );
}
