import type { DeskInUseFeatureType } from 'generic/layers/DeskInUseLayer';
import type { RoomBeaconFeatureType } from 'generic/layers/RoomBeaconLayer';
import type { RoomInUseFeatureType } from 'generic/layers/RoomInUseLayer';
import useDeviceDetect from 'utils/useDeviceDetect';

import { Tooltip } from '@visx/tooltip';
import Card from 'mda2-frontend/src/generic/components/Card';
import Popup from 'mda2-frontend/src/generic/components/Popup';
import type { Coordinate } from 'ol/coordinate';
import type OLMap from 'ol/Map';

import OccupancyMapPopupBody from '../OccupancyMapPopupBody';
import type { OccupancyMapFeature } from '../OccupancyMapPopupBody/OccupancyMapPopupBody';

interface OccupancyMapPopupProps {
  map: OLMap;
  hoveredFeature: OccupancyMapFeature;
  hoveredCoords: Coordinate | null;
  isTouchedEvt: boolean;
  setHoveredFeature: (feat: OccupancyMapFeature | null) => void;
  openReservationPopup: (
    feat: RoomBeaconFeatureType | DeskInUseFeatureType | RoomInUseFeatureType,
  ) => void;
}

export default function OccupancyMapPopup({
  map,
  hoveredFeature,
  hoveredCoords,
  isTouchedEvt,
  setHoveredFeature,
  openReservationPopup,
}: OccupancyMapPopupProps) {
  const { isMobile } = useDeviceDetect();

  return (
    <Popup
      map={map}
      feature={hoveredFeature}
      popupCoordinate={hoveredCoords}
      offset={isTouchedEvt ? [-20, -20] : undefined}
      renderTooltip={({ top, left, className, 'data-test-id': dataTestId }) => {
        if (isMobile) {
          return (
            <Card className="absolute bottom-0 right-0 left-0 z-20">
              <OccupancyMapPopupBody
                hoveredFeature={hoveredFeature}
                isTouchedEvt={isTouchedEvt}
                setHoveredFeature={setHoveredFeature}
                openReservationPopup={openReservationPopup}
              />
            </Card>
          );
        }

        // On touch there is a button -> it is a Popup and not a Tooltip, so we can't use Tooltip component
        if (isTouchedEvt) {
          return (
            <div
              style={{ top, left }}
              className={className}
              data-test-id={dataTestId}
            >
              <OccupancyMapPopupBody
                hoveredFeature={hoveredFeature}
                isTouchedEvt={isTouchedEvt}
                setHoveredFeature={setHoveredFeature}
                openReservationPopup={openReservationPopup}
              />
            </div>
          );
        }

        return (
          <Tooltip
            top={top}
            left={left}
            data-test-id={dataTestId}
            className={`${className} max-w-[500px]`}
          >
            <OccupancyMapPopupBody
              hoveredFeature={hoveredFeature}
              isTouchedEvt={isTouchedEvt}
              setHoveredFeature={setHoveredFeature}
              openReservationPopup={openReservationPopup}
            />
          </Tooltip>
        );
      }}
    />
  );
}
