import { LastWill } from 'common/types';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import LoadingSpinner from 'generic/components/LoadingSpinner';
import Tooltip from 'generic/components/Tooltip';
import {
  useDeviceEnrollmentStatusQuery,
  useSetupMqttDeviceMutation,
} from 'graphql/types';
import useStore from 'model/store';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';
import parseMda3Topic from 'utils/parseMda3Topic';

interface EnrollDevicesProps {
  uuid: string;
}

const MqttListener = lazy(() => import('generic/components/MqttListener'));

const getBeaconId = (topic: string) => topic.replace('cmd/', '').split('/')[3];

function TopicCell({
  topic,
  setupDevice,
  fetching,
  selectedBeacons,
  setDisabledBeacons,
  version,
}: {
  topic: string;
  setupDevice: (selectedDevices: string[]) => void;
  fetching: boolean;
  version: string;
  selectedBeacons: string[];
  setDisabledBeacons: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const [{ data: enrollmentStatus, fetching: loadingEnrollmentStatus }] =
    useDeviceEnrollmentStatusQuery({
      variables: { UserInput: { macAddress: getBeaconId(topic) ?? '' } },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        [hasuraHeader],
      ),
      requestPolicy: 'cache-and-network',
    });

  const isDeviceEnrolled =
    enrollmentStatus?.GetMqttDeviceEnrollmentStatus.enrolled;
  const sameOrganization =
    enrollmentStatus?.GetMqttDeviceEnrollmentStatus.sameOrganization;

  const enrollmentConfiguration = useStore(
    (state) => state.enrollmentConfiguration,
  );
  const operatorNumber = Number.parseInt(topic.split('/')[2] ?? '0', 10);

  // In order to quickly distinguish between the operators (the person doing the enrollment)
  // we choose different colors per operator
  const getOperatorColor = () => {
    switch (operatorNumber) {
      case 1:
        return 'bg-primary-200 text-primary-500';

      case 2:
        return 'bg-green-200 text-green-500';

      case 3:
        return 'bg-blue-200 text-blue-500';

      case 4:
        return 'bg-yellow-200 text-yellow-500';

      default:
        break;
    }

    return 'bg-neutral-200 text-white';
  };

  const enrollmentMessage = useMemo(() => {
    if (isDeviceEnrolled) {
      if (sameOrganization) {
        return 'Already enrolled in same organization';
      }
      return 'Already enrolled in other organization';
    }
    if (enrollmentConfiguration) {
      return 'Send configuration';
    }
    return 'Set enrollment configuration first';
  }, [isDeviceEnrolled, sameOrganization, enrollmentConfiguration]);

  const disabled =
    !enrollmentConfiguration ||
    !enrollmentConfiguration.configuration?.sensorPolicyId ||
    isDeviceEnrolled ||
    // Wait for the enrollment status to load
    loadingEnrollmentStatus;

  useEffect(() => {
    if (disabled) {
      setDisabledBeacons((existing) =>
        existing.includes(topic) ? existing : [...existing, topic],
      );
    } else {
      setDisabledBeacons((existing) =>
        existing.includes(topic)
          ? existing.filter((e) => !e.includes(topic))
          : existing,
      );
    }
  }, [disabled, setDisabledBeacons, topic]);

  return (
    <div className="flex space-x-2 items-center">
      <Tooltip
        content={
          <p>
            <StyledButton
              disabled={disabled}
              data-test-id="configure-button"
              className={`${
                isDeviceEnrolled
                  ? '!bg-red-100 disabled:text-red-400 disabled:hover:text-red-400 hover:bg-red-300 disabled:hover:bg-red-100 dark:bg-red-100 dark:hover:bg-red-300 dark:disabled:hover:bg-red-100'
                  : '!bg-green-100 disabled:text-green-400 disabled:hover:text-green-400 hover:bg-green-300 disabled:hover:bg-green-100 dark:bg-green-100 dark:hover:bg-green-300 dark:disabled:hover:bg-green-100'
              } `}
              onClick={() =>
                setupDevice([
                  `cmd/unconfigured/${parseMda3Topic(topic)}/configure`,
                ])
              }
            >
              {(fetching &&
                selectedBeacons.find((beacon) =>
                  beacon.includes(getBeaconId(topic) ?? ''),
                )) ||
              loadingEnrollmentStatus ? (
                <FaCircleNotch className="animate-spin size-5 text-neutral-200" />
              ) : (
                <HiOutlineWrenchScrewdriver
                  className={`size-5 ${
                    isDeviceEnrolled ? 'text-red-500' : 'text-green-500'
                  }`}
                />
              )}
            </StyledButton>
          </p>
        }
      >
        <FormattedMessage id={enrollmentMessage} />
      </Tooltip>
      <div className="flex items-center justify-between w-full space-x-2">
        <div className="flex flex-col">
          {userRoles?.includes(HasuraPermissions.VIEW_STATUS) &&
          sameOrganization ? (
            <Link
              className="hover:text-primary-500 text-sm"
              to={`/status?beacon=${getBeaconId(topic)}`}
              data-test-id={`button-id-${getBeaconId(topic)}`}
            >
              {getBeaconId(topic)}
            </Link>
          ) : (
            <div
              className="text-sm"
              data-test-id={`button-id-${getBeaconId(topic)}`}
            >
              {getBeaconId(topic)}
            </div>
          )}
          <div className="flex text-xs">
            <FormattedMessage id="Version" />:{' '}
            <div
              className={`${
                version === '1.0.1' ? 'text-red-500' : ''
              } flex pl-1`}
            >
              {version}{' '}
              {version === '1.0.1' && (
                <Tooltip>
                  <FormattedMessage id="Version problem 1.0.1" />
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        <Tooltip
          content={
            <div
              className={`${getOperatorColor()} size-6 focus:outline-none rounded-full flex items-center justify-center select-none capitalize`}
            >
              {operatorNumber}
            </div>
          }
        >
          <FormattedMessage id="Operator" /> {operatorNumber}
        </Tooltip>
      </div>
    </div>
  );
}

export default function EnrollDevices({ uuid }: EnrollDevicesProps) {
  const enrollmentConfiguration = useStore(
    (state) => state.enrollmentConfiguration,
  );
  const [{ fetching }, setupMqttDevice] = useSetupMqttDeviceMutation();
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [selectedBeacons, setSelectedBeacons] = useState<string[]>([]);
  const [disabledBeacons, setDisabledBeacons] = useState<string[]>([]);
  const [hiddenBeacons, setHiddenBeacons] = useState<string[]>([]);

  // selectedDevices is an array like ["cmd/unconfigured/<orga>/<operator>/<mac>/configure"]
  const setupDevice = (
    selectedDevices: string[],
    resetMessages: () => void,
  ) => {
    if (enrollmentConfiguration?.configuration) {
      const beacons = selectedDevices.map(
        (device) => getBeaconId(device) ?? '',
      );
      setSelectedBeacons(beacons);

      const { mqttPassword, mqttUser, onPremises, sensorPolicyId } =
        enrollmentConfiguration.configuration;

      setupMqttDevice(
        {
          Input: {
            topics: selectedDevices,
            ...enrollmentConfiguration.configuration,
            mqttPassword: onPremises ? mqttPassword : undefined,
            mqttUser: onPremises ? mqttUser : undefined,
          },
          Beacons: beacons.map((device) => ({
            Beacon: device,
          })),
          BeaconNames: beacons,
          SensorPolicyId: sensorPolicyId ?? 0,
          OnPremises: onPremises,
        },
        hasuraHeader(HasuraPermissions.VIEW_ADMIN, ['GroupMembers']),
      ).then((data) => {
        if (data.error) {
          toast(data, {
            message: {
              type: 'error',
              content: intl.formatMessage({
                id: 'Error configuring devices',
              }),
            },
          });
        } else {
          toast(data, {
            message: {
              type: 'success',
              content: intl.formatMessage({
                id: 'Configured beacons',
              }),
            },
          });
          setHiddenBeacons(beacons);
          setSelectedBeacons((existing) =>
            existing.filter((e) => !selectedBeacons.includes(e)),
          );
          resetMessages();
        }
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hiddenBeacons.length > 0) {
        setHiddenBeacons([]);
      }
    }, 10_000); // Reset the list of hidden beacons after 10s so it will turn up again if something went wrong

    return () => clearTimeout(timer);
  }, [hiddenBeacons]);

  return (
    <div className="flex flex-col max-h-96 overflow-y-auto">
      <Suspense fallback={<LoadingSpinner loading />}>
        <MqttListener
          hideConnectionDetails
          topics={[
            // Use this topic to listen to the "offline" message in order to hide the beacon
            `unconfigured/${uuid}/+/+`,
            // Subscribe to the unconfigured version in order to show it in the topic
            `unconfigured/${uuid}/+/+/info/version/+`,
          ]}
          ignoreTimestamp
          columns={['topic']}
          filterMessages={({ message, topic }) => {
            if (message === LastWill.OFFLINE) {
              setHiddenBeacons((existing) => [
                ...existing,
                getBeaconId(topic) ?? '',
              ]);
            }

            return (
              (hiddenBeacons.length
                ? hiddenBeacons.every((beaconId) => !topic.includes(beaconId))
                : true) &&
              // Remove any devices that go offline
              message !== LastWill.OFFLINE &&
              // Do not show the version topic
              topic.split('/').length === 7
            );
          }}
          enableRowSelection
          renderTopicCell={({ row }, resetMessages) => (
            <TopicCell
              version={row.original.message}
              topic={row.original.topic}
              fetching={fetching}
              setupDevice={(selected) => setupDevice(selected, resetMessages)}
              selectedBeacons={selectedBeacons}
              setDisabledBeacons={setDisabledBeacons}
            />
          )}
          disabledItems={disabledBeacons}
          renderSelectedAction={(selectedRows, resetMessages) => [
            {
              onClick: () =>
                setupDevice(
                  selectedRows.map(
                    (b) =>
                      `cmd/unconfigured/${parseMda3Topic(
                        b.original.topic,
                      )}/configure`,
                  ),
                  resetMessages,
                ),
              text: intl.formatMessage({
                id: !enrollmentConfiguration
                  ? 'Set enrollment configuration first'
                  : 'Configure selected devices',
              }),
              icon: <HiOutlineWrenchScrewdriver className="size-5" />,
              permission: HasuraPermissions.VIEW_ADMIN,
            },
          ]}
        />
      </Suspense>
    </div>
  );
}
