import { Colors } from 'mda2-frontend/src/common/types';

const getPrimaryColor = (color: number): string =>
  getComputedStyle(document.body)
    .getPropertyValue(`--color-primary-${color}`)
    .trim();

export const primaryColorToRGB = (
  primaryColor: 0 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900,
  opacity?: number,
): string => {
  const [r, g, b] = getPrimaryColor(primaryColor).split(' ');
  return opacity
    ? `rgba(${+(r ?? 0)},${+(g ?? 0)},${+(b ?? 0)}, ${opacity})`
    : `rgb(${+(r ?? 0)},${+(g ?? 0)},${+(b ?? 0)})`;
};

const getColor = (color: keyof typeof Colors, opacity = '1'): string =>
  `rgba(${Colors[color]},${opacity})`;

export default getColor;
