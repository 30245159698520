import { useIntl } from 'translations/Intl';

import { Action } from 'mda2-frontend/src/common/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import {
  type 
  BuildingQuery,
  useInsertBuildingMutation,
  useUpdateBuildingMutation,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useEffect, useState } from 'react';
import { HiOutlineBuildingOffice } from 'react-icons/hi2';
import { generatePath, useNavigate } from 'react-router-dom';

interface AddBuildingModalProps {
  isAdding: boolean;
  setIsAdding: (isAdding: boolean) => void;
  building?: BuildingQuery['Buildings'][number];
}

export default function AddBuildingModal({
  isAdding,
  setIsAdding,
  building,
}: AddBuildingModalProps): JSX.Element {
  const intl = useIntl();
  const [name, setName] = useState(building?.Name ?? '');
  const navigate = useNavigate();
  const [, addBuilding] = useInsertBuildingMutation();
  const [, changeBuilding] = useUpdateBuildingMutation();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  useEffect(() => {
    if (building?.Name) {
      setName(building.Name);
    }
  }, [building, building?.Name]);

  return (
    <Modal
      title={intl.formatMessage({
        id: building ? 'Edit building' : 'Add building',
      })}
      action={building ? Action.UPDATE : Action.ADD}
      open={isAdding}
      setShowModal={setIsAdding}
      footer={
        <ModalFooter
          disabled={name === ''}
          action={building ? Action.UPDATE : Action.ADD}
          onProceed={() => {
            if (building) {
              changeBuilding(
                {
                  Id: building.Id,
                  NewName: name,
                },
                hasuraHeader(HasuraPermissions.WRITE_BUILDING),
              ).then((data) => {
                if (!data.error) {
                  navigate(
                    generatePath('/admin/:buildingName', {
                      buildingName: encodeURIComponent(name),
                    }),
                    {
                      replace: true,
                    },
                  );
                  setIsAdding(false);
                  toast(data);
                }
              });
            } else {
              addBuilding(
                { Name: name },
                hasuraHeader(HasuraPermissions.WRITE_BUILDING),
              ).then((data) => {
                setIsAdding(false);
                setName('');
                toast(data);
              });
            }
          }}
          onCancel={() => setIsAdding(false)}
        />
      }
    >
      <div className="space-y-3">
        <Input
          type="text"
          label={intl.formatMessage({
            id: 'Building name',
          })}
          icon={
            <HiOutlineBuildingOffice className="size-5 dark:text-neutral-200" />
          }
          placeholder={intl.formatMessage({
            id: 'Name',
          })}
          value={name}
          id="Building name"
          data-test-id="building-name-input"
          onChangeValue={(e) => setName(e)}
          required
        />
      </div>
    </Modal>
  );
}
