import type { Colors } from 'mda2-frontend/src/common/types';
import getColor from 'mda2-frontend/src/utils/getColor';
import type { Feature } from 'ol';
import type { Geometry } from 'ol/geom';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import AdminLayer, { type AdminLayerOptions, type Options } from './AdminLayer';

type StyleFunction<T> = (
  feat: T,
  resolution: number,
  color: keyof typeof Colors,
  selected: T,
  hidden?: string | null,
  highlighted?: T | null,
  showLabel?: boolean,
) => Style | Style[] | null;

const styleFunction = <T>(
  feat: T,
  _: number,
  color: keyof typeof Colors,
  selected: T,
) => [
  new Style({
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: getColor(color, feat === selected ? '.9' : '.5'),
      }),
      stroke: new Stroke({
        color: getColor(color, '.5'),
        width: 3,
      }),
    }),
  }),
];

interface AdminPointOptions<T> extends Options {
  styleFunction?: StyleFunction<T>;
}

interface AdminPointLayerOptions<T> extends AdminLayerOptions {
  styleFunction?: StyleFunction<T>;
}

class AdminPointLayer<T extends Feature<Geometry> = any> extends AdminLayer {
  constructor(options: AdminPointOptions<T>) {
    super({
      styleFunction,
      ...options,
    } as AdminPointLayerOptions<T>);
  }
}

export default AdminPointLayer;
