import type { DeskFeatures, DrawingDeskFeatures } from 'common/types';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import type { Coordinate } from 'ol/coordinate';
import Event from 'ol/events/Event';

export enum DeskMoveEventType {
  START = 'deskmovestart',
  END = 'deskmoveend',
}

export default class DeskMoveEvent extends Event {
  feature: DrawingDeskFeatures | DeskFeatures;

  mapBrowserEvent: MapBrowserEvent<UIEvent>;

  deskOffset?: Coordinate;

  constructor(
    type: DeskMoveEventType,
    feature: DrawingDeskFeatures | DeskFeatures,
    mapBrowserPointerEvent: MapBrowserEvent<UIEvent>,
    deskOffset?: Coordinate,
  ) {
    super(type);
    this.feature = feature;
    this.mapBrowserEvent = mapBrowserPointerEvent;
    this.deskOffset = deskOffset;
  }
}
