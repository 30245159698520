export default function SliderRail() {
  return (
    <>
      <div
        className="absolute w-full h-20 rounded-md cursor-pointer -translate-y-1/2"
      />
      <div className="absolute w-full h-1 rounded-md bg-red-500 -translate-y-1/2" />
    </>
  );
}
