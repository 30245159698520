import Accordion from 'generic/components/Accordion';
import Tooltip from 'generic/components/Tooltip';
import {
  FormattedMessage,
  useIntl,
  type IntlMessageKeys,
} from 'translations/Intl';

import Card from 'mda2-frontend/src/generic/components/Card';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import Transition from 'mda2-frontend/src/generic/components/Transition';
import {
  useSensorsStatusQuery,
  type SensorsStatusQuery,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import { formattedDistance } from 'mda2-frontend/src/utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import renderSensorIcon from 'mda2-frontend/src/utils/renderSensorIcon';
import { useMemo, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import {
  HiOutlineCheckCircle,
  HiOutlineChevronRight,
  HiOutlineTrash,
  HiOutlineXCircle,
  HiShieldExclamation,
} from 'react-icons/hi2';

import RemoveSensorModal from '../RemoveSensorModal';
import SensorChart from '../SensorChart';

interface SensorsStatusProps {
  beaconName: string;
}

export default function SensorsStatus({
  beaconName,
}: SensorsStatusProps): JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const [visibleSensors, setVisibleSensors] = useState<number[]>([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState<number | null>(null);
  const [sensorToRemove, setSensorToRemove] = useState<
    SensorsStatusQuery['MqttBeacons'][number]['Sensors'][number] | null
  >(null);

  const [{ data: sensors, fetching: loadingSensors }, reexecuteQuery] =
    useSensorsStatusQuery({
      variables: {
        BeaconName: beaconName,
      },
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.READ,
          ),
        [hasuraHeader, userRoles],
      ),
    });
  usePolling(loadingSensors, reexecuteQuery, 1000 * 30);

  return (
    <>
      {loadingSensors && !sensors && (
        <div className="flex max-width-screen gap-4 py-4">
          {[1, 2].map((idx) => (
            <div
              key={`sensor-loading-${idx}`}
              className="w-1/3 animate-pulse flex"
            >
              <div className="w-full bg-neutral-200 h-24 rounded-md" />
            </div>
          ))}
        </div>
      )}
      {(sensors?.MqttBeacons[0]?.Sensors.length ?? 0) > 0 && (
        <div className="flex flex-col max-width-screen">
          <Accordion initialStateOpen title={<FormattedMessage id="Sensors" />}>
            <div
              data-test-id={`sensor-card-wrapper-${beaconName}`}
              className="w-full grid grid-cols-2 gap-4"
            >
              {sensors?.MqttBeacons[0]?.Sensors.sort(
                (a, b) =>
                  a.SensorType.Name.localeCompare(b.SensorType.Name) ||
                  a.Index - b.Index,
              ).map((sensor) => {
                const deskRelations = sensor.Desk?.Name;
                const roomRelations = [
                  ...new Set(sensor.RoomSensors.map((rS) => rS.Room.Name)),
                ];
                const floorRelations = [
                  sensors.MqttBeacons[0] &&
                    intl.formatMessage(
                      { id: 'Building Floor' },
                      {
                        number: sensors.MqttBeacons[0].Floor?.Number,
                        building: sensors.MqttBeacons[0].Floor?.Building.Name,
                      },
                    ),
                ];
                const isActive = deskRelations || roomRelations.length > 0;
                const sensorHistoryVisible = visibleSensors.includes(sensor.Id);
                return (
                  <Card
                    key={sensor.Id}
                    data-test-id={`sensor-card-${sensor.SensorType.Name}`}
                    className="flex flex-wrap w-full px-2 py-2 md:p-2 col-span-2 md:col-span-1"
                  >
                    <div className="flex flex-col flex-wrap w-full">
                      <div className="flex justify-between">
                        <div className="flex gap-2 items-center flex-wrap">
                          <Tooltip
                            content={
                              <div className="relative p-2 bg-primary-500 text-white rounded">
                                {renderSensorIcon(
                                  sensor.SensorType.Name,
                                  'size-5 text-white',
                                )}
                              </div>
                            }
                          >
                            <p>{sensor.SensorType.Name}</p>
                          </Tooltip>
                          <div>
                            <span
                              data-test-id={`${sensor.SensorType.Name}-${sensor.Index}`}
                            >
                              <FormattedMessage
                                id={sensor.SensorType.Name as IntlMessageKeys}
                              />
                            </span>{' '}
                            {sensor.Index > 0 && sensor.Index}
                          </div>
                          <div className="flex">
                            {!isActive ? (
                              <Tooltip
                                content={
                                  <p>
                                    <HiOutlineXCircle
                                      data-test-id="sensor-inactive"
                                      className="size-4 text-neutral-500"
                                    />
                                  </p>
                                }
                              >
                                <FormattedMessage id="Not active" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                content={
                                  <p>
                                    <HiOutlineCheckCircle
                                      data-test-id="sensor-active"
                                      className="size-4 text-green-500"
                                    />
                                  </p>
                                }
                              >
                                <FormattedMessage id="Active" />
                              </Tooltip>
                            )}
                            {sensor.IsPrivate && (
                              <Tooltip
                                content={
                                  <p className="pl-1">
                                    <HiShieldExclamation
                                      data-test-id="sensor-private"
                                      className="size-4 text-neutral-600"
                                    />
                                  </p>
                                }
                              >
                                <FormattedMessage id="Private sensor" />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                        <PrivateWrapper
                          roleRequired={HasuraPermissions.DELETE_SENSOR}
                        >
                          <Button
                            id={`delete-floor-${Number}`}
                            className="size-8 bg-primary-200 text-primary-500 hover:bg-primary-400 hover:text-white focus:outline-none rounded-full flex items-center justify-center"
                            onClick={() => {
                              setSensorToRemove(sensor);
                            }}
                            title={intl.formatMessage({
                              id: 'Remove',
                            })}
                          >
                            {isDeleteLoading &&
                            isDeleteLoading === sensor.Id ? (
                              <FaCircleNotch className="size-5 animate-spin text-primary-500" />
                            ) : (
                              <HiOutlineTrash className="size-5" />
                            )}
                          </Button>
                        </PrivateWrapper>
                      </div>

                      <div className="flex flex-col flex-wrap text-sm gap-1 pt-1">
                        <Transition
                          show={
                            !sensor.IsPrivate ||
                            // Allow "God" to be all seeing
                            userRoles?.includes(HasuraPermissions.READ_ALL)
                          }
                        >
                          <div
                            className="flex flex-wrap"
                            data-test-id={`value-${sensor.SensorType.Name}-${sensor.Index}`}
                          >
                            <FormattedMessage id="Value" />: {sensor.Value}{' '}
                            {sensor.SensorType.Unit} (
                            {formattedDistance(new Date(sensor.UpdatedAt))})
                          </div>
                        </Transition>
                        {deskRelations && (
                          <div>
                            <FormattedMessage id="Desk" />: {deskRelations}
                          </div>
                        )}
                        {roomRelations.length > 0 && (
                          <div className="flex flex-wrap">
                            <FormattedMessage
                              id={roomRelations.length > 1 ? 'Rooms' : 'Room'}
                            />
                            :{' '}
                            {roomRelations
                              .sort((a, b) => a.localeCompare(b))
                              .join(', ')}
                          </div>
                        )}
                        {floorRelations.length > 0 && (
                          <div className="flex flex-wrap">
                            <FormattedMessage
                              id={
                                floorRelations.length > 1 ? 'floors' : 'Floor'
                              }
                            />
                            : {floorRelations.join(', ')}
                          </div>
                        )}
                        <Transition
                          show={
                            !sensor.IsPrivate ||
                            // Allow "God" to be all seeing
                            userRoles?.includes(HasuraPermissions.READ_ALL)
                          }
                        >
                          <div className="flex flex-col">
                            <Button
                              className="flex gap-2 items-center text-sm font-medium"
                              onClick={() => {
                                if (sensorHistoryVisible) {
                                  setVisibleSensors(
                                    [...visibleSensors].filter(
                                      (s) => s === sensor.Id,
                                    ),
                                  );
                                } else {
                                  setVisibleSensors([
                                    ...visibleSensors,
                                    sensor.Id,
                                  ]);
                                }
                              }}
                            >
                              <HiOutlineChevronRight
                                className={`size-4 transition-all ${
                                  sensorHistoryVisible ? 'rotate-90' : ''
                                }`}
                              />
                              <FormattedMessage id="Historical values over the last 2 weeks" />
                            </Button>
                            <Transition show={sensorHistoryVisible}>
                              <div className="h-24 relative">
                                <SensorChart sensorId={sensor.Id} />
                              </div>
                            </Transition>
                          </div>
                        </Transition>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Accordion>
        </div>
      )}
      <RemoveSensorModal
        beaconName={beaconName}
        sensorToRemove={sensorToRemove}
        setSensorToRemove={setSensorToRemove}
        setIsDeleteLoading={setIsDeleteLoading}
      />
    </>
  );
}
