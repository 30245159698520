import { useRoomClimateHistoryQuery } from 'graphql/types';
import { useMemo } from 'react';
import { type IntlMessageKeys, useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import ReportingPage from '../ReportingPage';
import BarChart from './components/BarChart';

export default function ReportClimateValues() {
  const hasuraHeader = useHasuraHeader();
  const intl = useIntl();
  const { Start, End, Building } = useAnalyticsFilter();
  const [{ data, fetching }] = useRoomClimateHistoryQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    variables: {
      Start,
      End,
      Building,
    },
  });

  const environmentData = data?.f_history_environment_custom ?? [];
  const sensorTypes = [...new Set(environmentData.map((e) => e.SensorType))];

  return (
    <ReportingPage
      loading={fetching}
      reportTitle="Climate conditions"
      kpi={intl.formatMessage({
        id: 'Are the room conditions agreeable?',
      })}
      cardData={sensorTypes.map((sensorType) => {
        const data = environmentData.filter((e) => e.SensorType === sensorType);
        const acceptableDataAvg =
          data
            .map((e) => e.AcceptablePercentage)
            .reduce((acc, cur) => acc + cur, 0) / data.length;
        const goodDataAvg =
          data.map((e) => e.GoodPercentage).reduce((acc, cur) => acc + cur, 0) /
          data.length;
        const poorDataAvg =
          data.map((e) => e.PoorPercentage).reduce((acc, cur) => acc + cur, 0) /
          data.length;

        const aggregatedData = acceptableDataAvg + goodDataAvg;

        return {
          title: `${intl.formatMessage({
            id: sensorType as IntlMessageKeys,
          })} ${aggregatedData.toFixed(2)}%`,
          values: [
            {
              value: `${intl.formatMessage({ id: 'Good' })}: ${goodDataAvg.toFixed(2)}%`,
              key: 'good',
              className: 'font-bold',
            },
            {
              value: `${intl.formatMessage({ id: 'Acceptable' })}: ${acceptableDataAvg.toFixed(
                2,
              )}%`,
              key: 'acceptable',
              className: 'font-bold',
            },
            {
              value: `${intl.formatMessage({ id: 'Poor' })}: ${poorDataAvg.toFixed(2)}%`,
              key: 'poor',
            },
          ],
        };
      })}
    >
      <div className="flex flex-col space-y-4 w-full">
        {sensorTypes.map((sensorType) => {
          const data = environmentData.filter(
            (e) => e.SensorType === sensorType,
          );
          const rooms = [
            ...new Set(
              data
                .filter((e) => e.SensorType === sensorType)
                .map((e) => e.Room),
            ),
          ];
          return (
            <div className="h-96 w-full" key={sensorType}>
              <BarChart
                sensorType={sensorType}
                key={sensorType}
                data={rooms.map((d) => ({
                  roomName: d,
                  acceptablePercentage:
                    data.find((e) => e.Room === d)?.AcceptablePercentage ?? 0,
                  goodPercentage:
                    data.find((e) => e.Room === d)?.GoodPercentage ?? 0,
                  poorPercentage:
                    data.find((e) => e.Room === d)?.PoorPercentage ?? 0,
                }))}
              />
            </div>
          );
        })}
      </div>
    </ReportingPage>
  );
}
