import Transition from 'mda2-frontend/src/generic/components/Transition';
import Tooltip from 'generic/components/Tooltip';
import Switch from 'generic/components/Form/Switch/Switch';
import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import { FormattedMessage } from 'translations/Intl';

interface AAWSelectionProps {
  aloneAtWorkIsActivated: boolean;
  setAloneAtWorkIsActivated: (val: boolean) => void;
  show: boolean;
}

export default function AAWSelection({
  show,
  aloneAtWorkIsActivated,
  setAloneAtWorkIsActivated,
}: AAWSelectionProps): JSX.Element {
  return (
    <PrivateWrapper roleRequired={HasuraPermissions.FEATURE_ROOMMODES}>
      <Transition show={show} appear>
        <div className="flex items-center space-x-2">
          <Switch
            isEnabled={aloneAtWorkIsActivated}
            onSetEnable={() =>
              setAloneAtWorkIsActivated(!aloneAtWorkIsActivated)
            }
          />
          <div className="flex space-x-2 items-center text-base md:text-sm">
            <div>
              <FormattedMessage id="AloneAtWork" />
            </div>
            <Tooltip>
              <FormattedMessage id="AloneAtWork description" />
            </Tooltip>
          </div>
        </div>
      </Transition>
    </PrivateWrapper>
  );
}
