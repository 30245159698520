import { Themes } from 'common/types';
import useClient from 'config/useClient';
import AuthenticationProvider from 'generic/components/layout/AuthenticationProvider';
import useStore from 'model/store';
import SecuredPages from 'pages/App';
import { primaryColorToRGB } from 'utils/getColor';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Provider as UrqlProvider } from 'urql';

function AuthenticatedUrqlPages() {
  // The Authentication with the role is only made on connection.
  // Set a new role in redux from anywhere in order to create a new websocket with the new permission
  const client = useClient();

  return (
    <UrqlProvider value={client}>
      <SecuredPages />
    </UrqlProvider>
  );
}

export default function Authenticated() {
  const theme = useStore((state) => state.userSettings.theme);
  const themeColor = useStore((state) => state.userSettings.themeColor);
  const setThemeColor = useStore((state) => state.userApi.setThemeColor);
  const setTheme = useStore((state) => state.userApi.setTheme);
  const [primaryColor, setPrimaryColor] = useState(
    theme.color === Themes.DARK
      ? primaryColorToRGB(600)
      : primaryColorToRGB(300),
  );

  // Setting the themeColor will add the color to the root of index.html
  useEffect(() => setThemeColor(themeColor), [themeColor, setThemeColor]);

  // Setting the theme will add the theme to the root of index.html
  useEffect(() => setTheme(theme), [theme, setTheme]);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

    prefersDarkMode.addEventListener('change', (e) => {
      // TODO: Not working in mac os Brave: https://bugs.chromium.org/p/chromium/issues/detail?id=998903
      // Only change the theme based on the system setting if the browser is using
      // the device theme
      if (theme.useDeviceTheme) {
        setTheme({
          color: e.matches ? Themes.DARK : Themes.LIGHT,
          useDeviceTheme: true,
        });
      }
    });

    // When the page is initially loaded and it couldn't listen to changes as it wasn't open
    // then see if the set mode matches the preferred mode
    if (
      theme.useDeviceTheme &&
      theme.color !== (prefersDarkMode.matches ? Themes.DARK : Themes.LIGHT)
    ) {
      setTheme({
        color: prefersDarkMode.matches ? Themes.DARK : Themes.LIGHT,
        useDeviceTheme: true,
      });
    }
  }, [setTheme, theme]);

  useEffect(() => {
    // Initially primaryColorToRGB is empty -> wait for something else to load
    // to make sure it works
    if (themeColor) {
      setPrimaryColor(
        theme.color === Themes.DARK
          ? primaryColorToRGB(600)
          : primaryColorToRGB(300),
      );
    }
  }, [theme.color, themeColor]);
  return (
    <AuthenticationProvider>
      <AuthenticatedUrqlPages />
      <Helmet>
        <meta name="theme-color" content={primaryColor} />
      </Helmet>
    </AuthenticationProvider>
  );
}
