import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition/Transition';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import Button from 'mda2-frontend/src/generic/components/Form/Button';
import StyledButton from 'mda2-frontend/src/generic/components/Form/Button/StyledButton';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import {
  type 
  NotificationsPaginatedQuery,
  useMqttBeaconsUuidMutation,
  useUpdateUserNotificationsMutation,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import { formattedDistance } from 'mda2-frontend/src/utils/date';
import localize from 'mda2-frontend/src/utils/format';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { forwardRef } from 'react';
import {
  HiOutlineBell,
  HiOutlineExclamationCircle,
  HiOutlineExclamationTriangle,
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';

export type NotificationsType = NotificationsPaginatedQuery['Notifications'];

enum Level {
  WARNING = 'Warning',
  INFO = 'Info',
  ERROR = 'Error',
}

interface NotificationProps {
  notification: NotificationsType[number];
  seeOnlyUnread: boolean;
}

const parseNewUUID = (message: string) => message.split(' ').slice(-1)[0];
const parseOldUUID = (message: string) => message.split(' ').slice(-3, -2)[0];
const parseSensorType = (message: string) => message.split(' ').slice(1, 2)[0];
const parseValue = (message: string) => message.split(' ').slice(-1)[0];

function StatusIcon({ status }: { status: string }) {
  if (status === Level.ERROR) {
    return (
      <div className="bg-red-100 flex-shrink-0 flex items-center justify-center size-6 rounded-full">
        <HiOutlineExclamationTriangle className="mx-auto size-5 text-red-500" />
      </div>
    );
  }
  if (status === Level.WARNING) {
    return (
      <div className="bg-yellow-100 flex-shrink-0 flex items-center justify-center size-6 rounded-full">
        <HiOutlineExclamationCircle className="mx-auto size-5 text-yellow-500" />
      </div>
    );
  }
  return (
    <div className="bg-blue-100 flex-shrink-0 flex items-center justify-center size-6 rounded-full">
      <HiOutlineBell className="mx-auto size-5 text-blue-500" />
    </div>
  );
}

function SourceDisplay({
  source,
  message,
}: {
  source: string;
  message: string;
}) {
  const userRoles = useStore((state) => state.user)?.roles;

  if (source === 'Pipeline') {
    return <div className="ml-2 font-bold">{source}</div>;
  }

  // When a new organization is added, then it has a UUID with 4 dashes
  if (source.split('-').length === 5) {
    return userRoles?.includes(HasuraPermissions.VIEW_STATUS) &&
      userRoles.includes(HasuraPermissions.READ_ALL) ? (
      <Link
        className="ml-2 font-bold hover:text-primary-500"
        to={`/organization/management?uuid=${source}`}
      >
        {source}
      </Link>
    ) : (
      <span className="ml-2 font-bold">{source}</span>
    );
  }

  // If battery or asset tracker is in the notification it is related to asset trackers
  if (message.includes('voltage') || message.includes('asset tracker')) {
    return userRoles?.includes(HasuraPermissions.VIEW_FINDMYASSET) ? (
      <Link
        className="ml-2 font-bold hover:text-primary-500"
        to={`/findmyasset?asset=${source}`}
      >
        {source}
      </Link>
    ) : (
      <span className="ml-2 font-bold">{source}</span>
    );
  }

  return userRoles?.includes(HasuraPermissions.VIEW_STATUS) ? (
    <Link
      className="ml-2 font-bold hover:text-primary-500"
      to={`/status?beacon=${source}`}
    >
      {source}
    </Link>
  ) : (
    <span className="ml-2 font-bold">{source}</span>
  );
}

function StatusMessage({
  message,
  source,
}: {
  message: IntlMessageKeys;
  source: string;
}) {
  if (
    (message as string) === 'Status for beacon changed from Online to Offline'
  ) {
    return (
      <FormattedMessage
        id="Status for beacon {beacon} changed from online to offline"
        values={{
          beacon: source,
        }}
      />
    );
  }
  if (
    (message as string) === 'Status for beacon changed from Offline to Online'
  ) {
    return (
      <FormattedMessage
        id="Status for beacon {beacon} changed from offline to online"
        values={{
          beacon: source,
        }}
      />
    );
  }
  if (
    (message as string) ===
    'Status for asset tracker changed from Online to Offline'
  ) {
    return (
      <FormattedMessage
        id="Status for asset tracker {assetTracker} changed from online to offline"
        values={{
          assetTracker: source,
        }}
      />
    );
  }
  if (
    (message as string) ===
    'Status for asset tracker changed from Offline to Online'
  ) {
    return (
      <FormattedMessage
        id="Status for asset tracker {assetTracker} changed from offline to online"
        values={{
          assetTracker: source,
        }}
      />
    );
  }
  if ((message as string) === 'Low battery voltage') {
    return (
      <FormattedMessage
        id="Low battery voltage for asset tracker"
        values={{
          assetTracker: source,
        }}
      />
    );
  }
  if ((message as string) === 'Battery voltage ok') {
    return (
      <FormattedMessage
        id="Healthy battery voltage for asset tracker"
        values={{
          assetTracker: source,
        }}
      />
    );
  }
  if (message.includes('changed UUID from')) {
    return (
      <FormattedMessage
        id="UUID for beacon changed"
        values={{
          beacon: source,
          old: parseOldUUID(message),
          new: parseNewUUID(message),
        }}
      />
    );
  }

  if (message.includes('measured a value of')) {
    return (
      <FormattedMessage
        id="Invalid value"
        values={{
          sensorType: parseSensorType(message),
          beacon: source,
          value: parseValue(message),
        }}
      />
    );
  }

  if (message.includes(`Organization ${source} created`)) {
    return (
      <FormattedMessage
        id="Organization created"
        values={{
          organization: source,
        }}
      />
    );
  }

  return <FormattedMessage id={message} />;
}

export const filterUnread = (notification: NotificationsType[number]) =>
  notification.UserNotifications.length === 0 ||
  notification.UserNotifications.filter((u) => !u.Read).length > 0;

const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  (props, ref) => {
    const { notification, seeOnlyUnread, ...rest } = props;

    const userRoles = useStore((state) => state.user)?.roles;
    const hasuraHeader = useHasuraHeader();
    const toast = useToast();
    const [, updateMqttBeaconUuid] = useMqttBeaconsUuidMutation();
    const [, updateUserNotifications] = useUpdateUserNotificationsMutation();
    const { Id, Message, Source, NotificationLevel, UpdatedAt, Organization } =
      notification;
    const isUnread = filterUnread(notification);

    const onReadClick = () => {
      updateUserNotifications(
        {
          UserNotifications: {
            NotificationsId: Id,
            Read: isUnread,
          },
        },
        hasuraHeader(HasuraPermissions.READ, [
          'Notifications',
          'Notifications_aggregate',
        ]),
      );
    };

    if (seeOnlyUnread && !isUnread) {
      return null;
    }

    return (
      <div ref={ref} className="group" {...rest}>
        <div className="flex flex-col py-2 px-4 group-hover:bg-neutral-50 dark:group-hover:bg-neutral-700">
          <div className="flex items-center py-1 justify-between">
            <div className="flex items-center">
              <StatusIcon status={NotificationLevel.Level} />
              <SourceDisplay source={Source} message={Message} />
            </div>
            <Button
              className="relative flex items-center"
              onClick={onReadClick}
            >
              <Tooltip
                className="top-0 right-5 text-xs whitespace-nowrap"
                content={
                  <div className="flex items-center justify-center size-6 group-hover:bg-neutral-200 dark:group-hover:bg-neutral-800 transition duration-150 rounded-full cursor-pointer">
                    {isUnread && (
                      <div className="size-3 bg-primary-500 dark:bg-primary-700 rounded-full" />
                    )}
                  </div>
                }
              >
                {isUnread ? (
                  <FormattedMessage id="Mark as read" />
                ) : (
                  <FormattedMessage id="Mark as unread" />
                )}
              </Tooltip>
            </Button>
          </div>
          <div>
            <StatusMessage
              message={Message as IntlMessageKeys}
              source={Source}
            />
          </div>
          <PrivateWrapper roleRequired={HasuraPermissions.WRITE_MQTTBEACON}>
            <Transition show={Message.includes('changed UUID from')}>
              <div>
                <StyledButton
                  onClick={() =>
                    updateMqttBeaconUuid(
                      {
                        Name: Source,
                        UniqueIdentifier: parseNewUUID(Message),
                        Message,
                      },
                      hasuraHeader(
                        userRoles?.includes(HasuraPermissions.READ_ALL)
                          ? HasuraPermissions.READ_ALL
                          : HasuraPermissions.WRITE_MQTTBEACON,
                      ),
                    ).then((data) => toast(data))
                  }
                >
                  <FormattedMessage id="Change UUID" />
                </StyledButton>
              </div>
            </Transition>
          </PrivateWrapper>
          <div className="flex flex-col text-neutral-500 dark:text-neutral-300 text-xs pt-1">
            {userRoles?.includes(HasuraPermissions.READ_ALL) && (
              <div>{Organization.Name}</div>
            )}
            <div>
              {formattedDistance(new Date(UpdatedAt))} (
              {localize(new Date(UpdatedAt), 'Pp')})
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default Notification;
