import Table from 'generic/components/Table/Table';
import Transition from 'generic/components/Transition';
import useStore from 'model/store';
import { useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';

import type { ColumnDef } from '@tanstack/react-table';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import {
  type 
  GroupInput,
  type 
  KeycloakGroupsQuery,
  type 
  KeycloakUsersQuery,
  useKeycloakGroupsQuery,
} from 'mda2-frontend/src/graphql/types';
import { useEffect, useMemo } from 'react';

export interface User {
  id: string;
}

type Data = KeycloakGroupsQuery['GetSubGroups'][number];

interface GroupsInputProps {
  initialUser?: KeycloakUsersQuery['GetUsers']['groupMembers'][number];
  groupsToAdd: GroupInput[];
  setGroupsToAdd: (groupToAdd: GroupInput[]) => void;
  groupsToDelete: GroupInput[];
  setGroupsToDelete: (groupToDelete: GroupInput[]) => void;
}

export default function GroupsInput({
  initialUser,
  groupsToAdd,
  setGroupsToAdd,
  groupsToDelete,
  setGroupsToDelete,
}: GroupsInputProps): JSX.Element | null {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const user = useStore((state) => state.user);
  const [{ data: groups, fetching: loadingGroups }] = useKeycloakGroupsQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_USERGROUP),
      [hasuraHeader],
    ),
  });

  const defaultColumns: ColumnDef<Data>[] = [
    {
      accessorKey: 'name',
      id: 'name',
      header: intl.formatMessage({ id: 'Name' }),
      enableGrouping: false,
    },
  ];

  useEffect(() => {
    if (initialUser?.groups) {
      setGroupsToAdd(
        initialUser.groups.map((u) => ({
          id: u?.id ?? '',
        })),
      );
    }
  }, [setGroupsToAdd, initialUser]);

  const initialRowSelection = useMemo(
    () =>
      Object.fromEntries(
        Object.entries<boolean>(
          groups?.GetSubGroups.reduce(
            (o, key, idx) =>
              Object.assign(o, {
                [idx]: initialUser?.groups?.map((u) => u?.id).includes(key.id),
              }),
            {},
          ) ?? {},
        ).filter(([, value]) => value),
      ),
    // Only set the initial selection when the data is available
    [groups?.GetSubGroups, initialUser],
  );

  return (
    <>
      {loadingGroups && <Loader loading={loadingGroups} />}
      <Transition
        show={(groups?.GetSubGroups || []).length > 0}
        className="flex flex-col gap-2"
      >
        <Table<Data>
          id="user-groups"
          columns={defaultColumns}
          initialState={{
            rowSelection: initialRowSelection,
          }}
          data={
            groups?.GetSubGroups.map((s) => ({
              ...s,
              // Disabled deselecting Admin group for logged in user
              disabled:
                s.name === 'Admin' && user?.email === initialUser?.email,
            })) || []
          }
          enabledFeatures={{
            enableGlobalFilter: false,
            enableRowSelection: true,
            enableCsvExport: false,
            enableColumnSelection: false,
            enablePageSize: false,
          }}
          onSelectedChange={(r) => {
            if (!groups) {
              return;
            }
            const clickedGroup = groups.GetSubGroups.find(
              (u) => u.id === r.original.id,
            );
            if (r.getIsSelected()) {
              // remove from groupsToAdd
              setGroupsToAdd(groupsToAdd.filter((u) => u.id !== r.original.id));
              // add to groupsToDelete
              if (clickedGroup) {
                setGroupsToDelete([...groupsToDelete, { id: clickedGroup.id }]);
              }
            } else {
              // filter from groupsToDelete
              setGroupsToDelete(
                groupsToDelete.filter((u) => u.id !== r.original.id),
              );
              // add to groupsToAdd
              if (clickedGroup) {
                setGroupsToAdd([...groupsToAdd, { id: clickedGroup.id }]);
              }
            }
          }}
        />
      </Transition>
    </>
  );
}
